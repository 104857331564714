/// <reference types="@types/office-js" />
import { OfficeAdapter } from 'src/app/office/shared/types';
import { CurrentTextService } from 'src/app/services/current-text.service';
import { AnalysisRequest, EditorMarkings } from 'src/app/TeoDefinitions';
import { TextSection } from './shared/models';
import { OfficeFulltextAdapter } from './adapters/fulltext-adapter';
import { toError, createLogger } from './shared/utils';

const LOG = createLogger('Office-Editor')

function createSubscription(key: string) {
  return [
    (...args: any) => LOG.log(`>>> EE >>> [${key}] GOT Event`, ...args),
    (...args: any) => LOG.log(`>>> EE >>> [${key}] GOT Event – ERROR`, ...args),
    (...args: any) => LOG.log(`>>> EE >>> [${key}] GOT Event – COMPLETE`, ...args),
  ]
}
export class OfficeEditor {

  adapter: OfficeAdapter = new OfficeFulltextAdapter(this);
  _doc: null | Office.Document = null;
  _wrappers: TextSection[] = [];

  constructor(private currentText: CurrentTextService) {
    globalThis.oe = this; // TODO Remove, this is only for accessing it from the inspector

    currentText.gotMarkings.subscribe(
      async (markings: EditorMarkings) => {
        await this.adapter.onUpdateAvailabelMarkings(markings)
        }
    );

    currentText.beforeAnalysis.subscribe(() => this.adapter.onBeforeAnalysis());

    currentText.removedMarkings.subscribe((removedMarkings) => {
      this.adapter.onRemovedMarkings(removedMarkings)
    });

    currentText.markings.subscribe(() => {
      this.adapter.onUpdateMarkings(currentText.activeMarkings)
    });

    // currentText.editorContentUpdate.subscribe(...createSubscription('editorContentUpdate'))
    // currentText.gotMarkings.subscribe(...createSubscription('gotMarkings'))
    // currentText.beforeAnalysis.subscribe(...createSubscription('beforeAnalysis'))
    // currentText.gotResults.subscribe(...createSubscription('gotResults'))
    // currentText.gotScannerResults.subscribe(...createSubscription('gotScannerResults'))
    // currentText.markings.subscribe(...createSubscription('markings'))
    // currentText.removedMarkings.subscribe(...createSubscription('removedMarkings'))
    // currentText.textClick.subscribe(...createSubscription('textClick'))
    // currentText.textReset.subscribe(...createSubscription('textReset'))

  }

  // TODO proper error handling
  init(): Promise<boolean | Error> {
    return new Promise((resolve, reject) => {
      if (typeof Office === 'undefined') {
        console.error('Office not available!');
        return resolve(false);
      }

      Office.onReady(async () => {
        try {
          if (typeof Word === 'undefined') {
            console.error('Not in a Word context!');
            return resolve(false);
          }
          const result = await this.onReady();

          resolve(result);
        } catch (error: unknown) {
          console.error('Office Error', error);
          resolve(toError(error))
        }
      });
    });
  }

  async onReady(): Promise<boolean> {
    this.adapter.init();
    this._doc = Office.context.document;
    this._doc.addHandlerAsync(Office.EventType.DocumentSelectionChanged, () => {
      this.adapter.onSelectionChanged();
    });
    return true;
  }

  async prepareRequest(req: AnalysisRequest): Promise<void> {
    LOG.info('prepare')
    if (typeof req.text == 'string' || !req.text) {
      try {
        req.text = await this.adapter.getRequestText();
      } catch (err: unknown) {
       LOG.error('Office Editor - prepareRequest', err);
      }
    } else {
      LOG.log('Text ist already processed');
    }
  }

  getHighlightColor(markers: string[]): string {
    const activeMarkers = this.currentText.activeMarkings
    const found = markers.find(marker => activeMarkers.includes(marker))
    if (found) {
      LOG.log('Found marking', found, this.currentText.wrapColors)
      return this.currentText.wrapColors[found] || 'yellow'
    } else {
      LOG.log('No found marking', found, this.currentText.wrapColors)
      return 'yellow'
    }
  }


  async unmark(): Promise<void> {}
}
