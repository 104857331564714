<mat-card class="index-card" [class.detailed]="detailed">
  <mat-icon class="top-left-info-icon" svgIcon="info" appInfoButton="gender-thesaurus" infoTitle="Gender-Thesaurus">
  </mat-icon>
  <mat-progress-bar class="card-loading-bar" [style.opacity]="loading ? 1 : 0" mode="indeterminate" color="accent">
  </mat-progress-bar>
  <div class="result-panel-wrap" (click)="itemClick();">
    <div class="result-panel-content">
      <div>
        Gender-Thesaurus: <b class="score-text-number">{{ entryNumber }}</b>
      </div>
    </div>
  </div>
  <div class="score-details" [style.height]="detailsHeight">
    <div id="gender-thesaurus-details-inner" class="score-details-inner">
      <div class="score-details-inner-wrap">
        <div *ngIf="detailed" class="score-details-head">
          <!-- <a href="#" class="scan-button" (click)="scan()">neu prüfen</a> -->
          <div *ngIf="!entryNumber">
            Keine Thesauruseinträge gefunden.
          </div>
          <div *ngIf="entryNumber">
            <div *ngIf="!currentEntry">
              Bitte wählen Sie ein markiertes Wort aus.
            </div>
            <div *ngIf="currentEntry">
              Wort:<div class="current-word">
                {{ thesEntries[currentEntry].word }}
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="detailed && currentEntry && thesEntries[currentEntry].synonyms.length > 0">
          <h4>Synonyme</h4>
          <mat-radio-group [(ngModel)]="currentSynonymId" (change)="useReplacement()">
            <label *ngFor="let synonym of thesEntries[currentEntry].synonyms; let i = index" class="score-todo"
              [class.active]="currentWordId === i">
              <div class="score-todo-text">{{ synonym }}</div>
              <mat-radio-button [value]="i"></mat-radio-button>
            </label>
          </mat-radio-group>
          <div class="keyword-actions">
            <a href="#" (click)="resetReplacement()">Zurücksetzen</a>
          </div>
          <div class="keyword-infos">
            Achten Sie bitte darauf, dass Sie Artikel und Satzbau ggf. anpassen müssen.
          </div>
        </div>
      </div>
    </div>
  </div>
</mat-card>
