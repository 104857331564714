<div class="medium-container">
  <div class="page-header">
    <h1>Analyse Konfigurieren</h1>
    <div class="info-text">Bitte wählen Sie Ihre Einstellungen.</div>
  </div>
  <div class="analysis-config-row-wrap analysis-config-active">
    <div class="row-icon-container" [class.checked]="currentText.textAudience !== null">
      <mat-icon class="row-icon checkmark-icon" svgIcon="check-mark"></mat-icon>
      <mat-icon class="row-icon arrow-icon" svgIcon="arrow"></mat-icon>
    </div>
    <div>Zielgruppe</div>
    <div class="info-text">Wer soll Ihren Text lesen?</div>
    <div class="layout-row card-select">
      <ng-container *ngFor="let option of currentText.benchmarkOptions['target-audience']">
        <div class="co-3" [class.co-6]="smallLayout" *ngIf="option.state != 'HIDDEN'">
          <input [id]="option.name + '-radio'" name="textAudience" type="radio" [value]="option"
            [attr.disabled]="option.state == 'INACTIVE' ? true : null" [(ngModel)]="currentText.textAudience" />
          <label class="" [for]="option.name + '-radio'">
            <mat-icon *ngIf="option.state != 'INACTIVE'" class="top-left-info-icon" svgIcon="info"
              [appInfoButton]="option.name" [infoTitle]="option.title">
            </mat-icon>
            <mat-card matRipple class="button-card" [class.inactive]="option.state == 'INACTIVE'"
              matRippleColor="rgba(0,0,0,0.03)">
              <span class="button-card-text">{{ option.title }}</span>
            </mat-card>
          </label>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="analysis-config-row-wrap" [class.analysis-config-active]="currentText.textAudience !== null">
    <div class="row-icon-container" [class.checked]="currentText.textStrategy !== null">
      <mat-icon class="row-icon checkmark-icon" svgIcon="check-mark"></mat-icon>
      <mat-icon class="row-icon arrow-icon" svgIcon="arrow"></mat-icon>
    </div>
    <div>Strategisches Ziel</div>
    <div class="info-text">Welches Ziel verfolgen Sie?</div>
    <div class="layout-row card-select">
      <ng-container *ngFor="let option of currentText.benchmarkOptions['strat-direction']">
        <div class="co-3" [class.co-6]="smallLayout" *ngIf="option.state != 'HIDDEN'">
          <input [id]="option.name + '-radio'" name="textStrategy" type="radio" [value]="option"
            [attr.disabled]="option.state == 'INACTIVE' ? true : null" [(ngModel)]="currentText.textStrategy" />
          <label class="" [for]="option.name + '-radio'">
            <mat-icon *ngIf="option.state != 'INACTIVE'" class="top-left-info-icon" svgIcon="info"
              [appInfoButton]="option.name" [infoTitle]="option.title">
            </mat-icon>
            <mat-card matRipple class="button-card" [class.inactive]="option.state == 'INACTIVE'"
              matRippleColor="rgba(0,0,0,0.03)">
              <span class="button-card-text">{{ option.title }}</span>
            </mat-card>
          </label>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="analysis-config-row-wrap"
    [class.analysis-config-active]="currentText.textStrategy !== null && currentText.textAudience !== null">
    <div class="row-icon-container" [class.checked]="currentText.textMedium !== null">
      <mat-icon class="row-icon checkmark-icon" svgIcon="check-mark"></mat-icon>
      <mat-icon class="row-icon arrow-icon" svgIcon="arrow"></mat-icon>
    </div>
    <div>Medium</div>
    <div class="info-text">Für welches Medium texten Sie?</div>
    <div class="layout-row card-select">
      <ng-container *ngFor="let option of currentText.benchmarkOptions['text-medium']">
        <div class="co-3" [class.co-6]="smallLayout" *ngIf="option.state != 'HIDDEN'">
          <input [id]="option.name + '-radio'" name="textMedium" type="radio" [value]="option"
            [attr.disabled]="option.state == 'INACTIVE' ? true : null" [(ngModel)]="currentText.textMedium" />
          <label class="" [for]="option.name + '-radio'">
            <mat-icon *ngIf="option.state != 'INACTIVE'" class="top-left-info-icon" svgIcon="info"
              [appInfoButton]="option.name" [infoTitle]="option.title">
            </mat-icon>
            <mat-card matRipple class="button-card" [class.inactive]="option.state == 'INACTIVE'"
              matRippleColor="rgba(0,0,0,0.03)">
              <span class="button-card-text">{{ option.title }}</span>
            </mat-card>
          </label>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="analysis-config-row-wrap"
  [class.analysis-config-active]="currentText.textStrategy !== null && currentText.textAudience !== null && currentText.textMedium !== null">
    <div class="row-icon-container checked">
      <mat-icon class="row-icon checkmark-icon" svgIcon="check-mark"></mat-icon>
      <mat-icon class="row-icon arrow-icon" svgIcon="arrow"></mat-icon>
    </div>
    <div>Ansprache</div>
    <div class="info-text">Werden Ihre Leser mit "Sie" oder mit "Du" angesprochen?</div>
    <div class="layout-row card-select">
      <ng-container *ngFor="let option of informalSelectOptions">
        <div class="co-3" [class.co-6]="smallLayout" *ngIf="option.state != 'HIDDEN'">
          <input [id]="option.name + '-radio'" name="textIsInformal" type="radio" [value]="option.value"
            [attr.disabled]="option.state == 'INACTIVE' ? true : null" [(ngModel)]="currentText.isInformal" />
          <label class="" [for]="option.name + '-radio'">
            <mat-icon *ngIf="option.state != 'INACTIVE'" class="top-left-info-icon" svgIcon="info"
              [appInfoButton]="option.name" [infoTitle]="option.title">
            </mat-icon>
            <mat-card matRipple class="button-card" [class.inactive]="option.state == 'INACTIVE'"
              matRippleColor="rgba(0,0,0,0.03)">
              <span class="button-card-text">{{ option.title }}</span>
            </mat-card>
          </label>
        </div>
      </ng-container>
    </div>
  </div>
  <button class="big-button" [class.inactive]="!selectionReady()" (click)="continue()">
    <div class="big-button-ripple-layer" matRipple></div>
    Weiter
  </button>

</div>
<div class="page-annotation">* Beta-Status</div>
