import { Component, OnInit } from '@angular/core';
import { AppStateService } from 'src/app/services/app-state.service';

@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.scss']
})
export class SideBarComponent implements OnInit {
  hidden = true;
  display = true;

  // tabs = [
  //   "pinboard",
  //   "history"
  // ];

  public activeTab = null;

  constructor(
    public appState: AppStateService
  ) {

    appState.sideBar = this;

    this.checkDisplay(appState.activeView);
    appState.viewChange.subscribe((change) => {
      this.checkDisplay(change.to);
    });
  }

  ngOnInit(): void {
  }

  checkDisplay(viewName) {
    if (viewName == 'analysis-main') {
      this.display = true;
    } else {
      this.display = false;
    }
  }

  public show(tabName) {
    this.activeTab = tabName;
    this.hidden = false;
  }

  public hide() {
    this.activeTab = null;
    this.hidden = true;
  }

  tabButtonClick(tabName){
    if (this.activeTab != tabName) {
      this.show(tabName);
    } else {
      this.hide();
    }
  }

}
