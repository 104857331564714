<mat-card class="index-card" [class.detailed]="detailed">
  <mat-icon class="top-left-info-icon" svgIcon="info" appInfoButton="causal-connectors" infoTitle="Kausalitäts-Scanner">
  </mat-icon>
  <div class="brand-fit-indicator" [class.active]="!brandFit"></div>
  <div class="result-panel-wrap" (click)="itemClick();">
    <div class="result-panel-content">
      <div class="">
        Kausalitäts-Scanner: <b class="score-text-number">{{ number }}</b>
      </div>
    </div>
  </div>
  <div class="score-details" [style.height]="detailsHeight">
    <div id="causality-scanner-details-inner" class="score-details-inner">
      <div *ngIf="detailed" class="score-details-head">
        <!-- <a href="#" [class.active]="marked" (click)="mark()">Markieren</a><br /> -->
        <a href="#" (click)="showList()">Übersicht Kausal-Adverbien</a>
      </div>
    </div>
  </div>
</mat-card>
