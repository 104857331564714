import { JsonApiService } from 'src/app/services/json-api.service';
import { PinboardComponent } from './../pinboard/pinboard.component';
import {
  Injectable,
  EventEmitter,
  ComponentFactoryResolver,
  ApplicationRef,
  Injector,
  EmbeddedViewRef,
  ComponentRef,
  Type
} from '@angular/core';
import { InfoItemComponent } from '../pinboard/info-item/info-item.component';
import { identifierModuleUrl } from '@angular/compiler';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { User } from '../TeoDefinitions';
import { DialogAction, DialogContentComponent } from '../general-components/dialog-content/dialog-content.component';
import { FreePinsComponent } from '../pinboard/free-pins/free-pins.component';
import { SideBarComponent } from '../general-components/side-bar/side-bar.component';
import { StorageService } from './storage.service';

export class InfoLayerData {
  name: string;
}

@Injectable({
  providedIn: 'root'
})

export class AppStateService {
  activeView = 'login';
  // activeView = 'analysis-main';
  viewChange = new EventEmitter();

  loading = false;
  loadingQue: ((queCallback) => any)[] = [];;
  loadingMode = 'indeterminate';

  infoItemComponentRefs: ComponentRef<InfoItemComponent>[] = [];
  infoItemNames: string[] = [];

  // pinboard is set in PinboardComponent
  pinboard: PinboardComponent;
  freePins: FreePinsComponent;
  pinboardChange = new EventEmitter();

  // sidebar is set in SideBarComponent
  sideBar: SideBarComponent;

  infoTexts: any; // should get filled by API Service
  infoTextsChange = new EventEmitter();

  logout = new EventEmitter();
  login = new EventEmitter();

  user: User;
  debugMode = false;

  brandFit = null;
  brandFitRules = [];
  brandFitOpen = new EventEmitter();

  private infoZIndex = 10;

  private storageKey = 'app-state';

  constructor(
    private storage: StorageService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector,
    public snackBar: MatSnackBar,
    public dialog: MatDialog
  ) {

    const storedData = storage.retrieve(this.storageKey);

    if (storedData) {
      for (const property in storedData) {
        this[property] = storedData[property];
      }
    }

    // use the browsers history functionality with our views
    if (typeof history.pushState === 'function') {
      history.pushState({ teoapp: true, view: this.activeView }, 'TEO App', '');
    }
    window.onpopstate = (event) => {
      if (event.state !== null && typeof event.state.teoapp !== 'undefined') {
        this.setView(event.state.view, false);
        return false;
      }
    };

    this.login.subscribe((user) => {
      this.user = user;
      this.storage.setProperty(this.storageKey, 'user', user);
    })

    this.logout.subscribe(() => {
      this.user = null;
      this.debugMode = false;
      if (this.pinboard) {
        this.pinboard.removeAllInfoItems();
      }
      this.freePins.removeAllInfoItems();
      this.storage.clearAll();
      setTimeout(() => { // wait for other logout subscriptions to do their stuff
        this.setView('login');
      }, 200);
    });
  }

  public async queForLoading(load: (queCallback) => any) {
    if (this.loading) {
      this.loadingQue.push(load);
    } else {
      this.execLoadingFunction(load);
    }
    return true;
  }

  private execLoadingFunction(load: (queCallback) => any) {
    if (!this.loading) {
      {
        this.loading = true;
        load(() => {
          // finished this loading function
          // setTimeout(() => {
          this.loading = false;
          if (this.loadingQue.length > 0) {
            this.execLoadingFunction(this.loadingQue.shift());
          }
          // }, 300);
        });
      }

    } else {
      console.error('Tried to execute loading function while already loading')
    }
  }


  public userIsLoggedIn() {
    return this.user ? true : false;
  }

  public setInfoTexts(infoTexts: any) {
    this.infoTexts = infoTexts;
    this.infoTextsChange.emit(this.infoTexts);
  }

  public infoItemIsPinned(name: string) {
    if (this.pinboard !== null) {
      return (this.pinboard.isPinned(name) || this.freePins.isPinned(name));
    } else {
      throw new Error('Pinboard does not exist.');
    }
  }

  public pinInfoItem(componentType: Type<any>, name: string, title: string, data: any) {
    if (this.pinboard !== null) {
      return this.pinboard.pinInfoItem(componentType, name, title, data);
    } else {
      throw new Error('Pinboard does not exist.');
    }
  }
  public unpinInfoItem(id: number) {
    this.closeInfoItem(id, false);
  }

  public openInfoItem(componentType: Type<any>, name: string, title: string, data?: any) {
    if (this.freePins !== null) {
      if (!data && typeof this.infoTexts[name] !== 'undefined') {
        return this.freePins.pinInfoItem(componentType, name, title, this.infoTexts[name]);
      }
      return this.freePins.pinInfoItem(componentType, name, title, data);
    } else {
      throw new Error('Pinboard does not exist.');
    }
  }
  public openInfoItemCustomText(componentType: Type<any>, name: string, title: string, text: string) {
    if (this.freePins !== null) {
      return this.freePins.pinInfoItem(componentType, name, title, [{ text, title }]);
    } else {
      throw new Error('Pinboard does not exist.');
    }
  }
  public closeInfoItem(id: number, free = true) {
    if (!free && this.pinboard !== null) {
      return this.pinboard.removeInfoItem(id);
    } else if (free && this.freePins !== null) {
      return this.freePins.removeInfoItem(id);
    } else {
      throw new Error('Pinboard does not exist.');
    }
  }

  public showMessage(
    message: string,
    type = 'alert',
    actions: DialogAction[] = [],
    notice?: { title: string, text: string }
  ) {
    this.dialog.open(DialogContentComponent, {
      data: {
        message,
        type,
        actions,
        notice
      }
    });
  }

  public openSideBar(tabName) {
    if (this.sideBar !== null) {
      this.sideBar.show(tabName);
    } else {
      throw new Error('sideBar does not exist.');
    }
  }

  public closeSideBar() {
    if (this.sideBar !== null) {
      this.sideBar.hide();
    } else {
      throw new Error('sideBar does not exist.');
    }
  }

  public updateBrandFit(ruleName: string, fulfilled: boolean){
      this.brandFitRules[ruleName] = fulfilled;
      for(const rule in this.brandFitRules){
        if(!this.brandFitRules[rule]){
          this.brandFit = false;
          return;
        }
      }
      this.brandFit = true;
  }

  public setView(viewName: string, pushHistory = true) {
    if (this.activeView === viewName) {
      return;
    }
    if (pushHistory && typeof history.pushState === 'function') {
      history.pushState({ teoapp: true, view: viewName }, 'TEO App', '');
    }
    this.viewChange.emit({ from: this.activeView, to: viewName });
    this.activeView = viewName;
    this.storage.setProperty(this.storageKey, 'activeView', viewName);
  }
}
