<div class="side-bar-container" [class.hidden]="hidden" [class.display]="display">
  <div class="side-bar-tab-nav">
    <div class="side-bar-tab-button" [class.active]="activeTab == 'history'" (click)="tabButtonClick('history')">
      <mat-icon class="history-icon" svgIcon="history"></mat-icon>
    </div>
    <div class="side-bar-tab-button" [class.active]="activeTab == 'pinboard'" (click)="tabButtonClick('pinboard')">
      <mat-icon class="" svgIcon="pin"></mat-icon>
    </div>
  </div>
  <div class="scroll-container">
    <div class="scroll-inner">
      <app-history class="side-bar-content" [class.active]="activeTab == 'history'"></app-history>
      <app-pinboard class="side-bar-content" [class.active]="activeTab == 'pinboard'"></app-pinboard>

    </div>
  </div>
</div>
