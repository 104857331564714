import { HelpLayerService, HelpLayerItem } from './../help-layer.service';
import { Component, OnInit, Input, EventEmitter, AfterViewInit } from '@angular/core';

@Component({
  selector: 'app-help-layer',
  templateUrl: './help-layer.component.html',
  styleUrls: ['./help-layer.component.scss']
})
export class HelpLayerComponent implements OnInit, AfterViewInit {
  @Input() infoTextEmitter: EventEmitter<any>;

  layerOpen = false;

  currentItem: HelpLayerItem = null;

  infoTexts: any;

  constructor(
    public helpLayer: HelpLayerService
  ) {
    this.helpLayer.helpLayerOpen.subscribe((viewName: string) => {
      this.layerOpen = true;
    });
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    this.infoTextEmitter.subscribe((infoTexts) => {
      this.infoTexts = infoTexts;
    });
  }

  close() {
    this.layerOpen = false;
  }

  hideItem() {
    this.currentItem = null;
  }
  showItem(item: HelpLayerItem) {
    let text: string = null;
    if (this.infoTexts !== null) {
      if (typeof this.infoTexts[item.infoTextName] !== 'undefined') {
        text = this.infoTexts[item.infoTextName][0].text;
      }
    }
    if (text === null) {
      text = 'Keine Informationen verfügbar.<br/><br/>';
      // console.log(this.infoTexts);
      console.error('Failed to display info text for ' + item.infoTextName);
    }
    item.text = text.trim();
    this.currentItem = item;
  }

}
