import { Component, OnInit, Input } from '@angular/core';
import { AnalysisItemContent } from '../analysis-item-content-interface';
import { CurrentTextService } from 'src/app/services/current-text.service';
import { AppStateService } from 'src/app/services/app-state.service';
import { AccordionInfoComponent } from 'src/app/pinboard/info-item/accordion-info/accordion-info.component';

@Component({
  selector: 'app-word-cloud',
  templateUrl: './wordCloud.component.html',
  styleUrls: ['../analysis-item-shared.scss', './wordCloud.component.scss']
})
export class WordCloudComponent implements OnInit, AnalysisItemContent {
  data: any;
  itemName: string;
  @Input() inactive: boolean;

  detailed = false;

  numbers: any[] = [];

  detailsHeight = '0px;';

  constructor(
    private currentText: CurrentTextService,
    private appState: AppStateService
  ) {
    this.currentText.gotResults.subscribe(() => {
      const data = this.currentText.lastResult.wordCloud;

      this.numbers = data;

      this.setDetailsHeight();
    });
  }

  ngOnInit() {
  }

  close(removeMarkings = true) {
    this.detailed = false;
    this.setDetailsHeight();
  }

  open() {
    this.detailed = true;
    this.setDetailsHeight();
  }

  itemClick() {
    if (this.detailed) {
      this.close();
    } else {
      this.open();
    }
  }

  private setDetailsHeight() {
    window.setTimeout(() => {
      if (this.detailed) {
        const todoElem = document.getElementById('word-cloud-details-inner');
        const height = todoElem.offsetHeight;
        this.detailsHeight = height + 'px';
      } else {
        this.detailsHeight = '0px';
      }
    }, 20);
  }

}
