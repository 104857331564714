<div class="editor-header">
  <div class="editor-header-item">
    Größe<!--
    --><select class="editor-option-select" [(ngModel)]="editorOptions.fontSize">
      <option *ngFor="let size of editorFontSizes" [ngValue]="size">{{size}}</option>
    </select>
  </div>
  <div class="editor-header-item">
    Zeilenabstand<!--
    --><select class="editor-option-select" [(ngModel)]="editorOptions.lineHeight">
      <option *ngFor="let hights of editorLineHeights" [ngValue]="hights">{{hights}}</option>
    </select>
  </div>
  <div class="editor-header-item">
    <span>Zeilenumbrüche</span> <a (click)="removeLinebreaks()"><mat-icon class="" svgIcon="clear"></mat-icon></a>
  </div>
</div>
<div id="editor-wrapper">
  <div id="editor-text" contenteditable="true" placeholder="Fügen Sie bitte einen Text ein."
    [ngStyle]="getEditorStyle()" (input)="onEdit($event)" (paste)="onPaste($event)" (click)="onClick($event)"
    #editor>
  </div>
</div>
