import { Component, OnInit, Input } from '@angular/core';
import { WordListInfoComponent } from 'src/app/pinboard/info-item/word-list-info/word-list-info.component';
import { AppStateService } from 'src/app/services/app-state.service';
import { CurrentTextService } from 'src/app/services/current-text.service';
import { JsonApiService } from 'src/app/services/json-api.service';
import { AnalysisRequest } from 'src/app/TeoDefinitions';
import { AnalysisItemContent } from '../analysis-item-content-interface';

@Component({
  selector: 'app-power-words',
  templateUrl: './power-words.component.html',
  styleUrls: ['../analysis-item-shared.scss', './power-words.component.scss']
})
export class PowerWordsComponent implements OnInit, AnalysisItemContent {
  data: any;
  itemName: string;
  @Input() inactive: boolean;
  @Input() scannerName: string;

  detailed = false;

  loading = false;

  words = [];

  // editorMarkings = [];

  wordNumber = 0;

  textClickSubscription = null;
  markingsSubscription = null;

  markings = ['wrap_power_word'];

  detailsHeight = '0px;';

  benchmark = 1; // in percent

  wordsPercent = 0;
  scaleScore = 0;
  scaleMarking = '1 %';
  scaleBenchmark = 10;
  scaleInactive = false;

  list: Array<string>;


  constructor(
    private currentText: CurrentTextService,
    private appState: AppStateService,
    private api: JsonApiService
  ) {
    // this.currentText.gotResults.subscribe(() => {
    //   window.setTimeout(() => {
    //     this.setDetailsHeight();
    //   }, 50);
    // });
    this.currentText.gotScannerResults.subscribe((dataArray) => {
      if (dataArray && dataArray[this.scannerName]) {
        this.useScanResult(dataArray[this.scannerName]);
      }
    });

  }

  ngOnInit() {
  }

  setScore(data) {
    const wordNum = data.num_words;
    this.wordNumber = data.num_power_words;
    this.wordsPercent = this.wordNumber / wordNum * 100;
    this.scaleScore = this.wordsPercent / this.benchmark * 10;
    if (this.scaleScore > 15) {
      this.scaleScore = 15;
    }
    this.wordsPercent = Math.floor(this.wordsPercent);
  }

  scan() {
    if (!this.loading) {
      this.loading = true;
      this.words = [];
      this.wordNumber = 0;
      setTimeout(() => {  // wait for wrappers to be removed
        const anaRequest = new AnalysisRequest();
        anaRequest.text = this.currentText.currentEditorContent;
        this.api.postScanRequest('powerWordScan', anaRequest, (data) => {
          this.useScanResult(data);
          this.loading = false;
        }, (error) => { console.log('Power Words Error: ', error); this.loading = false; });
      }, 50);
    }
  }

  useScanResult(data) {
    // this.currentText.unPrepareMarkings('power-word-wrapper');
    this.setScore(data.power_words);
    this.list = [];
    if (data.list && data.list.length) {
      data.list.forEach((item)=>{
        // console.log(item);
        this.list.push(item.replacement);
      })
    }

    // for (const word of data.words) {
    //   this.words[word.id] = {
    //   };
    //   this.wordNumber++;
    // }
    // if (this.wordNumber > 0) {
    //   this.noWords = false;
    // } else {
    //   this.noWords = true;
    // }

    // this.editorMarkings = [];
    // for (const marking of data.markings) {
    //   this.editorMarkings.push({
    //     marker: 'power_word',
    //     offset: marking.offset,
    //     length: marking.length,
    //     relation: marking.relation
    //   });
    // }
    this.setDetailsHeight();
    // this.currentText.prepareMarkings(this.editorMarkings, 'power-word-wrapper');
  }

  close(removeMarkings = true) {
    this.detailed = false;
    if (this.textClickSubscription !== null) {
      this.textClickSubscription.unsubscribe();
    }
    if (this.markingsSubscription !== null) {
      this.markingsSubscription.unsubscribe();
    }
    if (removeMarkings) {
      this.currentText.removeMarkings();
      this.currentText.clickClasses = [];
    }
    this.setDetailsHeight();
  }

  open() {
    console.log('open')
    // this.currentText.clickClasses = this.markings;
    this.currentText.setMarkings(this.markings);

    this.markingsSubscription = this.currentText.markings.subscribe((reset: boolean) => {
      if (reset && this.detailed) {
        if (!this.currentText.activeMarkings.includes(this.markings[0])) {
          this.close(false);
        }
      }
    });

    this.detailed = true;
    this.setDetailsHeight();
  }

  itemClick() {
    if (this.detailed) {
      this.close();
    } else {
      this.open();
    }
  }

  showList() {
    this.appState.openInfoItem(
      WordListInfoComponent,
      'power_word_list',
      'Übersicht: Power-Wörter',
      this.list
    );
  }

  private setDetailsHeight() {
    window.setTimeout(() => {
      if (this.detailed) {
        const todoElem = document.getElementById('power-words-details-inner');
        const height = todoElem.offsetHeight;
        this.detailsHeight = height + 'px';
      } else {
        this.detailsHeight = '0px';
      }
    }, 20);
  }
}
