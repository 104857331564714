import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  private keyName = 'storageService';

  private storage = environment.storageType && environment.storageType == 'local' ? localStorage : sessionStorage;
  private localStorageObject = {};

  constructor() {
    // this.localStorageObject = window.localStorage.getItem[this.keyName];
    // if (this.localStorageObject === null) {
    //   this.localStorageObject = {};
    // }

    const localStorageObject = this.storage.getItem(this.keyName);

    if (!localStorageObject) {
      this.localStorageObject = {};
    } else {
      this.localStorageObject = JSON.parse(localStorageObject);
    }
  }

  store(moduleName: string, data: any) {
    this.localStorageObject[moduleName] = data;
    this.save();
  }

  modify(moduleName: string, mod: (data: any) => any) {
    this.localStorageObject[moduleName] = mod(
      typeof this.localStorageObject[moduleName] === 'undefined' ? {} : this.localStorageObject[moduleName]
    );
    this.save();
  }

  setProperty(moduleName, property, data) {
    this.modify(moduleName, (moduleObject) => {
      if (!moduleObject) {
        moduleObject = {};
      }
      moduleObject[property] = data;
      return moduleObject;
    });
  }

  getProperty(moduleName, property) {
    return typeof this.localStorageObject[moduleName] === 'undefined' ?
      undefined : this.localStorageObject[moduleName][property];
  }

  retrieve(moduleName: string) {
    return this.localStorageObject[moduleName];
  }

  clear(moduleName: string) {
    delete this.localStorageObject[moduleName];
    this.save();
  }

  clearAll(){
    this.localStorageObject = {};
    this.save();
  }

  private save() {
    this.storage.setItem(this.keyName, JSON.stringify(this.localStorageObject));
  }
}
