<mat-card class="index-card" [class.detailed]="detailed" [class.small-layout]="smallLayout">
    <mat-icon class="top-left-info-icon" svgIcon="info" appInfoButton="text-stats"
        infoTitle="Quantitative Text-Analyse"></mat-icon>
    <div class="result-panel-wrap" (click)="itemClick();">
        <div class="result-panel-content">
            <div>
                <b>Quantitative Text-Analyse</b>
            </div>
        </div>
    </div>
    <div class="score-details" [style.height]="detailsHeight">
        <div id="text-numbers-details-inner" class="score-details-inner">
            <div *ngIf="detailed" class="score-details-head">
                <div class="number-list">
                    <table class="number-table number-label-table">
                        <tr *ngFor="let number of numbers">
                            <td>{{ number.label }}</td>
                        </tr>
                    </table>
                    <table class="number-table number-value-table">
                        <tr *ngFor="let number of numbers">
                            <td>{{ number.value }}</td>
                        </tr>
                    </table>
                </div>
            </div>
            <div *ngIf="showCopyButton">
                <button (click)="copyToClipboard()">Zahlen Kopieren</button>
                <textarea id="text-number-copy-to-clipboard" class="number-values-hidden" style="opacity: 0; height: 0px;">
                </textarea>
            </div>
        </div>
    </div>
</mat-card>
