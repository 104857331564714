import { Directive, HostListener, Input, ElementRef, OnInit } from '@angular/core';
import { AppStateService } from '../services/app-state.service';
import { AccordionInfoComponent } from './info-item/accordion-info/accordion-info.component';

@Directive({
  selector: '[appInfoButton]'
})
export class InfoButtonDirective implements OnInit {

  @Input('appInfoButton') infoTextName: string;
  @Input() infoTitle: string;
  @Input() set infoText(infoText){
    this.data = infoText;
    this.setDisplay();
  };

  data = null;

  displayed = false;

  constructor(
    private appState: AppStateService,
    private el: ElementRef
  ) {
    this.appState.infoTextsChange.subscribe(() => {
      this.setDisplay();
    });
  }

  ngOnInit(): void {
    this.setDisplay();
  }

  @HostListener('click') onClick() {
    if (this.displayed) {
      this.appState.openInfoItem(
        AccordionInfoComponent,
        this.infoTextName,
        this.infoTitle,
        this.data
        );
      return false;
    }
  }

  private setDisplay() {
    if (
      (typeof this.appState.infoTexts !== 'undefined'
      && typeof this.appState.infoTexts[this.infoTextName] !== 'undefined')
      || this.data !== null
    ) {
      this.displayed = true;
    } else {
      this.displayed = false;
    }
    if (this.displayed) {
      this.el.nativeElement.style.display = null;
    } else {
      this.el.nativeElement.style.display = 'none';
    }
  }
}
