import {
  EditorMarkings,
  RequestTextBlock as TeoRequestTextBlock
} from 'src/app/TeoDefinitions';
import { OfficeEditor } from '../office-editor';
import { TextSection } from '../shared/models';
import {
  DocumentBlock,
  OfficeAdapter,
  SectionLoc,
  Wrapper
} from '../shared/types';
import { createLogger } from '../shared/utils';

const LOG = createLogger('Fulltext-Adapter');

export class OfficeFulltextAdapter implements OfficeAdapter {
  private documentBlocks: DocumentBlock[] = [];
  private wrappers: Record<string, Wrapper[]> = {};
  private activeWrappers: Wrapper[] = [];
  private recreateRequestText = true;

  constructor(private editor: OfficeEditor) {}

  async getRequestText(): Promise<TeoRequestTextBlock[]> {
    if (!this.recreateRequestText && this.documentBlocks.length) {
      return this.documentBlocks[0].reqBlocks;
    }

    this.recreateRequestText = false;

    // FIXME Imre sometimes context gets confused. Review untracking and clearing
    if (this.documentBlocks.length) {
      this.clear();
    }
    if (this.activeWrappers.length) {
      await this.clearActiveWrappers();
    }

    await Word.run(async (ctx) => {
      const $body = ctx.document.body;
      const $contentRange = $body.getRange('Whole');
      $contentRange.load('text,font');
      $contentRange.track(); // TODO untrack
      await ctx.sync();

      const section = new TextSection($contentRange);
      const text = section.text;

      this.documentBlocks = [
        {
          section,
          offset: 0,
          length: text.length,
          reqBlocks: [
            {
              string: text,
              type: 'text',
              length: text.length
            }
          ]
        }
      ];
    });

    return this.documentBlocks[0].reqBlocks;
  }

  private clear() {
    this.documentBlocks.forEach((block) => {
      block.section.untrack();
    });
    this.documentBlocks.length = 0;
  }

  private getDocumentBlock() {
    return this.documentBlocks[0];
  }

  async onUpdateAvailabelMarkings(markings: EditorMarkings) {
    LOG.info('>>> OnUpdateAvailableMarkings', markings);

    const markingsMap: Record<string, { markers: string[]; loc: SectionLoc }> =
      {};
    for (let marking of markings.markings) {
      const wMarker = 'wrap_' + marking.marker;
      const locKey = marking.offset + ':' + marking.length;
      const existing = markingsMap[locKey];
      if (existing) {
        if (!existing.markers.includes(wMarker)) {
          existing.markers.push(wMarker);
        }
      } else {
        markingsMap[locKey] = {
          loc: marking,
          markers: [wMarker]
        };
      }
    }

    const fullTextSection = this.getDocumentBlock().section;
    const mappedMarkings = Object.values(markingsMap);

    const ws = await fullTextSection.runWithSectionContext(
      async (_, ctx): Promise<Wrapper[]> => {
        const subSections = await fullTextSection.createSubSections(
          mappedMarkings.map((w) => w.loc),
          {
            trackAll: true,
            ctx
          }
        );
        return mappedMarkings.map((mappedMarking, idx) => ({
          markers: mappedMarking.markers,
          section: subSections[idx]
        }));
      }
    );

    LOG.debug('Markings Before', JSON.parse(JSON.stringify(this.wrappers)));

    ws.forEach((w) => {
      w.markers.forEach((marker) => {
        if (!this.wrappers[marker]) {
          this.wrappers[marker] = [];
        }
        this.wrappers[marker].push(w); // TODO don't push twice???
      });
    });

    LOG.debug('Markings After', JSON.parse(JSON.stringify(this.wrappers)));
  }

  init() {}

  private async clearActiveWrappers(): Promise<void> {
    let sections = this.activeWrappers.map((w) => w.section);
    if (sections.length) {
      TextSection.runWithSectionsContext(sections, async (sync) => {
        sections.forEach((section) => {
          section.resetHighlight();
          // LOG.info('>>> Reset Marker', section.text)
        });
        await sync();
      });
    }
    this.activeWrappers = [];
  }

  async onRemovedMarkings(removedMarkings: []): Promise<void> {
    for (const removedMarking of removedMarkings) {
      const wrapper = this.wrappers[removedMarking];
      if (wrapper) {
        LOG.info('>>> Found Wrapper to remove', wrapper);
      }
    }
  }

  async onUpdateMarkings(activeMarkings: string[]): Promise<void> {
    await this.clearActiveWrappers();

    activeMarkings.forEach((marking) => {
      const wrappers = this.wrappers[marking];
      if (!wrappers) {
        LOG.warn('Could not find wrappers for', marking, this.wrappers);
      } else {
        this.activeWrappers.push(...wrappers);
      }
    });

    const sections = this.activeWrappers.map((w) => w.section);
    if (sections.length) {
      TextSection.runWithSectionsContext(sections, (sync) => {
        this.activeWrappers.forEach((wrapper) => {
          wrapper.section.highlight(
            this.editor.getHighlightColor(wrapper.markers)
          );
        });

        return sync();
      });
    }
  }

  onSelectionChanged() {
    LOG.log('>>>>> ON SELECTION CHANGED');
  }

  async onBeforeAnalysis(): Promise<void> {
    LOG.log('>>>>> ON BEFORE ANALYSIS');
    this.wrappers = {};
    this.recreateRequestText = true;
  }
}
