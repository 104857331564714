import { Directive, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[appInfoItemContent]'
})
export class InfoItemContentDirective {

  constructor(public viewContainerRef: ViewContainerRef) { }

}
