<div id="teo-results" class="co-custom co-result" [class.small-layout]="!showEditor">
  <div *ngIf="showEditor" id="analysis-result-menue" [class.inactive]="currentText.analysisCounter === 0">
    <app-tli-display></app-tli-display>
    <div *ngIf="!(showBrandFit && displayPercent) && currentText.tliBenchmark >= 0" class="analysis-benchmark"
      [class.inactive]="currentText.analysisCounter === 0" appHelpLayerItem="benchmark-help" helpTitle="Benchmark"
      helpView="analysis-main">
      <div *ngIf="!displayPercent">
        <div class="bench-info">Zielwert:</div>
        <mat-icon class="bench-icon" svgIcon="stop_watch"></mat-icon><span class="bench-number">{{
          currentText.tliBenchmark }}</span>
        <span *ngIf="tliBenchmarkChange !== null" class="bench-change" [class.positive]="tliBenchmarkChange > 0"
          [class.negative]="tliBenchmarkChange < 0">{{ absBenchmarkChange() | number : '1.2-2' }}</span>
      </div>
      <div *ngIf="displayPercent">
        <div class="bench-info-percent">Abstand:
          <span *ngIf="scorePercentDistance !== null" class="bench-change bench-change-percent"
            [class.green]="scorePercentDistance <= 0" [class.red]="scorePercentDistance > 50"
            [class.yellow]="scorePercentDistance <= 50 && scorePercentDistance > 0">
            {{ scorePercentDistance | number : '1.0-0' }}%
          </span>
        </div>
      </div>
    </div>
    <app-brand-fit-button *ngIf="showBrandFit"></app-brand-fit-button>
    <!-- <mat-card matRipple class="button-card menu-button" [class.active]="currentCategory === 'tliDetails'"
      (click)="selectIndexCategory('tliDetails')" appHelpLayerItem="tli-details-help" helpTitle="Analyse Starten"
      helpView="analysis-main">
      <span class="button-card-text">
        <mat-icon class="button-icon" svgIcon="lens"></mat-icon>
        <br />
        Verständlichkeit
      </span>
    </mat-card> -->
    <mat-card *ngIf="performanceItems.length" matRipple class="button-card menu-button"
      [class.active]="currentCategory === 'activation'" (click)="selectIndexCategory('activation')"
      appHelpLayerItem="activation-help" helpTitle="Aktivierung" helpView="analysis-main">
      <span class="button-card-text">
        <mat-icon class="button-icon" svgIcon="activation"></mat-icon>
        <br />
        {{ categoryNames['activation'] }}
      </span>
    </mat-card>
    <mat-card *ngIf="brandItems.length" matRipple class="button-card menu-button"
      [class.active]="currentCategory === 'brand'" (click)="selectIndexCategory('brand')" appHelpLayerItem="brand-help"
      helpTitle="Brand" helpView="analysis-main">
      <span class="button-card-text">
        <mat-icon class="button-icon" svgIcon="brand_r"></mat-icon>
        <br />
        {{ categoryNames['brand'] }}
      </span>
    </mat-card>
    <mat-card *ngIf="styleItems.length" matRipple class="button-card menu-button"
      [class.active]="currentCategory === 'style'" (click)="selectIndexCategory('style')" appHelpLayerItem="stil-help"
      helpTitle="Stil" helpView="analysis-main">
      <span class="button-card-text">
        <mat-icon class="button-icon" svgIcon="write"></mat-icon>
        <br />
        {{ categoryNames['style'] }}
      </span>
    </mat-card>
    <mat-card *ngIf="statisticItems.length" matRipple class="button-card menu-button"
      [class.active]="currentCategory === 'statistic'" (click)="selectIndexCategory('statistic')"
      appHelpLayerItem="statistic-help" helpTitle="Statistik" helpView="analysis-main">
      <span class="button-card-text">
        <mat-icon class="button-icon" svgIcon="stats"></mat-icon>
        <br />
        {{ categoryNames['statistic'] }}
      </span>
    </mat-card>
    <mat-card *ngIf="toolItems.length" matRipple class="button-card menu-button"
      [class.active]="currentCategory === 'tools'" (click)="selectIndexCategory('tools')" appHelpLayerItem="tools-help"
      helpTitle="Rechtschreibung" helpView="analysis-main">
      <span class="button-card-text">
        <mat-icon class="button-icon" svgIcon="spellcheck"></mat-icon>
        <br />
        Rechtschreibung
      </span>
    </mat-card>
  </div>
  <div class="analysis-results-top">
    <button id='start-analysis' class="big-button"
      [class.inactive]="!currentText.textModified || currentText.analysisPending" (click)="analyzeText()"
      appHelpLayerItem="start-analysis-help" helpTitle="Analyse Starten" helpView="analysis-main">
      Analyse Starten
      <div class="big-button-ripple-layer" matRipple></div>
      <mat-progress-spinner class="big-button-spinner" [class.active]="currentText.analysisPending" color="primary"
        mode="indeterminate">
      </mat-progress-spinner>
    </button>
    <div class="analysis-menu">
      <a href="#" (click)="newAnalysis()">Neue Analyse</a>
      <a href="#" (click)="newAnalysis(true)">Konfiguration</a>
      <!-- <a href="#" appHelpLayerTrigger="analysis-main">Hilfe</a> -->
    </div>

    <h1 *ngIf="showEditor" [class.hidden]="!currentText.analysisCounter">Analyse-Ergebnisse</h1>

  </div>
  <div *ngIf="!showEditor" id="analysis-result-small-menue">
    <app-tli-display></app-tli-display>
    <div *ngIf="currentCategory" class="teo-dropdown-menu">
      <div class="active-item">
        <a href="#" (click)="toggleCategoryDropdown()"><span class="item-name">{{ categoryNames[currentCategory]
            }}</span>
          <mat-icon svgIcon="arrow_drop_down"></mat-icon>
        </a>
      </div>
      <div class="item-list" [style.height]="categoryDropdownHeight">
        <a *ngIf="performanceItems.length" href="#" (click)="selectIndexCategory('activation')"
          [class.active]="currentCategory === 'activation'"><span class="item-name">{{ categoryNames['activation']
            }}</span></a>
        <a *ngIf="brandItems.length" href="#" (click)="selectIndexCategory('brand')"
          [class.active]="currentCategory === 'brand'"><span class="item-name">{{ categoryNames['brand'] }}</span></a>
        <a *ngIf="styleItems.length" href="#" (click)="selectIndexCategory('style')"
          [class.active]="currentCategory === 'style'"><span class="item-name">{{ categoryNames['style'] }}</span></a>
        <a *ngIf="statisticItems.length" href="#" (click)="selectIndexCategory('statistic')"
          [class.active]="currentCategory === 'statistic'"><span class="item-name">{{ categoryNames['statistic']
            }}</span></a>
      </div>
    </div>
  </div>
  <div class="analysis-results-bottom-scroll-wrap">
    <div #resultsScroller class="analysis-results-bottom">
      <div class="analysis-results-bottom-scroll-inner">

        <!-- <div class="analysis-results-category" [class.active]="currentCategory === 'tliDetails'">
          <app-analysis-item *ngFor="let item of scoreItems; let i = index;" [class.hidden]="item.hidden" [itemName]="item.name"
            [data]="item.data" [childComponentType]="item.itemType" [inactive]="item.inactive" [order]="i">
          </app-analysis-item>
        </div> -->
        <div *ngIf="performanceItems.length" class="analysis-results-category"
          [class.active]="currentCategory === 'activation'">
          <app-analysis-item *ngFor="let item of performanceItems; let i = index;" [class.hidden]="item.hidden"
            [itemName]="item.name" [data]="item.data" [childComponentType]="item.itemType" [inactive]="item.inactive"
            [scannerName]="item.scannerName" [hidden]="item.hidden" [order]="i">
          </app-analysis-item>
        </div>
        <div *ngIf="brandItems.length" class="analysis-results-category" [class.active]="currentCategory === 'brand'">
          <app-analysis-item *ngFor="let item of brandItems; let i = index;" [class.hidden]="item.hidden"
            [itemName]="item.name" [data]="item.data" [childComponentType]="item.itemType" [inactive]="item.inactive"
            [scannerName]="item.scannerName" [hidden]="item.hidden" [order]="i">
          </app-analysis-item>
        </div>
        <div *ngIf="styleItems.length" class="analysis-results-category" [class.active]="currentCategory === 'style'">
          <app-analysis-item *ngFor="let item of styleItems; let i = index;" [class.hidden]="item.hidden"
            [itemName]="item.name" [data]="item.data" [childComponentType]="item.itemType" [inactive]="item.inactive"
            [scannerName]="item.scannerName" [hidden]="item.hidden" [order]="i">
          </app-analysis-item>
        </div>
        <!--
        <div class="analysis-results-category" [class.active]="currentCategory === 'structure'">
          <app-analysis-index *ngFor="let index of structureItems;" [class.hidden]="index.hidden"
            [indexName]="index.name" [indexTitle]="index.title" [indexType]="index.type" [detailed]="false">
          </app-analysis-index>
        </div> -->
        <div *ngIf="statisticItems.length" class="analysis-results-category"
          [class.active]="currentCategory === 'statistic'">
          <app-analysis-item *ngFor="let item of statisticItems; let i = index;" [class.hidden]="item.hidden"
            [itemName]="item.name" [data]="item.data" [childComponentType]="item.itemType" [inactive]="item.inactive"
            [scannerName]="item.scannerName" [hidden]="item.hidden" [order]="i">
          </app-analysis-item>
        </div>
        <div *ngIf="toolItems.length" class="analysis-results-category" [class.active]="currentCategory === 'tools'">
          <app-analysis-item *ngFor="let item of toolItems; let i = index;" [class.hidden]="item.hidden"
            [itemName]="item.name" [data]="item.data" [childComponentType]="item.itemType" [inactive]="item.inactive"
            [scannerName]="item.scannerName" [hidden]="item.hidden" [order]="i">
          </app-analysis-item>
        </div>
        <!-- <app-analyzer-results></app-analyzer-results> -->
      </div>
    </div>
  </div>
</div>
<div *ngIf="showEditor" id="teo-editor-container" #teoEditorWrap class="co-custom co-editor">
  <div id="teo-editor" [style.left]="fullscreenOffset" [style.width]="fullscreenWidth"
    [style.transition]="fullscreenTransition" [style.position]="fullscreenCssPosition"
    [style.z-index]="fullscreenZIndex">
    <div id="teo-editor-inner">
      <app-analysis-editor></app-analysis-editor>
    </div>
  </div>
</div>
