import { Component, OnInit } from '@angular/core';
import { CurrentTextService } from 'src/app/services/current-text.service';
import { environment } from 'src/environments/environment';
import { Pipe, PipeTransform } from '@angular/core';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss']
})
export class HistoryComponent implements OnInit {

  mainIndexName = environment.mainIndexName;
  activeEntry = null;
  constructor(
    public currentText: CurrentTextService
  ) {
    setInterval(() => {
      const now = new Date();
      currentText.history.forEach(element => {
        element.minutes_ago = Math.round((now.getTime() - element.time.getTime()) / 60000);
      });
    }, 30000);

    currentText.gotResults.subscribe((gotResults) => {
      if (gotResults) {
        this.setActive(currentText.history[currentText.history.length - 1]);
      }
    })
  }

  ngOnInit(): void {
  }

  recreate(entry) {
    this.currentText.reset(true);
    this.currentText.updateEditorContent(entry.text);
    this.setActive(entry);
  }

  setActive(entry) {
    if (this.activeEntry) {
      this.activeEntry.active = false;
    }
    this.activeEntry = entry;
    entry.active = true;
  }

  getIndex(entry){
    return Math.floor(entry.main_index_percentage) + '%'
  }
}
