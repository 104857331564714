// import { HelpLayerModule } from './help-layer/help-layer.module';
// import { HelpLayerComponent } from './help-layer/help-layer.component';
import { AnalysisEditorComponent } from './view-components/analysis-main/analysis-editor/analysis-editor.component';
import { AnalysisIndexComponent } from './view-components/analysis-main/analysis-item/analysis-index/analysis-index.component';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { AppComponent } from './app.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRippleModule } from '@angular/material/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';

import { DragDropModule } from '@angular/cdk/drag-drop';

import { MdePopoverModule } from '@material-extended/mde';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UserLoginComponent } from './view-components/user-login/user-login.component';
import { UserPasswordResetComponent } from './view-components/user-password-reset/user-password-reset.component';
import { AnalysisConfigurationComponent } from './view-components/analysis-configuration/analysis-configuration.component';
import { AnalysisMainComponent } from './view-components/analysis-main/analysis-main.component';
import { TliDisplayComponent } from './view-components/analysis-main/tli-display/tli-display.component';
import { AccordionInfoComponent } from './pinboard/info-item/accordion-info/accordion-info.component';
import { InfoItemComponent } from './pinboard/info-item/info-item.component';
import { InfoItemContentDirective } from './pinboard/info-item/info-item-content.directive';
import { NoteInfoComponent } from './pinboard/info-item/note-info/note-info.component';
import { PinboardComponent } from './pinboard/pinboard.component';
import { PinboardContentDirective } from './pinboard/pinboard-content.directive';
import { AnalysisItemComponent } from './view-components/analysis-main/analysis-item/analysis-item.component';
import { AnalysisItemContentDirective } from './view-components/analysis-main/analysis-item/analysis-item-content.directive';
import { KeywordScannerComponent } from './view-components/analysis-main/analysis-item/keyword-scanner/keyword-scanner.component';
import { InfoButtonDirective } from './pinboard/info-button.directive';
import { AnglicismsComponent } from './view-components/analysis-main/analysis-item/anglicisms/anglicisms.component';
import { CorporateTonalityComponent } from './view-components/analysis-main/analysis-item/corporate-tonality/corporate-tonality.component';
import { ThesaurusComponent } from './view-components/analysis-main/analysis-item/thesaurus/thesaurus.component';
import { HelpLayerModule } from './help-layer/help-layer.module';
import { HumanInterestComponent } from './view-components/analysis-main/analysis-item/human-interest/human-interest.component';
import { AnalysisItemScaleComponent } from './view-components/analysis-main/analysis-item-scale/analysis-item-scale.component';
import { TextNumbersComponent } from './view-components/analysis-main/analysis-item/textNumbers/textNumbers.component';
import { WordCloudComponent } from './view-components/analysis-main/analysis-item/wordCloud/wordCloud.component';
import { DynIndexDebugComponent } from './view-components/analysis-main/analysis-item/dynIndexDebug/dynIndexDebug.component';
import { CausalityScannerComponent } from './view-components/analysis-main/analysis-item/causalityScanner/causalityScanner.component';
import { WordListInfoComponent } from './pinboard/info-item/word-list-info/word-list-info.component';
import { DialogScannerComponent } from './view-components/analysis-main/analysis-item/dialogScanner/dialogScanner.component';
import localeDe from '@angular/common/locales/de';
import { registerLocaleData } from '@angular/common';
import { TopBarComponent } from './general-components/top-bar/top-bar.component';
import { ChangelogComponent } from './view-components/changelog/changelog.component';
import { LanguageToolComponent } from './view-components/analysis-main/analysis-item/languageTool/language-tool.component';
import { DialogContentComponent } from './general-components/dialog-content/dialog-content.component';
import { SideBarComponent } from './general-components/side-bar/side-bar.component';
import { FreePinsComponent } from './pinboard/free-pins/free-pins.component';
import { HistoryComponent } from './general-components/history/history.component';
import { PowerWordsComponent } from './view-components/analysis-main/analysis-item/power-words/power-words.component';
import { GenderThesaurusComponent } from './view-components/analysis-main/analysis-item/gender-thesaurus/gender-thesaurus.component';
import { SentimentComponent } from './view-components/analysis-main/analysis-item/sentiment/sentiment.component';
import { WordListComponent } from './view-components/analysis-main/analysis-item/word-list/word-list.component';
import { BrandFitButtonComponent } from './view-components/analysis-main/brand-fit-button/brand-fit-button.component';


@NgModule({
  declarations: [
    AppComponent,
    UserLoginComponent,
    UserPasswordResetComponent,
    AnalysisConfigurationComponent,
    AnalysisMainComponent,
    AnalysisIndexComponent,
    AnalysisEditorComponent,
    TliDisplayComponent,
    AccordionInfoComponent,
    WordListInfoComponent,
    NoteInfoComponent,
    InfoItemComponent,
    InfoItemContentDirective,
    PinboardComponent,
    PinboardContentDirective,
    AnalysisItemComponent,
    AnalysisItemContentDirective,
    KeywordScannerComponent,
    InfoButtonDirective,
    AnglicismsComponent,
    CorporateTonalityComponent,
    LanguageToolComponent,
    ThesaurusComponent,
    GenderThesaurusComponent,
    HumanInterestComponent,
    AnalysisItemScaleComponent,
    TextNumbersComponent,
    WordCloudComponent,
    CausalityScannerComponent,
    DialogScannerComponent,
    DynIndexDebugComponent,
    TopBarComponent,
    ChangelogComponent,
    DialogContentComponent,
    SideBarComponent,
    FreePinsComponent,
    HistoryComponent,
    PowerWordsComponent,
    SentimentComponent,
    WordListComponent,
    BrandFitButtonComponent,
    // DebugComponent,
    // HelpLayerModule
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,

    HelpLayerModule,

    DragDropModule,

    MatButtonModule,
    MatListModule,
    MatTabsModule,
    MatToolbarModule,
    MatIconModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatRippleModule,
    MatSlideToggleModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatExpansionModule,
    MatSelectModule,
    MatCheckboxModule,
    MatRadioModule,
    MatSnackBarModule,
    MatDialogModule,

    MdePopoverModule,
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'de-DE' }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor() {
    registerLocaleData(localeDe, 'de-DE');
  }
}
