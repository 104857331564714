import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-analysis-item-scale',
  templateUrl: './analysis-item-scale.component.html',
  styleUrls: ['./analysis-item-scale.component.scss']
})
export class AnalysisItemScaleComponent implements OnInit {
  @Input() indexValue: number;
  @Input() indexRawValue: number;
  @Input() indexType: string;
  @Input() leftMarking: string;
  @Input() centerMarking: string;
  @Input() rightMarking: string;
  @Input() inactive: boolean;

  @Input() benchmark = 10;

  @Input() peakBenchmark = 5;

  @Input() hideBenchmark = false;

  @Input() redWidth = 0.5;

  constructor() {
  }

  ngOnInit() {
  }

  benchmarkIndicatorPos() {
    return this.benchmark / 15 * 100;
  }

  benchmarkYellowPercent() {
    return this.redWidth * this.benchmarkIndicatorPos();
  }
  benchmarkGreenPercent() {
    return 1 * this.benchmarkIndicatorPos();
  }

  benchmarkPeakIndicatorPos() {
    return this.peakBenchmark / 30 * 100;
  }
  benchmarkPeakGreenPercent() {
    return ((this.benchmark / 15) * this.peakBenchmark) / 30 * 100;
  }
  benchmarkPeakGreenEndPercent() {
    var end = ((-1) * (2 / 3) * (this.benchmark - 15) + this.peakBenchmark) / 30 * 100;
    return end < 100 ? end : 100;
  }
  benchmarkPeakYellowPercent() {
    return this.benchmarkPeakGreenPercent() / 2;
  }
  benchmarkPeakYellowEndPercent() {
    var greenEnd = this.benchmarkPeakGreenEndPercent();
    return greenEnd + (100 - greenEnd) / 2;
  }

  rightMarkingPos() {
    return this.indexType === 'peak' ? 100 : this.benchmarkIndicatorPos();
  }

  indicatorPos() {
    return this.indexRawValue / 15 * 100;
  }

  peakIndicatorPos() {
    if (!this.indexRawValue) {
      return 0;
    } else if (this.indexRawValue < 30) {
      return this.indexRawValue / 30 * 100;
    }
    return 100;
  }

  // trafficLightLeft() {
  //   const stepNum = 7;

  //   let step = (this.indexValue / 100) * stepNum;
  //   step = Math.floor(step);
  //   if (step === stepNum) {
  //     step = stepNum - 1;
  //   }
  //   return step * (100 / (stepNum - 1));
  // }

  curveLeft() {
    return this.indexRawValue / 30 * 100;
  }

  getColor() {
    if (this.indicatorPos() < this.benchmarkYellowPercent()) {
      return 'red';
    } else if (this.indicatorPos() < this.benchmarkGreenPercent()) {
      return 'yellow';
    } else {
      return 'green';
    }
  }

  getPeakColor() {
    if (this.peakIndicatorPos() < this.benchmarkPeakYellowPercent()) {
      return 'red';
    } else if (this.peakIndicatorPos() <= this.benchmarkPeakGreenPercent()) {
      return 'yellow';
    } else if (this.peakIndicatorPos() < this.benchmarkPeakGreenEndPercent()) {
      return 'green';
    } else if (this.peakIndicatorPos() < this.benchmarkPeakYellowEndPercent()) {
      return 'yellow';
    } else {
      return 'red';
    }
  }

}
