import { Component, OnInit } from '@angular/core';
import { Validators, FormControl } from '@angular/forms';
import { AppStateService } from 'src/app/services/app-state.service';
import { JsonApiService } from 'src/app/services/json-api.service';

@Component({
  selector: 'app-user-password-reset',
  templateUrl: './user-password-reset.component.html',
  styleUrls: ['./user-password-reset.component.scss']
})
export class UserPasswordResetComponent implements OnInit {

  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);

  requestState = 0; // 0: nothing, 1: pending, 2: success, 3: failed

  constructor(
    public appState: AppStateService,
    private apiService: JsonApiService
  ) { }

  sendResetRequest(){
    if (this.emailFormControl.valid && this.requestState != 1) {
      this.requestState = 1;
      this.apiService.pwReset(this.emailFormControl.value, () => {
        //success
        this.requestState = 2;
      },()=>{
        //fail
        this.requestState = 3;
        this.emailFormControl.setErrors({
          serverError: true
        });
      });
    }
    this.emailFormControl.markAllAsTouched();
  }

  ngOnInit() {
  }

}
