import { create } from 'domain';
import { SectionLoc } from './types';
import { createSync } from './utils';

export class TextSection {
  private letters: string[];
  private range: Word.Range;
  private origColor: string;
  private parent: TextSection | null;
  private loc: SectionLoc;

  constructor(
    range: Word.Range,
    loc?: SectionLoc,
    parent?: TextSection | null
  ) {
    this.parent = parent || null;
    this.loc = loc || {
      offset: 0,
      length: range.text.length
    };
    this.range = range;
    this.origColor = range.font.highlightColor;
    this.letters = Array.from(new Set(range.text.split('')));
  }

  get text() {
    return this.range.text;
  }

  hasLocation(loc: SectionLoc): boolean {
    return this.loc.length === loc.length && this.loc.offset === loc.offset;
  }

  track(): TextSection {
    this.range.track();
    return this;
  }

  untrack(): TextSection {
    this.range.untrack();
    return this;
  }

  highlight(color: string): void {
    this.range.font.highlightColor = color;
  }

  resetHighlight(): void {
    this.range.font.highlightColor = this.origColor;
  }

  async runWithSectionContext<TResult>(
    batch: (
      sync: ReturnType<typeof createSync>,
      ctx: Word.RequestContext
    ) => Promise<TResult>
  ): Promise<TResult> {
    return Word.run([this.range], (ctx) => batch(createSync(ctx), ctx));
  }

  static async runWithSectionsContext<TResult>(
    sections: TextSection[],
    batch: (
      sync: ReturnType<typeof createSync>,
      ctx: Word.RequestContext
    ) => Promise<TResult>
  ): Promise<TResult> {
    const ranges = sections.map((section) => section.range);
    return Word.run(ranges, (ctx: Word.RequestContext) => {
      return batch(createSync(ctx), ctx);
    });
  }

  async createSubSections(
    positions: SectionLoc[],
    options?: {
      trackAll?: boolean;
      ctx?: Word.RequestContext;
    }
  ): Promise<TextSection[]> {
    const ctx = options?.ctx ?? this.range.context;
    const syncContext = createSync(ctx);

    const letterRanges = this.range.split(this.letters, true, false, false);
    letterRanges.load('items/text');
    await syncContext();

    const subRanges = positions.map(({ offset, length }) => {
      const first = letterRanges.items[offset];
      const last = letterRanges.items[offset + length - 1];
      const subRange = first.expandTo(last);
      subRange.load('text,font');
      return { range: subRange, offset, length };
    });
    await syncContext();

    return subRanges.map(({ range, offset, length }) => {
      const subSection = new TextSection(range, { offset, length }, this);
      return options?.trackAll ? subSection.track() : subSection;
    });
  }
}
