import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HelpLayerComponent } from './help-layer/help-layer.component';
import { HelpLayerItemDirective } from './help-layer-item.directive';
import { HelpLayerTriggerDirective } from './help-layer-trigger.directive';
import { HelpLayerService } from './help-layer.service';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';



@NgModule({
  providers: [
    HelpLayerService
  ],
  declarations: [
    HelpLayerComponent,
    HelpLayerItemDirective,
    HelpLayerTriggerDirective
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatCardModule
  ],
  exports: [
    HelpLayerComponent,
    HelpLayerItemDirective,
    HelpLayerTriggerDirective
  ]
})
export class HelpLayerModule { }
