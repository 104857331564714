<mat-card class="index-card" [class.detailed]="detailed">
  <mat-icon class="top-left-info-icon" svgIcon="info" appInfoButton="anglicisms" infoTitle="Anglizismen"></mat-icon>
  <div class="result-panel-wrap" (click)="itemClick();">
    <div class="result-panel-content">
      <div>
        Anglizismen-Thesaurus: <b class="score-text-number">{{ anglicismNumber }}</b>
      </div>
    </div>
  </div>
  <div id="anglicisms-details" class="score-details" [style.height]="detailsHeight">
    <div id="anglicisms-details-inner" class="score-details-inner">
      <div *ngIf="detailed" class="score-details-head">
        <div *ngIf="anglicismNumber">
          <div *ngIf="!currentAnglicism">
            Bitte wählen Sie ein markiertes Wort aus.
          </div>
          <div *ngIf="currentAnglicism">
            Wort:<div class="current-word">
              {{ anglicisms[currentAnglicism].word }}
              <span *ngIf="anglicisms[currentAnglicism].text">
                <br /><a href="#" class="lexi-link" (click)="openTextEntry()">Lexikalischen Eintrag
                  zeigen</a>
              </span>
            </div>
          </div>
        </div>
        <div *ngIf="!anglicismNumber">
          Keine Anglizismen gefunden.
        </div>
      </div>
      <div *ngIf="detailed && currentAnglicism">
        <h4>Übersetzungen</h4>
        <mat-radio-group [(ngModel)]="currentTranslationId" (change)="useReplacement()">
          <label *ngFor="let translation of anglicisms[currentAnglicism].translations; let i = index" class="score-todo"
            [class.active]="currentTranslationId === i">
            <div class="score-todo-text">{{ translation }}</div>
            <mat-radio-button [value]="i"></mat-radio-button>
          </label>
        </mat-radio-group>
        <div class="keyword-actions">
          <a href="#" (click)="resetReplacement()">Zurücksetzen</a>
        </div>
        <!-- <div class="keyword-infos">
                    <div class="keyword-info">Anzahl Worte: {{ wordCount }}</div>
                    <div class="keyword-info">Anzahl Keywords: {{ keywordCount }}</div>
                    <div class="keyword-info">Gesamtanteil Keywords: {{ keywordPercentage | percent }}</div>
                </div> -->
      </div>
    </div>
  </div>
</mat-card>
