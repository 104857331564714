import { AppStateService } from 'src/app/services/app-state.service';
import {
  Component,
  OnInit,
  ComponentFactoryResolver,
  ApplicationRef,
  Injector,
  Type,
  EmbeddedViewRef,
  ComponentRef,
  ElementRef,
  ChangeDetectorRef,
  ViewChild,
  EventEmitter
} from '@angular/core';
import { InfoItemComponent } from '../info-item/info-item.component';
import { DomSanitizer } from '@angular/platform-browser';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { PinboardContentDirective } from '../pinboard-content.directive';
import { element } from 'protractor';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-free-pins',
  templateUrl: './free-pins.component.html',
  styleUrls: ['./free-pins.component.scss']
})
export class FreePinsComponent implements OnInit {

  // @ViewChild(PinboardContentDirective) insertionPoint: PinboardContentDirective;


  freeInfoZIndex: number = 10;

  defaultDragPosition = { x: 0, y: 0 };

  infoItemNames: string[] = [];

  freeElements: any[] = [];

  smallLayout = environment.editor == 'office';

  constructor(
    private appState: AppStateService,
    public cd: ChangeDetectorRef,
    protected sanitizer: DomSanitizer
  ) {
    appState.freePins = this;

    const left = this.smallLayout ? 20 : window.innerWidth / 2 - 210;
    const top = window.innerHeight / 2 - 200;
    this.defaultDragPosition = { x: left, y: top };
  }

  ngOnInit() {
  }

  public isPinned(name: string) {
    if (this.infoItemNames.includes(name)) {
      return true;
    }
    return false;
  }

  public pinInfoItem(componentType: Type<any>, name: string, title: string, data: any) {
    if (!this.infoItemNames.includes(name)) {
      this.infoItemNames.push(name);
      const elementData = {
        componentType,
        name,
        title,
        data,
        parent: this,
        id: null,
        zIndex: 1,
        dragPosition: { x: this.defaultDragPosition.x, y: this.defaultDragPosition.y },
      };
        const id = this.freeElements.push(elementData) - 1;
        this.freeElements[id].id = id;
        this.freeElements[id].zIndex = this.freeInfoZIndex + id;
        this.appState.pinboardChange.emit();
        return id;

    } else {
      // @todo: return id of already existin item
      return null;
    }
  }

  public focusInfoItem(id: number) {
    let oldZIndex = null;

    this.freeElements.forEach((elem) => {
      if (elem.id === id) {
        oldZIndex = elem.zIndex;
        elem.zIndex = this.freeInfoZIndex + this.freeElements.length;
      }
    });

    this.freeElements.forEach((elem) => {
      if (elem.zIndex > oldZIndex) {
        elem.zIndex--;
      }
    });
  }

  public removeAllInfoItems() {
    this.freeElements = [];
    this.infoItemNames = [];
    this.appState.pinboardChange.emit();
  }

  public removeInfoItem(id: number) {
      this.freeElements = this.freeElements.filter((value) => {
        if (value.id === id) {
          this.infoItemNames = this.removeFromArray(this.infoItemNames, value.name);
          return false;
        }
        return true;
      });
    this.appState.pinboardChange.emit();
  }

  backgroundIsVisible(){
    return this.smallLayout && this.freeElements.length;
  }

  private removeFromArray(array: any[], elem: any) {
    return array.filter((value) => {
      return elem !== value;
    });
  }
}
