import { Component, OnInit, Input } from '@angular/core';
import { InfoItemContent } from '../info-item-content-interface';

@Component({
  selector: 'app-note-info',
  templateUrl: './note-info.component.html',
  styleUrls: ['./note-info.component.scss']
})
export class NoteInfoComponent implements OnInit, InfoItemContent {
  @Input() infoName: string;
  @Input() infoTitle: string;
  @Input() data: any;
  @Input() onBoard = true;
  constructor() { }

  ngOnInit() {
  }
}
