import { environment } from './../../environments/environment';
import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpErrorResponse } from '@angular/common/http';
import { AnalysisResult, AnalysisRequest, User, RequestTextBlock } from '../TeoDefinitions';
import { AppStateService } from './app-state.service';
import { isNull } from 'util';
import { StorageService } from './storage.service';
import { WordList } from '../view-components/analysis-main/analysis-item/word-list/word-list.component';

@Injectable({
  providedIn: 'root'
})
export class JsonApiService {
  private apiUrl = environment.apiUrl;
  private jsonApiUrl = this.apiUrl + 'json/';
  private apiToken = null;
  private storageId = 'json-api';

  constructor(
    private http: HttpClient,
    private appState: AppStateService,
    private storage: StorageService
  ) {
    const storedToken = this.storage.getProperty(this.storageId, 'apiToken');
    if (typeof storedToken == 'string') {
      this.apiToken = storedToken;
    }
    if (this.apiUrl == null) {
      let getUrl = window.location;
      this.apiUrl = getUrl.protocol + "//" + getUrl.host + "/api/";
      this.jsonApiUrl = this.apiUrl + 'json/';
    }

    appState.login.subscribe(() => {
      this.getInfoTexts(((texts) => {
        // is done here and not in appStateService to prevent circlular dependency
        appState.setInfoTexts(texts);
      }));
    });
  }

  public logIn(email: string, password: string, onSuccess, onError) {
    this.appState.queForLoading((finished) => {
      const request: any = { email, password };
      request.insecure = !environment.secureConnection;
      let loginUrl = this.jsonApiUrl + 'user/login';
      if (environment.useLdapLogin) {
        console.log('using ldap...')
        loginUrl = this.jsonApiUrl + 'user/loginLdap';
      }
      this.http.post(loginUrl, request).subscribe((response: User) => {
        this.apiToken = response.token;
        this.storage.setProperty(this.storageId, 'apiToken', response.token);
        finished();
        onSuccess({
          name: response.name,
          email: response.email,
          role: response.role,
          changelog: response.changelog ? response.changelog : []
        });
      }, (response) => {
        console.log('Login failed: ', response.error.message);
        finished();
        this.appState.snackBar.open('Login fehlgeschlagen.', null, {
          duration: 5000
        });
        onError();
      });
    });
  }

  public pwReset(email: string, onSuccess, onError) {
    this.appState.queForLoading((finished) => {
      this.http.post(this.jsonApiUrl + 'user/resetPassword', { email }).subscribe((response: any) => {
        finished();
        if (response.success) {
          onSuccess();
        } else {
          onError();
        }
      }, (response) => {
        finished();
        onError();
      });
    });
  }

  public logOut(onSuccess, onError) {
    if (this.apiToken == 'GicPcz3ZTQKDB7s3rwbxDGSdPMCSVfDJgCnia6cZpRixOlrK4ayzUgzQGNoj') { // exception for debug token
      onSuccess(true);
      this.appState.logout.emit();
      return;
    }
    this.basicGET('user/logout', (response: any) => {
      if (response.logout) {
        this.apiToken = null;
      }
      onSuccess(response.logout);
      this.appState.logout.emit();
    }, (error) => {
      this.appState.logout.emit();
      onError(error);
    });
  }

  public openReport(reportId: string, callback, onError) {
    this.http.post(this.apiUrl + 'other/textReport', {
      report_id: reportId,
      tli_mode: environment.mainIndexName == 'TLI',
      api_token: this.apiToken
    }, { responseType: 'text' }).subscribe((data) => {
      const win = window.open(null, 'report-' + reportId);
      win.document.write(data);
      callback();
    }, onError);
  }

  public analyzeText(
    request: AnalysisRequest,
    callback: (r: AnalysisResult) => void,
    onError?: (e: Error) => void
  ) {

    request.debug = 1;
    request.client = "teo-app";

    request.prepareHTMLText();

    this.postTeoTextRequest(request, 'analysis', (data) => {
      const result = new AnalysisResult(['comprend', 'abstract', 'activation'], data);
      callback(result);
    }, (error: HttpErrorResponse) => {
      this.showServerError(error);
      onError(error);
    });
  }

  public postMultiScanRequest(
    scanners: string[],
    request: AnalysisRequest,
    callback: (r: any) => void,
    onError?: (e: Error) => void
  ) {
    // this.appState.queForLoading((finished) => {
    request.client = "teo-app";
    request.prepareHTMLText();
    request.scanners = scanners;

    this.postTeoTextRequest(request, 'scanners', (data) => {
      callback(data);
      // finished();
    }, (error: HttpErrorResponse) => {
      this.showServerError(error);
      // finished();
      onError(error);
    });
    // });
  }

  public postWordListScanRequest(
    request: AnalysisRequest,
    callback: (r: any) => void,
    onError?: (e: Error) => void
  ) {
    // this.appState.queForLoading((finished) => {
    request.client = "teo-app";
    request.prepareHTMLText();

    this.postTeoTextRequest(request, 'wordListScan', (data) => {
      callback(data);
      // finished();
    }, (error: HttpErrorResponse) => {
      this.showServerError(error);
      // finished();
      onError(error);
    });
    // });
  }

  public postScanRequest(
    scanType: string,
    request: AnalysisRequest,
    callback: (r: any) => void,
    onError?: (e: Error) => void
  ) {
    request.client = "teo-app";
    request.prepareHTMLText();

    this.postTeoTextRequest(request, scanType, (data) => {
      callback(data);
    }, (error: HttpErrorResponse) => {
      this.showServerError(error);
      onError(error);
    });
  }

  public getWrapColors(callback: (colors: object) => void, onError?: (e: Error) => void) {
    this.basicGET('wrappers', (data) => {
      const colors = {};
      for (const color of data) {
        colors[color.wrapper] = color.color;
      }
      callback(colors);
    }, onError);
  }

  public getCausalConnectors(callback: (list: string[]) => void, onError?: (e: Error) => void) {
    this.basicGET('causalConnectors', callback, onError);
  }

  public getConfigOptions(callback: (options: object) => void, onError?: (e: Error) => void) {
    this.basicGET('configOptions', (data) => {
      const configOptions = {
        'target-audience': [],
        'strat-direction': [],
        'text-medium': [],
        'profile-id': null
      };
      let profile = data[0];
      data.forEach(element => {
        if (element.id == environment.evaluationProfileId) {
          profile = element;
        }
      });
      for (const option of profile.benchmark_configs) {
        if (option.category === 'audience'
          || option.category === 'strategy'
          || option.category === 'medium') {
          configOptions[this.translateOptionCategory(option.category)].push(option);
        }
      }
      configOptions['profile-id'] = profile.id;
      callback(configOptions);
    }, onError);
  }

  public getWordLists(callback: (list: WordList[]) => void, onError?: (e: Error) => void) {
    this.basicGET('wordLists', callback, onError);
  }

  private translateOptionCategory(category: string) {
    if (category == 'strategy') {
      return 'strat-direction';
    } else if (category == 'audience') {
      return 'target-audience';
    } else if (category == 'medium') {
      return 'text-medium';
    }
    return category;
  }

  public getInfoTexts(callback: (colors: object) => void, onError?: (e: Error) => void) {
    this.basicGET('infoTexts', (data) => {
      const texts = {};
      for (const text of data) {
        const textItem = {
          title: text.title,
          text: this.cleanRawHtml(text.text),
          order: text.order
        };
        if (typeof texts[text.item_name] !== 'undefined') {
          texts[text.item_name].push(textItem);
        } else {
          texts[text.item_name] = [textItem];
        }
      }
      callback(texts);
    }, onError);
  }

  public basicGET(requestName: string, onSuccess: (data: any) => void, onError?: (e: Error) => void) {
    // this.appState.queForLoading((finished) => {
    this.http.get(this.jsonApiUrl + requestName + '?api_token=' + this.apiToken).subscribe((data: any[]) => {
      onSuccess(data);
      // finished();
    }, (error: HttpErrorResponse) => {
      // finished();
      this.showServerError(error);
      onError(error);
    });
    // });
  }

  public cleanRawHtml(html: string) {

    // convert divs to linebreaks
    html = html.replace(/<\/div>/gmi, '§§br§§');
    // replace tags we want to keep
    html = html.replace(/<br.*?>/gmi, '§§br§§');
    html = html.replace(/<b.*?>/gmi, '§§b§§');
    html = html.replace(/<i.*?>/gmi, '§§i§§');
    html = html.replace(/<\/b.*?>/gmi, '§§/b§§');
    html = html.replace(/<\/i.*?>/gmi, '§§/i§§');
    // remove all tags
    html = html.replace(/<.*?>/gmi, '');
    // reinsert replaced tags
    html = html.replace(/§§b§§/gmi, '<b>');
    html = html.replace(/§§i§§/gmi, '<i>');
    html = html.replace(/§§\/b§§/gmi, '</b>');
    html = html.replace(/§§\/i§§/gmi, '</i>');
    html = html.replace(/§§br§§/gmi, '<br/>');

    return html;
  }

  private postTeoTextRequest(request: AnalysisRequest, action: string, callback: (data: any) => void, onError) {
    this.postAuthenticatedTeoRequest(request, action, callback, onError);
  }


  public postAuthenticatedTeoRequest(request: any, action: string, callback: (data: any) => void, onError) {
    request.api_token = this.apiToken;
    this.http.post(this.jsonApiUrl + action, request).subscribe((data: any) => {
      if (data.notice) {
        this.appState.showMessage(data.notice);
      }
      callback(data);
    }, onError);
  }

  private filterNbsp(text: string) {
    // const re = new RegExp(String.fromCharCode(160), 'g');
    return text.replace(/&nbsp;/g, ' ');
    // return text.replace(/\u00a0/g, ' ');
  }

  // private buildAnalyzeJSON(request: AnalysisRequest) {
  //   return '{}';
  // }

  // private buildResponseObject(response: string, callback: (r: AnalysisResult) => void) {

  // }

  private showServerError(error: HttpErrorResponse) {
    console.error(error);
    if (error.status === 401 || error.status === 403) {
      if (error.error.message && error.error.message != 'Unauthenticated.') {
        console.log(error.error.message)
        this.appState.showMessage(error.error.message);
      } else {
        this.appState.setView('login');
        this.appState.snackBar.open('Ihr Login ist abgelaufen', null, {
          duration: 5000
        });
      }
    } else {
      this.appState.snackBar.open('Serverfehler', null, {
        duration: 5000
      });
    }
  }

}
