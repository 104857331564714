import { Component, OnInit, Input } from '@angular/core';
import { CurrentTextService } from 'src/app/services/current-text.service';
import { JsonApiService } from 'src/app/services/json-api.service';
import { AnalysisRequest } from 'src/app/TeoDefinitions';
import { AnalysisItemContent } from '../analysis-item-content-interface';

@Component({
  selector: 'app-sentiment',
  templateUrl: './sentiment.component.html',
  styleUrls: ['../analysis-item-shared.scss', './sentiment.component.scss']
})
export class SentimentComponent implements OnInit, AnalysisItemContent {
  data: any;
  itemName: string;
  @Input() inactive: boolean;
  @Input() scannerName: string;

  detailed = false;

  loading = false;

  words = [];

  // editorMarkings = [];

  wordNumber = 0;

  textClickSubscription = null;
  markingsSubscription = null;

  markings = ['wrap_sentiment-negative', 'wrap_sentiment-positive'];

  detailsHeight = '0px;';

  // benchmark = 5; /

  percentScore = 0;
  scaleScore = 0;
  // scaleMarking = '5 %';
  // scaleBenchmark = 10;
  scaleInactive = false;

  value = 0;
  valueWeighted = 0;


  constructor(
    private currentText: CurrentTextService,
    private api: JsonApiService
  ) {
    // this.currentText.gotResults.subscribe(() => {
    //   window.setTimeout(() => {
    //     this.setDetailsHeight();
    //   }, 50);
    // });
    this.currentText.gotScannerResults.subscribe((dataArray) => {
      if (dataArray && dataArray[this.scannerName]) {
        this.useScanResult(dataArray[this.scannerName]);
      }
    });
  }

  ngOnInit() {
  }

  setScore(data) {
    this.value = data.sentiment;
    this.valueWeighted = data.sentiment_weighted;
    this.scaleScore = this.value + 7.5;
    this.scaleScore = this.scaleScore > 15 ? 15 : this.scaleScore;
    this.scaleScore = this.scaleScore < 0 ? 0 : this.scaleScore;
    this.percentScore = this.scaleScore / 15 * 100;
    // const wordNum = data.num_words;
    // this.wordNumber = data.num_power_words;
    // this.wordsPercent = this.wordNumber / wordNum * 100;
    // this.scaleScore = this.wordsPercent / this.benchmark * 10;
    // if(this.scaleScore > 15){
    //   this.scaleScore = 15;
    // }
    // this.wordsPercent = Math.floor(this.wordsPercent);
  }

  scan() {
    if (!this.loading) {
      this.loading = true;
      this.words = [];
      this.wordNumber = 0;
      this.currentText.unPrepareMarkings('sentiment-wrapper');
      setTimeout(() => {  // wait for wrappers to be removed
        const anaRequest = new AnalysisRequest();
        anaRequest.text = this.currentText.currentEditorContent;
        this.api.postScanRequest('sentimentScan', anaRequest, (data) => {
          this.useScanResult(data);
          this.loading = false;
        }, (error) => { console.log('Power Words Error: ', error); this.loading = false; });
      }, 50);
    }
  }


  useScanResult(data) {
    this.setScore(data.sentiment);
    this.setDetailsHeight();
  }

  close(removeMarkings = true) {
    this.detailed = false;
    if (this.textClickSubscription !== null) {
      this.textClickSubscription.unsubscribe();
    }
    if (this.markingsSubscription !== null) {
      this.markingsSubscription.unsubscribe();
    }
    if (removeMarkings) {
      this.currentText.removeMarkings();
      this.currentText.clickClasses = [];
    }
    this.setDetailsHeight();
  }

  open() {
    // this.currentText.clickClasses = this.markings;
    this.currentText.setMarkings(this.markings);

    this.markingsSubscription = this.currentText.markings.subscribe((reset: boolean) => {
      if (reset && this.detailed) {

        if (!this.currentText.activeMarkings.includes(this.markings[0])) {
          this.close(false);
        }
      }
    });

    this.detailed = true;
    this.setDetailsHeight();
  }

  itemClick() {
    if (this.detailed) {
      this.close();
    } else {
      this.open();
    }
  }

  private setDetailsHeight() {
    window.setTimeout(() => {
      if (this.detailed) {
        // const todoElem = document.getElementById('sentiment-details-inner');
        // const height = todoElem.offsetHeight;
        // this.detailsHeight = height + 'px';
      } else {
        this.detailsHeight = '0px';
      }
    }, 20);
  }
}
