import { Directive, Input, ElementRef } from '@angular/core';
import { HelpLayerService } from './help-layer.service';

@Directive({
  selector: '[appHelpLayerItem]'
})
export class HelpLayerItemDirective {

  @Input('appHelpLayerItem') infoTextName: string;
  @Input() helpTitle: string;
  @Input() helpView: string;

  constructor(
    private helpLayer: HelpLayerService,
    private el: ElementRef
  ) {
    this.helpLayer.helpLayerOpen.subscribe((viewName: string) => {
      if (viewName === this.helpView) {
        const domRect = this.el.nativeElement.getBoundingClientRect();

        const x = domRect.right;  // right edge
        const y = domRect.top + Math.round((domRect.bottom - domRect.top) / 2); // vertically centered
        // const y = domRect.top; // vertically top

        this.helpLayer.addItem({
          infoTextName: this.infoTextName,
          title: this.helpTitle,
          viewName: this.helpView,
          position: {
            x,
            y
          }
        });
      }
    });
  }

}
