<div [class.peak]="indexType === 'peak'" [class.number]="indexType === 'number'" [class.gray]="inactive">
  <div *ngIf="indexType === 'default'" class="score-scale">
    <div *ngIf="!hideBenchmark" class="score-scale-bench-indicator" [style.left]="benchmarkIndicatorPos() + '%'">
    </div>
    <div class="score-scale-bar red first" [style.width]="benchmarkYellowPercent() + '%'"></div>
    <div class="score-scale-bar yellow" [style.width]="benchmarkGreenPercent() - benchmarkYellowPercent() + '%'"></div>
    <div class="score-scale-bar green last" [style.width]="100 - benchmarkGreenPercent() + '%'"></div>
    <div class="score-scale-indicator" [ngClass]="getColor()" [style.left]="indicatorPos() + '%'">
    </div>
  </div>
  <div *ngIf="indexType === 'peak'" class="score-scale">
    <!-- <div class="score-scale-bench-indicator" [style.left]="benchmarkPeakIndicatorPos() + '%'">
    </div> -->
    <div class="score-scale-bar red first" [style.width]="benchmarkPeakYellowPercent() + '%'"></div>
    <div class="score-scale-bar yellow"
      [style.width]="benchmarkPeakGreenPercent() - benchmarkPeakYellowPercent() + '%'"></div>
    <div class="score-scale-bar green"
      [style.width]="benchmarkPeakGreenEndPercent() - benchmarkPeakGreenPercent() + '%'">
    </div>
    <div class="score-scale-bar yellow"
      [style.width]="benchmarkPeakYellowEndPercent() - benchmarkPeakGreenEndPercent() + '%'">
    </div>
    <div class="score-scale-bar red last" [style.width]="100 - benchmarkPeakYellowEndPercent() + '%'"></div>
    <div class="score-scale-indicator" [ngClass]="getPeakColor()" [style.left]="peakIndicatorPos() + '%'">
    </div>
    <div class="score-scale-bar"></div>
  </div>
  <div *ngIf="indexType !== 'number'" class="scale-markings">
    <div class="scale-marking left"><span>{{leftMarking}}</span></div>
    <div class="scale-marking center"><span>{{centerMarking}}</span></div>
    <div class="scale-marking right" [style.left]="rightMarkingPos() + '%'"><span>{{rightMarking}}</span></div>
  </div>
</div>
