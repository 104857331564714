import { Directive, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[appAnalysisItemContent]'
})
export class AnalysisItemContentDirective {

  constructor(public viewContainerRef: ViewContainerRef) { }

}
