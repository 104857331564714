import { Component, OnInit, Input } from '@angular/core';
import { AccordionInfoComponent } from 'src/app/pinboard/info-item/accordion-info/accordion-info.component';
import { AppStateService } from 'src/app/services/app-state.service';
import { CurrentTextService } from 'src/app/services/current-text.service';
import { JsonApiService } from 'src/app/services/json-api.service';
import { AnalysisRequest } from 'src/app/TeoDefinitions';
import { AnalysisItemContent } from '../analysis-item-content-interface';

export interface WordList {
  active: boolean;
  description?: String;
  display_properties: number;
  id: number;
  // is_whitelist: boolean;
  search_mode: number;
  order: number;
  name: string;
  relation_type: number;
  scale_benchmark?: number;
  scale_type?: number;
  subscription_id: number;
  title: string;
  title_entries: string;
  title_root_entry: string;
  use_description: boolean;
  use_for_ct: boolean;
  current_text_entries?: Array<WordListEntry>;
}
export interface WordListEntry {
  active: boolean;
  description?: string;
  expressions: Array<WordListExpression>;
  id: number;
  main_expression_id: number;
  title: string;
  word_list_id: number;
  found_by: number;
  current_relation?: number;
}
export interface WordListExpression {
  full_expression: string;
  id: number;
  is_pattern: boolean;
  priority: number;
  word_list_entry_id: number;
}

@Component({
  selector: 'app-word-list',
  templateUrl: './word-list.component.html',
  styleUrls: ['../analysis-item-shared.scss', './word-list.component.scss']
})

export class WordListComponent implements OnInit, AnalysisItemContent {
  @Input() data: any;
  itemName: string;
  @Input() inactive: boolean;
  @Input() scannerName: string;

  detailed = false;

  loading = false;

  foundExp = [];
  relations = {};

  selectedExpression: number = null;
  currentRadioSelection: number;
  // editorMarkings = [];

  brandFit = true;

  textClickSubscription = null;
  markingsSubscription = null;

  markings = [];

  detailsHeight = '0px;';

  scaleInactive = true;
  scaleMarking = '';
  wordPercentage = 0;
  scaleScore = 0;
  scaleBenchmark = 10;

  infoText: any[] = null;

  wordList: WordList = {
    active: false,
    description: '',
    display_properties: 0,
    id: 0,
    // is_whitelist: false,
    search_mode: 0,
    order: 0,
    name: '',
    relation_type: 0,
    scale_benchmark: 0,
    scale_type: 0,
    subscription_id: 0,
    title: 'Title...',
    title_entries: '',
    title_root_entry: '',
    use_description: false,
    use_for_ct: false
  };

  constructor(
    private currentText: CurrentTextService,
    private appState: AppStateService
  ) {
    this.currentText.gotScannerResults.subscribe((data) => {


      this.selectedExpression = null;
      this.resetRadioSelection();
      if (
        data['word-lists']
        && data['word-lists']['word-lists']
        && data['word-lists']['word-lists'].length
      ) {
        data['word-lists']['word-lists'].forEach((wl) => {
          if (wl.id == this.wordList.id) {
            this.wordList = wl;
            console.log('got WordList Data "' + this.wordList.name + '":', this.wordList);

            // if (
            //   this.wordList.relation_type == 3
            //   || this.wordList.relation_type == 2
            //   || this.wordList.relation_type == 1
            // ) {
            this.initFoundExp();
            // }
            if (this.wordList.scale_type == 1 && this.wordList.scale_benchmark !== undefined) {
              this.calcScore();
              this.scaleInactive = false;
            }
            // this.useScanResult(dataArray[this.scannerName]);
            if (this.wordList.description) {
              this.infoText = [
                {
                  text: this.wordList.description
                }
              ];
            } else {
              this.infoText = null;
            }
            this.checkBrandFit();
          }
        })
      }
    });
  }

  ngOnInit() {
    if (this.data) {
      this.wordList = this.data.wordList;
      if (this.wordList.relation_type == 0) {
        this.markings = [
          'wrap_' + this.wordList.name + '-prio-0',
          'wrap_' + this.wordList.name + '-prio-1',
          'wrap_' + this.wordList.name + '-prio-2',
        ];
      } else {
        this.markings = ['wrap_' + this.wordList.name + '-prio-0'];
      }
    }
  }

  checkBrandFit() {
    this.brandFit = true;
    if (this.wordList.use_for_ct) {
      if (!this.wordList.scale_benchmark) {
        this.brandFit = !this.getFindingNum();
      } else if (
        this.wordList.scale_type !== undefined
        && ((
          // benchmark set, but does not have scale
          this.wordList.scale_type == 0
          && this.countFindings() > this.wordList.scale_benchmark)
          // or benchmark set with scale
          || (this.wordList.scale_type == 1
            && this.wordPercentage < this.wordList.scale_benchmark
          ))
      ) {
        this.brandFit = false;
      }
    }
    this.appState.updateBrandFit('word-list-' + this.wordList.name, this.brandFit);
  }

  calcScore() {
    if (
      this.currentText.wordNum > 0
      && this.wordList.scale_benchmark > 0
    ) {
      let percentage = this.countFindings() / this.currentText.wordNum;
      this.wordPercentage = Math.floor(percentage * 100);
      this.scaleMarking = this.wordList.scale_benchmark + ' %';
      this.scaleScore = this.wordPercentage / this.wordList.scale_benchmark * 10;
      if (this.scaleScore > 15) {
        this.scaleScore = 15;
      }
    } else {
      this.wordPercentage = -1;
    }
  }

  countFindings() {
    let vioNum = this.foundExp.length;
    for (const i in this.foundExp) {
      if (this.foundExp[i].replaced) {
        vioNum--;
      }
    }
    return vioNum;
  }

  getFindingNum() {
    const prio = this.wordList.relation_type < 2 ? null : 0;
    let num = 0;
    for (const i in this.foundExp) {
      if (
        (prio == null || this.foundExp[i].foundExpression.priority == prio)
        && !this.foundExp[i].replaced
      ) {
        num++;
      }
    }
    return num;
  }

  // scan() {
  //   if (!this.loading) {
  //     this.currentText.unPrepareMarkings('ct-wrapper');
  //     setTimeout(() => {  // wait for wrappers to be removed
  //       const anaRequest = new AnalysisRequest();
  //       anaRequest.text = this.currentText.currentEditorContent;
  //       this.api.postScanRequest('ctScan', anaRequest, (data) => {
  //         this.useScanResult(data);
  //         setTimeout(() => {
  //           this.currentText.prepareMarkings(this.editorMarkings, 'ct-wrapper');
  //           this.currentText.setMarkings(this.markings);
  //           this.setDetailsHeight();
  //         }, 50); // wait for wrappers to be inserted
  //       }, (error) => { console.log('Corporate Tonality Error: ', error); this.loading = false; });
  //     }, 50);
  //   }
  // }

  initFoundExp() {
    this.foundExp = [];
    this.relations = {};

    for (const entry of this.wordList.current_text_entries) {

      const foundExpression = this.getFoundExpression(entry);
      const mainExpression = this.getMainExpression(entry);

      // 0 -> simple list,
      // 1 -> equal synonyms,
      // 2 -> replacements for main expression,
      // 3 -> main expression is replacement for others
      // 4 -> whitelist
      console.log(this.wordList);
      if (this.wordList.relation_type == 3) {
        this.foundExp.push(
          this.createViolationExpObj(foundExpression, mainExpression, entry)
        );
        // } else if (this.wordList.relation_type == 2) {
        //   this.foundExp.push(
        //     this.createImprovementsExpObj(foundExpression, mainExpression, entry)
        //   );
      } else if (this.wordList.relation_type == 1 || this.wordList.relation_type == 2) {
        this.foundExp.push(
          this.createSynonymsExpObj(foundExpression, mainExpression, entry)
        );
      } else {
        this.foundExp.push(
          this.createSimpleExpObj(foundExpression, mainExpression, entry)
        );
      }
      this.relations[entry.current_relation] = this.foundExp.length - 1;
    }
    this.setDetailsHeight();
  }

  private createViolationExpObj(foundExpression, mainExpression, entry) {
    return {
      word: foundExpression.full_expression,
      foundExpression,
      replacements: [mainExpression],
      id: entry.current_relation,
      replaced: false
    };
  }

  private createSynonymsExpObj(foundExpression, mainExpression, entry) {
    const replacements = [];
    let current = null;
    entry.expressions.forEach((exp, i) => {
      if (exp.priority > 0) {
        replacements.push(exp);
        if (exp.full_expression == foundExpression.full_expression) {
          current = i;
        }
      }
    });
    return {
      word: foundExpression.full_expression,
      foundExpression,
      replacements: replacements,
      id: entry.current_relation,
      replaced: false,
      text: entry.description,
      currentReplacement: current
    };
  }

  private createSimpleExpObj(foundExpression, mainExpression, entry) {
    console.log('Test')
    return {
      word: foundExpression.full_expression,
      foundExpression,
      replacements: [],
      id: entry.current_relation,
      replaced: false
    };
  }

  private onWordClickViolations(element) {
    const rel = this.relations[element.relation];
    this.foundExp[rel].replaced = !this.foundExp[rel].replaced;

    this.useReplacement(element.relation, 0);
  }

  private onWordClickSynonyms(element) {
    this.selectedExpression = this.relations[element.relation];
    this.resetRadioSelection();
    this.setDetailsHeight();
  }

  private getFoundExpression(entry: WordListEntry) {
    for (const exp of entry.expressions) {
      if (exp.id == entry.found_by) {
        return exp;
      }
    }
    return null;
  }

  private getMainExpression(entry: WordListEntry) {
    for (const exp of entry.expressions) {
      if (exp.id == entry.main_expression_id) {
        return exp;
      }
    }
    return null;
  }

  onRadioSelect() {
    if (this.selectedExpression != null) {
      this.foundExp[this.selectedExpression].currentReplacement = this.currentRadioSelection;
      this.foundExp[this.selectedExpression].replaced = true;
      this.useReplacement(
        this.foundExp[this.selectedExpression].id,
        this.currentRadioSelection
      );
    }
  }

  private resetRadioSelection() {
    if (this.selectedExpression != null) {
      this.currentRadioSelection = this.foundExp[this.selectedExpression].currentReplacement;
      if (this.currentRadioSelection == undefined) {
        this.currentRadioSelection = null;
      }
    }
  }

  resetReplacement() {
    const exp = this.foundExp[this.selectedExpression];
    exp.replaced = false;
    this.useReplacement(exp.id, null);
    this.currentRadioSelection = null;
    this.foundExp[this.selectedExpression].currentReplacement = null;
    // exp.replaced = true;
  }

  useReplacement(relationId, replacementIndex) {
    const rel = this.relations[relationId];
    // const editorNode = document.getElementById('editor-text');
    // // const itemNodes = editorNode.querySelectorAll('[itemprop=' + violationId + ']');
    // const itemNodes = editorNode.getElementsByClassName('wrap_' + this.wordList.name + ' relation_' + relationId);

    // if (this.foundExp[rel].replaced) {
    //   this.foundExp[rel].content = [itemNodes[0].innerHTML];
    //   itemNodes[0].innerHTML = this.foundExp[rel].replacements[replacementIndex].full_expression;
    //   itemNodes[0].classList.add('wrapper-corrected');
    // } else {
    //   if (!this.foundExp[rel].content[0]) {
    //     console.log('Fehler?');
    //     itemNodes[0].innerHTML = this.foundExp[rel].word;
    //   } else {
    //     itemNodes[0].innerHTML = this.foundExp[rel].content[0];
    //   }
    //   itemNodes[0].classList.remove('wrapper-corrected');
    // }
    // for (var i = 1; i < itemNodes.length; i++) {
    //   if (this.foundExp[rel].replaced) {
    //     this.foundExp[rel].content.push(itemNodes[i].innerHTML);
    //     itemNodes[i].innerHTML = '';
    //   } else {
    //     itemNodes[i].innerHTML = this.foundExp[rel].content[i];
    //     // itemNodes[i].classList.remove('wrapper-corrected');
    //   }
    // }
    let replacements;
    let origContent;
    let isCorretion = false;

    if (this.foundExp[rel].replaced) {
      replacements = [this.foundExp[rel].replacements[replacementIndex].full_expression];
      isCorretion = true;
    } else {
      replacements = this.foundExp[rel].content;
    }
    origContent = this.replaceByRelation(
      relationId,
      replacements,
      isCorretion
    );
    if (this.foundExp[rel].content === undefined) {
      console.log('setting content', origContent)
      this.foundExp[rel].content = origContent;
    }

    this.checkBrandFit();
  }

  replaceByRelation(relationId, replacement: string[], isCorretion) {

    console.log('replace:', relationId, replacement, isCorretion)

    const editorNode = document.getElementById('editor-text');
    // const itemNodes = editorNode.getElementsByClassName('wrap_' + this.wordList.name + ' relation_' + relationId);
    const itemNodes = editorNode.getElementsByClassName('relation_' + relationId);
    const replacedContent = [itemNodes[0].innerHTML];

    itemNodes[0].innerHTML = replacement[0];
    if (isCorretion) {
      itemNodes[0].classList.add('wrapper-corrected');
    } else {
      itemNodes[0].classList.remove('wrapper-corrected');
    }
    for (var i = 1; i < itemNodes.length; i++) {
      replacedContent.push(itemNodes[i].innerHTML);
      if (replacement[i]) {
        itemNodes[i].innerHTML = replacement[i];
      } else {
        itemNodes[i].innerHTML = '';
      }
    }
    const event = new Event('input');
    event.initEvent('input', true, true);
    editorNode.dispatchEvent(event);
    return replacedContent;
  }

  correctAllViolations() {
    if (this.wordList.relation_type == 3) {
      for (const vio of this.foundExp) {
        vio.replaced = true;
        this.useReplacement(vio.id, 0);
      }
    }
  }

  openTextEntry() {
    const name = 'thesaurus-' + this.foundExp[this.selectedExpression].word;
    const title = 'Lexikalischer Eintrag: ' + this.foundExp[this.selectedExpression].word;
    this.appState.openInfoItemCustomText(
      AccordionInfoComponent,
      name,
      title,
      this.foundExp[this.selectedExpression].text
    );
  }

  close(removeMarkings = true) {
    this.detailed = false;
    if (this.textClickSubscription !== null) {
      this.textClickSubscription.unsubscribe();
    }
    if (this.markingsSubscription !== null) {
      this.markingsSubscription.unsubscribe();
    }
    if (removeMarkings) {
      this.currentText.removeMarkings();
      this.currentText.clickClasses = [];
    }
    this.setDetailsHeight();
  }

  open() {
    this.currentText.clickClasses = this.markings;
    this.currentText.setMarkings(this.markings);
    this.textClickSubscription = this.currentText.textClick.subscribe((data) => {
      if (data.marker === this.markings[0]) {
        data.relations.forEach(element => {
          console.log('got click', element)
          // if (element.marker == this.wordList.name) {
          if (this.wordList.relation_type == 3) {
            this.onWordClickViolations(element);
          } else if (this.wordList.relation_type == 2) {
            this.onWordClickSynonyms(element);
          } else if (this.wordList.relation_type == 1) {
            this.onWordClickSynonyms(element);
          }

          // }
        });
      }
    });
    this.markingsSubscription = this.currentText.markings.subscribe((reset: boolean) => {
      if (reset && this.detailed) {
        if (!this.currentText.activeMarkings.includes(this.markings[0])) {
          this.close(false);
        }
      }
    });
    this.detailed = true;
    // window.setTimeout(() => {
    this.setDetailsHeight();
    // }, 500);
  }

  itemClick() {
    if (this.detailed) {
      this.close();
    } else {
      this.open();
    }
  }

  private setDetailsHeight() {
    window.setTimeout(() => {
      if (this.detailed) {
        const todoElem = document.getElementById(this.wordList.name + '-details-inner');
        const height = todoElem.offsetHeight;
        this.detailsHeight = height + 'px';
      } else {
        this.detailsHeight = '0px';
      }
    }, 20);
  }
}
