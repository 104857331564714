<div id="teo-main" [class.small-layout]="smallLayout">
    <mat-progress-bar id="teo-loader" [style.opacity]="appState.loading ? 1 : 0" [mode]="appState.loadingMode"
        color="accent"></mat-progress-bar>
    <app-help-layer [infoTextEmitter]="appState.infoTextsChange"></app-help-layer>

    <app-top-bar *ngIf="appState.userIsLoggedIn()"></app-top-bar>

    <div class="teo-view" [class.active-view]="appState.activeView === 'login'">
        <app-user-login></app-user-login>
    </div>
    <div class="teo-view" [class.active-view]="appState.activeView === 'reset-password'">
        <app-user-password-reset></app-user-password-reset>
    </div>
    <div class="teo-view" [class.active-view]="appState.activeView === 'changelog'">
        <app-changelog></app-changelog>
    </div>
    <div class="teo-view" [class.active-view]="appState.activeView === 'analysis-configuration'">
        <app-analysis-configuration></app-analysis-configuration>
    </div>
    <div class="teo-view full-width" [class.active-view]="appState.activeView === 'analysis-main'">
        <app-analysis-main></app-analysis-main>
    </div>
    <app-side-bar *ngIf="!smallLayout"></app-side-bar>
    <app-free-pins></app-free-pins>
</div>
