import { Injectable, EventEmitter } from '@angular/core';
import { HelpLayerModule } from './help-layer.module';

// @Injectable({
//   providedIn: HelpLayerModule
// })

export class HelpLayerItem {
  infoTextName: string;
  title: string;
  viewName: string;
  text?: string;
  position: { x: number, y: number };
}

@Injectable()
export class HelpLayerService {

  helpLayerOpen = new EventEmitter();

  helpLayerItems: HelpLayerItem[] = [];

  constructor() { }

  open(viewName: string) {
    this.helpLayerItems = [];
    this.helpLayerOpen.emit(viewName);
  }

  addItem(item: HelpLayerItem) {
    this.helpLayerItems.push(item);
  }
}
