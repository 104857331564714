<mat-card class="index-card" [class.detailed]="detailed"
  [class.has-scale]="wordList.scale_type == 1 && wordList.scale_benchmark !== undefined">
  <mat-icon class="top-left-info-icon" svgIcon="info" appInfoButton="word-list" [infoTitle]="wordList.title"
    [infoText]="infoText">
  </mat-icon>
  <mat-progress-bar class="card-loading-bar" [style.opacity]="loading ? 1 : 0" mode="indeterminate" color="accent">
  </mat-progress-bar>
  <div class="brand-fit-indicator" [class.active]="!brandFit"></div>
  <div class="result-panel-wrap" (click)="itemClick();">
    <div class="result-panel-content">
      <div class="">
        {{ wordList.title }}: <b>{{ getFindingNum() }} <span *ngIf="this.wordList.scale_type == 1">({{ wordPercentage |
            number : '1.0-0' }} %)</span></b>
      </div>
      <app-analysis-item-scale *ngIf="wordList.scale_type == 1 && wordList.scale_benchmark !== undefined"
        [indexValue]="wordPercentage" [indexRawValue]="scaleScore" indexType="default" leftMarking="0" centerMarking=""
        [rightMarking]="scaleMarking" [benchmark]="scaleBenchmark" [inactive]="scaleInactive">
      </app-analysis-item-scale>
    </div>
  </div>
  <div class="score-details" [style.height]="detailsHeight">
    <div [id]="wordList.name + '-details-inner'" class="score-details-inner">
      <div *ngIf="detailed && foundExp.length">

        <div *ngIf="wordList.relation_type == 3">
          <h4>{{ wordList.title_entries ? wordList.title_entries : 'Synonyme' }}</h4>
          <div>
            <label *ngFor="let violation of foundExp" class="score-todo">
              <div class="score-todo-text ct-entry">
                Statt <i>{{ violation.word }}</i><br />
                -> <b><i>{{ violation.replacements[0].full_expression }}</i></b>
              </div>
              <mat-checkbox [(ngModel)]="violation.replaced" (ngModelChange)="useReplacement(violation.id, 0)">
              </mat-checkbox>
            </label>
          </div>
          <div class="keyword-actions">
            <a href="#" (click)="correctAllViolations()">Alle Korrigieren</a>
          </div>
          <div class="keyword-infos">
            Achten Sie bitte darauf, dass Sie betreffende Artikel ggf. ersetzen müssen. Denken Sie bitte daran,
            den Text erneut zu analysieren, sobald Sie die gewünschten Synonyme ersetzt haben.
          </div>
        </div>

        <div *ngIf="wordList.relation_type == 1 || wordList.relation_type == 2">
          <div class="score-details-head">
            <div *ngIf="foundExp.length">
              <div *ngIf="selectedExpression === null">
                Bitte wählen Sie ein markiertes Wort aus.
              </div>
              <div *ngIf="selectedExpression !== null">
                Wort:<div class="current-word">
                  {{ foundExp[selectedExpression].word }}
                  <span *ngIf="foundExp[selectedExpression].text">
                    <br /><a href="#" class="lexi-link" (click)="openTextEntry()">Lexikalischen Eintrag
                      zeigen</a>
                  </span>
                </div>
              </div>
            </div>
            <div *ngIf="!foundExp.length">
              Nichts gefunden.
            </div>
          </div>
          <div *ngIf="selectedExpression !== null">
            <h4>{{ wordList.title_entries ? wordList.title_entries : 'Synonyme' }}</h4>
            <mat-radio-group [(ngModel)]="currentRadioSelection" (change)="onRadioSelect()">
              <label *ngFor="let expression of foundExp[selectedExpression].replacements; let i = index"
                class="score-todo" [class.active]="currentRadioSelection === i">
                <div class="score-todo-text">{{ expression.full_expression }}</div>
                <mat-radio-button [value]="i"></mat-radio-button>
              </label>
            </mat-radio-group>
            <div class="keyword-actions">
              <a href="#" (click)="resetReplacement()">Zurücksetzen</a>
            </div>
            <div class="keyword-infos">
              Achten Sie bitte darauf, dass Sie betreffende Artikel ggf. ersetzen müssen. Denken Sie bitte daran,
              den Text erneut zu analysieren, sobald Sie die gewünschten Synonyme ersetzt haben.
            </div>
            <!-- <div class="keyword-infos">
                        <div class="keyword-info">Anzahl Worte: {{ wordCount }}</div>
                        <div class="keyword-info">Anzahl Keywords: {{ keywordCount }}</div>
                        <div class="keyword-info">Gesamtanteil Keywords: {{ keywordPercentage | percent }}</div>
                    </div> -->
          </div>


        </div>

        <div *ngIf="wordList.relation_type == 0">
        </div>

      </div>
    </div>
  </div>
</mat-card>
