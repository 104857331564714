<div class="dialog-header">
  <mat-icon svgIcon="notification_important"></mat-icon>
</div>

<p [innerHtml]="data.message"></p>
<button *ngIf="actions.length == 0" mat-button class="dialog-ok-button" (click)="close()">ok</button>
<!-- <div *ngIf="actions.length > 0"> -->
<button #actionButton *ngFor="let action of actions; let index = index; let last = last;"
  id="dialog-action-{{action.id}}" mat-button class="dialog-action-button" [class.last]="last"
  (click)="actionClick(index)">
  {{ action.text }}
</button>
<!-- </div> -->
<div *ngIf="notice" class="notice-container">
  <div class="notice-toggle-container small-text">
    <a href="#" class="notice-toggle" [class.active]="noticeHeight != '0px'" (click)="toggleNotice()">
      {{ notice.title }}</a>
  </div>
  <div class="notice-text" [style.height]="noticeHeight">
    <div #noticeTextInner class="notice-text-inner" [innerHtml]="notice.text">
    </div>
  </div>
</div>
