const nodeEnv = process.env.NODE_ENV;

const DEV_MODE = nodeEnv === 'development';

function noop(..._args: unknown[]) {}

const SILENT_LOGGER = {
  log: noop,
  debug: noop,
  info: noop,
  warn: noop,
  error: noop
};

type LoggerInstance = typeof SILENT_LOGGER;

const methods = Object.keys(
  SILENT_LOGGER
) as unknown as (keyof LoggerInstance)[];

export function createLogger(key?: string): LoggerInstance {
  if (DEV_MODE && typeof window !== 'undefined' && window.console) {
    return methods.reduce((logger, method) => {
      logger[method] = console[method].bind(console, `[${key}]`);
      return logger;
    }, {} as LoggerInstance);
  }

  return SILENT_LOGGER;
}
