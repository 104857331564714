import { Component, OnInit } from '@angular/core';
import { AppStateService } from 'src/app/services/app-state.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-changelog',
  templateUrl: './changelog.component.html',
  styleUrls: ['./changelog.component.scss']
})
export class ChangelogComponent implements OnInit {

  changelog = [];

  changelogLink = environment.changelogUrl;

  constructor(
    public appState: AppStateService
  ) {
    appState.login.subscribe((user) => {
        this.changelog = user.changelog ? user.changelog : [];
    });
   }

  ngOnInit(): void {
  }



  continue(){
    this.appState.setView('analysis-configuration');
    return false;
  }
}
