
  <div class="welcome-text">
    Um Ihr Passwort zurückzusetzen, benötigen wir Ihre E-Mail-Adresse. <br />Bitte tragen Sie diese in das Feld ein und klicken Sie auf "Zurücksetzen".
  </div>
<div class="small-container">
  <!-- <div class="teo-logo"></div> -->
  <mat-card class="form-card">
    <!-- <div class="teo-logo"></div> -->
    <mat-form-field *ngIf="requestState != 2">
      <input matInput placeholder="Email" [formControl]="emailFormControl">
      <mat-error *ngIf="emailFormControl.hasError('email') && !emailFormControl.hasError('required')">
        Bitte geben Sie eine gültige Email Adresse ein.
      </mat-error>
      <mat-error *ngIf="emailFormControl.hasError('required')">
        Bitte geben Sie eine Email Adresse ein.
      </mat-error>
      <mat-error *ngIf="emailFormControl.hasError('serverError')">
        Diese E-Mail-Adresse ist nicht bekannt.
      </mat-error>
    </mat-form-field>
    <div *ngIf="requestState == 2">
      Wir haben Ihnen eine E-Mail mit einem Link geschickt.<br />
      Mit diesem Link können Sie sich ein neues Passwort setzen. Schauen Sie am besten direkt einmal in Ihren Posteingang. Sollten Sie dort keine E-Mail von uns finden, schauen Sie bitte auch in Ihren Spam-Ordner.
    </div>

    <a href="#" (click)="appState.setView('login')">Zurück zum Log In</a>
  </mat-card>
  <button *ngIf="requestState != 2" class="big-button" (click)="sendResetRequest()" [class.inactive]="requestState == 1">
    <div class="big-button-ripple-layer" matRipple></div>
    Zurück-<br />setzen
    <mat-progress-spinner class="big-button-spinner" [class.active]="requestState == 1" color="primary"
      mode="indeterminate">
    </mat-progress-spinner>
  </button>
</div>
