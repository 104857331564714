<div style="clear:both"></div>
<div class="large-container">
  <div class="client-logo">
  </div>
  <div class="welcome-text">
    Herzlich willkommen bei TEO V2.<br />
    Bitte loggen Sie sich ein und entdecken Sie Next Level Text-Performance.
  </div>
  <div class="small-container">
    <!-- <div class="teo-logo"></div> -->
    <mat-card class="form-card">
      <div *ngIf="showDevNotice" class="dev-notice">DEV</div>
      <div class="teo-logo"></div>
      <mat-error class="login-error" *ngIf="loginFailed">
        Bitte prüfen Sie Ihre Logindaten.
      </mat-error>
      <mat-form-field>
        <input matInput placeholder="Email" [formControl]="emailFormControl">
        <mat-error *ngIf="emailFormControl.hasError('email') && !emailFormControl.hasError('required')">
          Bitte geben Sie eine gültige Email Adresse ein.
        </mat-error>
        <mat-error *ngIf="emailFormControl.hasError('required')">
          Bitte geben Sie eine Email Adresse ein.
        </mat-error>
      </mat-form-field>
      <mat-form-field>
        <input matInput type="password" placeholder="Passwort" [formControl]="passwordFormControl">
        <mat-error *ngIf="passwordFormControl.hasError('required')">
          Bitte geben Sie Ihr Passwort ein.
        </mat-error>
      </mat-form-field>
      <a *ngIf="showPasswordLink" href="#" (click)="appState.setView('reset-password')">Passwort vergessen?</a>
    </mat-card>
    <button class="big-button" (click)="logIn()">
      <div class="big-button-ripple-layer" matRipple></div>
      Log In
    </button>
  </div>
  <div id="tcl-branding-bottom">
    <a href="https://tcl.digital" title="tcl.digital" target="_blank">
      <img src="assets/images/tcl_logo.svg" alt="TCL Logo" />
    </a>
    <div>Alle Rechte tcl.digital</div>
    <div *ngIf="privacyLink !== null">Datenschutzbestimmung: <a [href]="privacyLink" title="Datenschutzbestimmung" target="_blank">{{ privacyLink }}</a></div>
  </div>
</div>
