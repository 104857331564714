<div class="help-layer-main" [class.open]="layerOpen">
    <div class="help-layer-background" (click)="close()"></div>
    <div *ngFor="let item of helpLayer.helpLayerItems" class="help-layer-item-icon"
        [class.active]="currentItem !== null && currentItem.infoTextName === item.infoTextName"
        [ngStyle]="{'top.px': item.position.y, 'left.px': item.position.x}" (click)="showItem(item)">

        <mat-icon class="" svgIcon="info"></mat-icon>
    </div>
    <mat-card *ngIf="currentItem !== null" class="help-layer-current-item">
        <h5>{{ currentItem.title }}</h5>
        <div *ngIf="currentItem.text">
            <p [innerHtml]="currentItem.text">
            </p>
        </div>
        <a href="#" class="card-close-link" (click)="hideItem()">Schließen</a>
    </mat-card>
    <a href="#" class="layer-close-link" (click)="close()">Schließen</a>
</div>