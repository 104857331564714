import { Component, OnInit, Input } from '@angular/core';
import { AnalysisItemContent } from '../analysis-item-content-interface';
import { CurrentTextService } from 'src/app/services/current-text.service';
import { AppStateService } from 'src/app/services/app-state.service';
import { AccordionInfoComponent } from 'src/app/pinboard/info-item/accordion-info/accordion-info.component';
import { NoteInfoComponent } from 'src/app/pinboard/info-item/note-info/note-info.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-human-interest',
  templateUrl: './human-interest.component.html',
  styleUrls: ['../analysis-item-shared.scss', './human-interest.component.scss']
})
export class HumanInterestComponent implements OnInit, AnalysisItemContent {
  data: any;
  itemName: string;
  @Input() inactive: boolean;

  detailed = false;

  gotContent = false;

  // score: number;
  // percent: string;
  // title: string;

  todoDetailsHeight = 0;
  todoDetailsOpen = false;
  selectedTodo = null;

  scoreChange: number;

  todoDetailsInnerId = 'human-interest-todo-details-inner';

  // howtos: string[];

  notesBoxOpen = {};

  markingsJustChanged = false;

  smallLayout = environment.editor == 'office';

  constructor(
    private currentText: CurrentTextService,
    private appState: AppStateService
  ) {
    this.currentText.gotResults.subscribe(() => {
      if (
        this.currentText.textStrategy
        && (this.currentText.textStrategy.main_index_name == 'TPI1')
      ) {
        this.inactive = true;
      } else {
        this.inactive = false;
      }

      const data = this.currentText.lastResult.additionalData.humanInterest;
      if (typeof data !== 'undefined') {
        this.gotContent = true;
        this.scoreChange = Math.round(data.percent - this.data.percent);
        this.data = data;

        for (const property of this.data.text_properties) {
          property.howto = this.splitHowto(property.howto);
          property.markers = property.markers.split(',');

          // @todo: use better iterator
          for (let i = 0; i < property.markers.length; i++) {
            property.markers[i] = property.markers[i].trim();
          }
        }
      }
    });
    this.currentText.markings.subscribe((reset: boolean) => {
      if (reset) {
        if (this.markingsJustChanged) {
          this.markingsJustChanged = false;
        } else {
          this.selectedTodo = null;
          window.setTimeout(() => {
            this.setTodoDetailsHeight();
          }, 50);
        }
      }
    });
  }

  absScoreChange() {
    return Math.abs(this.scoreChange);
  }

  private setTodoDetailsHeight() {
    if (this.detailed) {
      const todoElem = document.getElementById(this.todoDetailsInnerId + this.selectedTodo);
      const todosElem = document.getElementById(this.todoDetailsInnerId);
      let height = 0;
      if (this.todoDetailsOpen && this.selectedTodo !== null && todoElem.offsetHeight) {
        height += todoElem.offsetHeight;
      }
      if (todosElem.offsetHeight) {
        height += todosElem.offsetHeight;
      }
      this.todoDetailsHeight = height;
    } else {
      this.todoDetailsHeight = 0;
    }
  }

  private splitHowto(howto: string) {
    // convert linebreaks
    howto = howto.replace(/<div.*?>/gmi, '\n');
    howto = howto.replace(/<br.*?>/gmi, '\n');
    // only allow i and b tags
    howto = howto.replace(/<b.*?>/gmi, '§§b§§');
    howto = howto.replace(/<i.*?>/gmi, '§§i§§');
    howto = howto.replace(/<\/b.*?>/gmi, '§§/b§§');
    howto = howto.replace(/<\/i.*?>/gmi, '§§/i§§');
    howto = howto.replace(/<.*?>/gmi, '');
    howto = howto.replace(/§§b§§/gmi, '<b>');
    howto = howto.replace(/§§i§§/gmi, '<i>');
    howto = howto.replace(/§§\/b§§/gmi, '</b>');
    howto = howto.replace(/§§\/i§§/gmi, '</i>');

    const howtoArr = howto.split('\n');
    for (let i = 0; i < howtoArr.length; i++) {
      howtoArr[i] = howtoArr[i].trim();
      if (howtoArr[i] === '') {
        howtoArr.splice(i, 1);
        i--;
      }
    }
    return howtoArr;
  }

  openNotesBox(todoMarker: string, title: string, howtos: object) {
    this.notesBoxOpen[todoMarker] = this.appState.pinInfoItem(NoteInfoComponent, todoMarker + '-notes', title, { howtos });
  }
  closeNotesBox(todoMarker: string) {
    this.appState.unpinInfoItem(this.notesBoxOpen[todoMarker]);
    this.notesBoxOpen[todoMarker] = null;
  }

  private notesBoxPinned(todoMarker: string) {
    return !(typeof this.notesBoxOpen[todoMarker] === 'undefined' || this.notesBoxOpen[todoMarker] === null);
  }

  selectTodo(todoName: string, markers: string[]) {
    if (this.selectedTodo !== todoName) {
      this.selectedTodo = todoName;
      this.markingsJustChanged = true;
      this.currentText.setMarkings(markers);
    } else {
      this.selectedTodo = null;
      this.currentText.removeMarkings();
    }
    window.setTimeout(() => {
      this.setTodoDetailsHeight();
    }, 50);
  }

  toggleTodoDetails() {
    this.todoDetailsOpen = !this.todoDetailsOpen;
    this.setTodoDetailsHeight();
  }

  open() {
    if (!this.inactive) {
      this.detailed = true;
      this.setTodoDetailsHeight();
    }
  }

  close() {
    this.detailed = false;
    this.selectedTodo = null;
    this.currentText.removeMarkings();
    this.setTodoDetailsHeight();
  }

  itemClick() {
    if (this.detailed) {
      this.close();
    } else {
      this.open();
    }
  }

  ngOnInit() {
  }

}
