import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { CurrentTextService } from 'src/app/services/current-text.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-tli-display',
  templateUrl: './tli-display.component.html',
  styleUrls: ['./tli-display.component.scss']
})

export class TliDisplayComponent implements OnInit {
  @ViewChild('benchArc') benchArc: ElementRef;

  private iBenchmark = 0;
  segmentArcPath = '';
  tachoSegments: { styles: { transform: string }, class: string }[];

  private greenColor = 'svg-color-score-green';
  private redColor = 'svg-color-score-red';
  private yellowColor = 'svg-color-score-yellow';
  private grayColor = 'svg-color-score-gray';

  currentColor = this.grayColor;

  segmentNum = 100;
  segmentGap = 1; // deg

  scorePercent = 0;
  score = 0;
  scoreMax = 20;

  tachoDeg = 320;

  environment = environment;

  percentDisplay = false;

  benchArcSegments = [];

  animationTime = 200; // ms

  benchArcColorTransitionBefore = 0.1;
  benchArcColorTransitionAfter = 0.05;

  isModified = false;

  constructor(
    public currentText: CurrentTextService
  ) {
    if (environment.mainIndexName == "TLI") {
      this.segmentNum = 20;
      this.scoreMax = 20;
    } else {
      this.segmentNum = 80;
      this.scoreMax = 15;
      this.percentDisplay = true;
    }
    this.segmentArcPath = this.describeArc(50, 50, 45, 180, this.tachoDeg / this.segmentNum + 180 - this.segmentGap);
    this.createTacho();
    this.currentText.gotResults.subscribe(() => {
      this.benchmark = this.currentText.getMainIndexBenchmark();
      this.isModified = this.currentText.lastResult.hasWhitelistedWords;
      this.setTacho();
    });
  }

  getTachoSegmentClass(segment: { styles: { transform: string }, class: string }) {
    return 'tli-tacho-segment ' + segment.class;
  }

  createTacho() {
    this.tachoSegments = [];
    for (let index = 0; index < this.segmentNum; index++) {
      this.tachoSegments.push({
        styles: {
          transform: 'rotate(' + ((this.tachoDeg / this.segmentNum) * index) + 'deg)',
        },
        class: this.grayColor
      });
    }
    this.setTacho();
  }

  setTacho() {
    this.resetTachoColor();
    this.scorePercent = Math.floor(this.currentText.getMainIndexPercentage());
    this.score = this.currentText.getMainIndex();
    for (let index = 0; index < this.segmentNum; index++) {
      this.tachoSegments[index].class = (index + 1) <= (this.score / this.scoreMax * this.segmentNum) ? this.currentColor : this.grayColor;
    }
  }

  // this way of animating it, does not work well in angular.
  // animateTacho() {
  //   this.resetTachoColor();
  //   const newScore = this.currentText.getMainIndexPercentage();
  //   const stepNum = Math.ceil(Math.abs(newScore - this.score) / this.scoreMax * this.segmentNum);

  //   let progress = 0;

  //   for (let index = 0; index < this.segmentNum; index++) {
  //     const oldClass = (index + 1) <= (this.currentText.getMainIndex() / this.scoreMax * this.segmentNum) ? this.currentColor : this.grayColor;
  //     const newClass = (index + 1) <= (newScore / this.scoreMax * this.segmentNum) ? this.currentColor : this.grayColor;
  //     if(newClass != oldClass){
  //       setTimeout(() => {
  //         this.tachoSegments[index].class = newClass;
  //       }, this.animationTime * (progress / stepNum));
  //       progress ++;
  //     }else{
  //       this.tachoSegments[index].class = newClass;
  //     }
  //   }

  //   setTimeout(() => {
  //     this.scorePercent = this.currentText.getMainIndexPercentage();
  //     this.score = this.currentText.getMainIndex();
  //   }, this.animationTime);
  // }


  resetTachoColor() {
    if (!this.currentText.analysisCounter) {
      this.currentColor = this.grayColor;
    } else {
      const index = this.currentText.getMainIndex();
      if (index < this.benchmark / 2) {
        this.currentColor = this.redColor;
      } else if (index < this.benchmark) {
        this.currentColor = this.yellowColor;
      } else {
        this.currentColor = this.greenColor;
      }
    }
  }

  private polarToCartesian(centerX: number, centerY: number, radius: number, angleInDegrees: number) {
    const angleInRadians = (angleInDegrees - 90) * Math.PI / 180.0;

    return {
      x: centerX + (radius * Math.cos(angleInRadians)),
      y: centerY + (radius * Math.sin(angleInRadians))
    };
  }

  private describeArc(x: number, y: number, radius: number, startAngle: number, endAngle: number) {

    const start = this.polarToCartesian(x, y, radius, endAngle);
    const end = this.polarToCartesian(x, y, radius, startAngle);

    const largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1';

    return [
      'M', start.x, start.y,
      'A', radius, radius, 0, largeArcFlag, 0, end.x, end.y
    ].join(' ');
  }

  ngOnInit() {
  }

  get benchmark(): number {
    return this.iBenchmark;
  }

  private updateBenchArc() {
    this.benchArcSegments = [];
    const benchFrac = this.iBenchmark / this.scoreMax;
    const redStartDeg = 180;
    const yellowStartDeg = benchFrac * this.tachoDeg * 0.5 + 180;
    const greenStartDeg = benchFrac * this.tachoDeg + 180;
    const greenEndDeg = this.tachoDeg + 180;
    this.benchArcSegments.push({
      styles: { stroke: 'rgb(151, 192, 11)' },
      path: this.describeArc(50, 50, 55, greenStartDeg + (this.benchArcColorTransitionAfter * this.tachoDeg), greenEndDeg)
    });
    this.benchArcSegments.push({
      styles: { stroke: 'rgb(255, 192, 0)' },
      path: this.describeArc(50, 50, 55, yellowStartDeg + (this.benchArcColorTransitionAfter * this.tachoDeg), greenStartDeg - (this.benchArcColorTransitionBefore * this.tachoDeg))
    });
    this.benchArcSegments.push({
      styles: { stroke: 'rgb(255, 78, 79)' },
      path: this.describeArc(50, 50, 55, redStartDeg, yellowStartDeg - (this.benchArcColorTransitionBefore * this.tachoDeg))
    });

    // yellow to green transition
    this.createBenchGradientPathSegments(greenStartDeg - (this.benchArcColorTransitionBefore * this.tachoDeg), greenStartDeg + (this.benchArcColorTransitionAfter * this.tachoDeg), 10, { r: 255, g: 192, b: 0 }, { r: 151, g: 192, b: 11 });

    //red to yellow
    this.createBenchGradientPathSegments(yellowStartDeg - (this.benchArcColorTransitionBefore * this.tachoDeg), yellowStartDeg + (this.benchArcColorTransitionAfter * this.tachoDeg), 10, { r: 255, g: 78, b: 79 }, { r: 255, g: 192, b: 0 });
  }

  private createBenchGradientPathSegments(startPos, endPos, segNum, startColor, endColor) {
    const transDeg = endPos - startPos;
    const aliasingMargin = 0.2; // In deg. Most browsers render a small line between the segments, due to aliasing. This margin prevents that
    for (let i = 0; i < segNum; i++) {
      const transState = i / segNum;
      const nextTransState = (i + 1) / segNum;
      this.benchArcSegments.push({
        styles: { stroke: this.createTransitionColorString(startColor, endColor, (transState + nextTransState) / 2) },
        path: this.describeArc(50, 50, 55, startPos + transState * transDeg - aliasingMargin, startPos + nextTransState * transDeg + aliasingMargin)
      });
    }
  }

  private createTransitionColorString(startColor, endColor, transitionState) {
    return 'rgb('
      + (startColor.r * (1 - transitionState) + endColor.r * transitionState) + ', '
      + (startColor.g * (1 - transitionState) + endColor.g * transitionState) + ', '
      + (startColor.b * (1 - transitionState) + endColor.b * transitionState) + ')';
  }

  set benchmark(bench: number) {
    this.iBenchmark = bench;
    this.updateBenchArc();
    this.setTacho();
  }
}
