<mat-card class="index-card" [class.detailed]="detailed">
  <mat-icon class="top-left-info-icon" svgIcon="info" appInfoButton="sentiment" infoTitle="Sentiment-Scanner">
  </mat-icon>
  <mat-progress-bar class="card-loading-bar" [style.opacity]="loading ? 1 : 0" mode="indeterminate" color="accent">
  </mat-progress-bar>
  <div class="result-panel-wrap" (click)="itemClick();">
    <div class="result-panel-content">
      <div>
        <b></b>
      </div>
      <div class="score-text">
        Sentiment-Scanner: <b>{{ value }}</b>
      </div>
      <app-analysis-item-scale [indexValue]="percentScore" [indexRawValue]="scaleScore" indexType="default"
        leftMarking="" centerMarking="" rightMarking="" benchmark="8" [inactive]="scaleInactive" hideBenchmark="true"
        redWidth="0.9">
      </app-analysis-item-scale>
    </div>
  </div>
  <div class="score-details" [style.height]="detailsHeight">
    <!-- <div id="sentiment-details-inner" class="score-details-inner"> -->
      <!-- <div *ngIf="detailed" class="score-details-head">
          Profil: <div class="current-word">
            &nbsp;
          </div>
        </div> -->
    <!-- </div> -->
  </div>
</mat-card>
