import { Type } from '@angular/core';
import { AllHtmlEntities } from 'html-entities';
import { AnalysisItemContent } from './view-components/analysis-main/analysis-item/analysis-item-content-interface';

// export enum TextType {
//   Dialog = 0,
//   Sales,
//   Information
// }
// export enum StrategicDirection {
//   Information = 0,
//   Promotion,
//   Sales,
//   HardSelling,
// }
// export enum TextAudience {
//   B2C = 0,
//   B2B,
//   Individual,
//   Easy,
// }
// export enum Branch {
//   Lifestyle = 0,
//   Finanzdienstleister,
//   Mustermarke
// }
export class TextAttribute {
  title: string;
  marker: string;
  number: number;
  percent: number;
  measurement: string;
  tipp: string;
  howto: string[];
  constructor(data: any) {
    this.title = data.title;
    this.marker = data.markers;
    this.number = Math.ceil(data.number);
    this.percent = Math.ceil(data.percentage);
    this.measurement = data.todo;
    this.tipp = data.tip;

    let howto = data.howto;
    if (typeof howto !== 'string') {
      howto = '';
    }
    // convert linebreaks
    howto = howto.replace(/<div.*?>/gmi, '\n');
    howto = howto.replace(/<br.*?>/gmi, '\n');
    // only allow i and b tags
    howto = howto.replace(/<b.*?>/gmi, '§§b§§');
    howto = howto.replace(/<i.*?>/gmi, '§§i§§');
    howto = howto.replace(/<\/b.*?>/gmi, '§§/b§§');
    howto = howto.replace(/<\/i.*?>/gmi, '§§/i§§');
    howto = howto.replace(/<.*?>/gmi, '');
    howto = howto.replace(/§§b§§/gmi, '<b>');
    howto = howto.replace(/§§i§§/gmi, '<i>');
    howto = howto.replace(/§§\/b§§/gmi, '</b>');
    howto = howto.replace(/§§\/i§§/gmi, '</i>');

    this.howto = howto.split('\n');
    for (let i = 0; i < this.howto.length; i++) {
      this.howto[i] = this.howto[i].trim();
      if (this.howto[i] === '') {
        this.howto.splice(i, 1);
        i--;
      }
    }
  }
}
export class AnalysisIndex {
  name: string;
  title: string;
  score: number;
  benchmark: number;
  rawBenchmark?: number;
  percent?: number;
  scorePercent?: number;
  rawScore?: number;
  scaleStyle?: string;
  todo: TextAttribute[];
  constructor(name: string, data: any) {
    this.name = name;
    this.title = data.title;
    this.score = data.score;
    this.benchmark = data.benchmark;
    if (data.raw_benchmark != 'undefined') {
      this.rawBenchmark = data.raw_benchmark;
    }
    if (typeof data.score_percent != 'undefined') {
      this.scorePercent = data.score_percent;
    }
    if (data.raw_result != 'undefined') {
      this.rawScore = data.raw_result;
    }
    this.percent = data.percent;
    this.todo = [];
    for (const todo of data.text_properties) {
      this.todo.push(new TextAttribute(todo));
    }
  }
}

export class AnalysisResult {
  tli: number;
  tpi: any;
  tliBenchmark: number;
  indices: AnalysisIndex[];
  wrapped: string;
  wrappers: EditorMarking[];
  additionalData?: any;
  textNumbers: any[];
  wordCloud: any[];
  dynIndexDebug?: any[];
  statisticSession?: string;
  scanners?: any[];
  reportId?: string;
  hasWhitelistedWords?: boolean;
  constructor(indices: string[], data: any) {
    this.additionalData = {};
    this.tli = data.tli;
    this.tpi = data.tpi;
    this.tliBenchmark = data.tli_benchmark;
    this.wrapped = data.wrapped;
    this.wrappers = data.wrappers;
    this.indices = [];
    this.textNumbers = data.additional_data.analysis_numbers;
    this.wordCloud = data.additional_data.word_cloud;
    this.dynIndexDebug = data.dynIndexDebug;
    this.statisticSession = data.statistic_session;
    this.additionalData.anglicisms = data.additional_data.anglicisms;
    this.additionalData.corporateTonality = data.additional_data.corporate_tonality;
    this.additionalData.thesaurus = data.additional_data.thesaurus;
    this.additionalData.humanInterest = data.indices.human_interest;
    this.additionalData.causalConn = data.additional_data.causal_conn;
    this.additionalData.dialog = data.additional_data.dialog;
    this.scanners = data.scanners ? data.scanners : null;
    this.hasWhitelistedWords = data.has_whitelisted_words ? data.has_whitelisted_words : false;
    if (data.report_id) {
      // const rId = new Date(data.report_id);
      // this.reportId = rId.getUTCFullYear() + "/" +
      // ("0" + (rId.getUTCMonth()+1)).slice(-2) + "/" +
      // ("0" + rId.getUTCDate()).slice(-2) + " " +
      // ("0" + rId.getUTCHours()).slice(-2) + ":" +
      // ("0" + rId.getUTCMinutes()).slice(-2) + ":" +
      // ("0" + rId.getUTCSeconds()).slice(-2);
      this.reportId = data.report_id;
    }
    this.indices.push(new AnalysisIndex('causal_conn', data.additional_data['causal_conn']));
    for (const indexName of indices) {
      this.indices.push(new AnalysisIndex(indexName, data.indices[indexName]));
    }
  }
}

export class EditorMarkings{
  markings: EditorMarking[];
  wrapperClass: string;
}
export class EditorMarking {
  marker: string;
  offset: number;
  length: number;
  offset_block?: {
    block: number;
    pos: number;
  };
  end_offset_block?: {
    block: number;
    pos: number;
  };
  relation?: any;
}

export class ScoreItem {
  name: string;
  hidden: boolean;
  itemType: Type<AnalysisItemContent>;
  inactive: boolean;
  data: any;
  scannerName?: string;
  resultProperty?: string;
}
export class ResultValue {
  name: string;
  score: number;
  percent: number;
  ratingText: string;
  infoText: string;
  measureText: string;
  markings: string[];
}
export class AnalysisRequest {
  api_token?: string;
  statistic_session?: string;
  text: string | RequestTextBlock[];
  audience: number;
  strategy: number;
  medium: number;
  is_informal?: boolean;
  text_alias?: string;
  evaluation_profile: number;
  editable_text?: string;
  client?: string;
  scanners?: string[];
  debug?: number;

  prepareHTMLText() {
    if (typeof this.text == 'string') {
      const textBlocks: RequestTextBlock[] = [];
      let currString = '';
      let inTag = false;
      let possibEntity = '';
      for (let i = 0; i < this.text.length; i++) {
        if (!inTag && this.text[i] == '<') {
          if (possibEntity.length > 0) {
            currString += possibEntity;
            possibEntity = '';
          }
          if (currString.length) {
            textBlocks.push({
              string: currString,
              type: 'text',
              length: currString.length
            });
          }
          currString = '<';
          inTag = true;
        } else if (
          inTag
          && this.text[i] == '>'
          // && !(i < this.text.length - 1 // check if next char belongs to tag
          //   && this.text[i + 1] == '<')
        ) {
          currString += this.text[i];
          let type = 'ignore';
          if (
            currString == '<br>'
            || currString == '<br />'
            || currString == '<div>'
            || currString == '</div>'
          ) {
            type = 'linebreak';
          } else if (currString == '</p>') {
            type = 'paragraph';
          }
          textBlocks.push({
            string: currString,
            type,
            length: currString.length
          });
          inTag = false;
          currString = '';
        } else if (!inTag && this.text[i] == '&') {
          possibEntity = '&';
        } else if (!inTag && possibEntity.length > 0) {
          if (this.text[i] != ';') {
            possibEntity += this.text[i];
          } else {
            possibEntity += ';';
            const dec = AllHtmlEntities.decode(possibEntity);
            if (dec != possibEntity) {
              if (currString.length) {
                textBlocks.push({
                  string: currString,
                  type: 'text',
                  length: currString.length
                });
              }
              currString = '';
              if (dec.length) {
                textBlocks.push({
                  string: possibEntity,
                  type: 'text',
                  interpret_as: dec,
                  length: possibEntity.length
                });
              }
              possibEntity = '';
            } else {
              currString += possibEntity;
              possibEntity = '';
            }
          }
        } else {
          currString += this.text[i];
        }
      }

      if (possibEntity.length > 0 && !inTag) {
        currString += possibEntity;
      }
      if (currString.length && !inTag) {
        textBlocks.push({
          string: currString,
          type: 'text',
          length: currString.length
        });
      }

      this.text = textBlocks;
      return textBlocks;
    }
    return [];
  }
}
export class RequestTextBlock {
  string: string;
  type: string;
  length?: number;
  interpret_as?: string;
}
// export class AnalysisRequest {
//   statisticSession: string;
//   text: string;
//   branch: Branch;
//   audience: number;
//   strategy: number;
//   medium: number;
// }
export class User {
  name: string;
  email: string;
  role: UserRole;
  token?: string;
  changelog?: Array<ChangelogEntry>;
}
export class UserRole {
  name: string;
  level: number;
  permissions: string[];
}
export class BenchmarkOptions {
  'target-audience': any[];
  'strat-direction': any[];
  'text-medium': any[];
}

export class ChangelogEntry {
  title: string;
  description: string;
  link: string;
  activation_time: string;
}

export class HistoryEntry {
  audience: number;
  strategy: number;
  medium: number;
  is_informal?: boolean;
  text: string;
  text_alias?: string;
  // main_index: number;
  // main_index_bench: number;
  main_index_percentage: number;
  result: AnalysisResult;
  time: Date;
  minutes_ago?: number;
  color?: string;
}
