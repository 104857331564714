<mat-card *ngIf="index" class="index-card" [class.detailed]="detailed" [class.peak]="indexType === 'peak'"
  [class.number]="indexType === 'number'" [class.small-layout]="smallLayout">
  <mat-icon class="top-left-info-icon" svgIcon="info" [appInfoButton]="itemName" [infoTitle]="indexTitle"></mat-icon>
  <div class="result-panel-wrap" (click)="itemClick();">
    <div class="result-panel-content" *ngIf="!comprendTliException">
      <div class="score-text">
        {{index.title}}<span *ngIf="!inactive">:
          <b *ngIf="indexType !== 'peak'">
            {{index.percent  | number:'1.0-0' }}%
          </b>
          <b *ngIf="indexType === 'peak'">
            {{index.scorePercent  | number:'1.0-0' }}%
          </b>
          <span *ngIf="scoreChange !== null && indexType === 'default' && !inactive" class="score-change"
            [class.hidden]="currentText.analysisCounter < 2" [class.positive]="scoreChange > 0"
            [class.negative]="scoreChange < 0">
            {{ absScoreChange() }}%
          </span>
        </span>
      </div>
      <app-analysis-item-scale *ngIf="indexType !== 'peak'" [indexValue]="index.percent" [indexRawValue]="index.score"
        [indexType]="indexType" [leftMarking]="leftMarking" [centerMarking]="centerMarking"
        [rightMarking]="rightMarking" [benchmark]="index.benchmark" [inactive]="inactive">
      </app-analysis-item-scale>
      <app-analysis-item-scale *ngIf="indexType === 'peak'" [indexValue]="index.percent"
        [indexRawValue]="index.rawScore" [indexType]="indexType" [leftMarking]="leftMarking"
        [centerMarking]="centerMarking" [rightMarking]="rightMarking" [benchmark]="index.benchmark"
        [peakBenchmark]="index.rawBenchmark" [inactive]="inactive">
      </app-analysis-item-scale>
    </div>

    <div class="result-panel-content tli-exception" *ngIf="comprendTliException">
      <div class="score-text">
        {{index.title}} (TLI Details)
      </div>
    </div>
  </div>
  <div *ngIf="indexType !== 'number'" class="score-details" [style.height]="todoDetailsHeight + 'px'">
    <div [id]="todoDetailsInnerId" class="score-details-inner">
      <h4>Details</h4>
      <div *ngIf="!index.todo.length">
        <p class="info-text">Keine Handlungsempfehlungen vorhanden.</p>
      </div>
      <div>
        <div *ngFor="let todo of index.todo;" class="score-todo" [class.active]="selectedTodo === todo.marker"
          (click)="selectTodo(todo.marker)">
          <div class="score-todo-title">{{ todo.title }}</div>
          <div class="score-todo-numbers">{{ todo.number }} ({{ todo.percent }}%)</div>
        </div>
      </div>
      <h4 *ngIf="selectedTodo !== null">Handlungsempfehlungen<a href="#" (click)="toggleTodoDetails();">
          <span *ngIf="!todoDetailsOpen">ausklappen</span>
          <span *ngIf="todoDetailsOpen">einklappen</span>
        </a></h4>
    </div>
    <div class="todo-details" [class.open]="todoDetailsOpen">
      <div *ngFor="let todo of index.todo;" class="score-todo-detail" [class.active]="selectedTodo === todo.marker">
        <div [id]="todoDetailsInnerId + todo.marker" class="score-todo-detail-inner">
          <h6>{{ todo.title }}</h6>
          <h3>Was ist zu tun?</h3>
          <p class="info-text">
            {{ todo.measurement }}
          </p>
          <h3>Tipp:<mat-icon class="light" svgIcon="light"></mat-icon>
          </h3>
          <p class="info-text">
            {{ todo.tipp }}
          </p>
          <h3>So gehts!<mat-icon svgIcon="pen"></mat-icon>
          </h3>
          <div class="yellow-card">
            <ul>
              <li *ngFor="let howto of todo.howto;" class="" [innerHTML]="howto"></li>
            </ul>
            <a *ngIf="!notesBoxPinned(todo.marker) && !smallLayout" href="#" class="pinboard-toggle"
              (click)="openNotesBox(todo.marker, todo.title, todo.howto)">an Pinnwand heften</a>
            <a *ngIf="notesBoxPinned(todo.marker) && !smallLayout" href="#" class="pinboard-toggle"
              (click)="closeNotesBox(todo.marker)">von Pinnwand entfernen</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</mat-card>
