import { Component, OnInit, Input } from '@angular/core';
import { InfoItemContent } from '../info-item-content-interface';

@Component({
  selector: 'app-word-list-info',
  templateUrl: './word-list-info.component.html',
  styleUrls: ['./word-list-info.component.scss']
})
export class WordListInfoComponent implements OnInit, InfoItemContent {
  @Input() infoName: string;
  @Input() infoTitle: string;
  @Input() data: any;
  @Input() onBoard = true;
  constructor() { }

  ngOnInit() {
  }

}
