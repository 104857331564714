<mat-card class="index-card" [class.detailed]="detailed">
    <!-- <mat-icon class="top-left-info-icon" svgIcon="info" appInfoButton="text-stats"
        infoTitle="Quantitative Text-Analyse"></mat-icon> -->
    <div class="result-panel-wrap" (click)="itemClick();">
        <div class="result-panel-content">
            <div>
                <b>TPI Debug</b>
            </div>
        </div>
    </div>
    <div class="score-details" [style.height]="detailsHeight">
        <div id="dyn-index-debug-details-inner" class="score-details-inner">
            <div *ngIf="detailed" class="score-details-head">
                <div class="number-list" *ngFor="let index of indices">
                    <div>
                        <span class="number-title">{{ index.name }}</span>
                        <span class="number-value">{{ index.value | number : '1.2-2' }}
                            <span *ngIf="index.additional">({{ index.additional | number : '1.2-2' }})</span>
                        </span>
                    </div>
                    <div *ngIf="valueNotUndefined(index.benchmark)">
                        <span class="number-title">{{ index.name }} Benchmark</span>
                        <span class="number-value">{{ index.benchmark | number : '1.2-2' }}</span>
                    </div>
                    <div *ngIf="valueNotUndefined(index.percentage)">
                        <span class="number-title">{{ index.name }} Prozent</span>
                        <span class="number-value">{{ index.percentage | number : '1.0-0' }} %</span>
                    </div>
                    <div *ngIf="valueNotUndefined(index.autoBench)">
                        <span class="number-title">{{ index.name }} Auto-Benchmark</span>
                        <span class="number-value">{{ index.autoBench | number : '1.2-2' }}</span>
                    </div>
                    <div *ngIf="valueNotUndefined(index.autoPercentage)">
                        <span class="number-title">{{ index.name }} Auto-Prozent</span>
                        <span class="number-value">{{ index.autoPercentage | number : '1.0-0' }} %</span>
                    </div>
                    <div class="sub-number-list" *ngIf="index.indexParts">
                        <div *ngFor="let part of index.indexParts">
                            <span class="number-title">{{ part.value_name }} Gewichtung</span>
                            <span class="number-value">{{ part.weight | number : '1.2-2' }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</mat-card>
