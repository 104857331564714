<mat-card class="index-card" [class.detailed]="detailed">
    <mat-icon class="top-left-info-icon" svgIcon="info" appInfoButton="text-stats"
        infoTitle="Quantitative Text-Analyse"></mat-icon>
    <div class="result-panel-wrap" (click)="itemClick();">
        <div class="result-panel-content">
            <div>
                <b>Häufige Wörter</b>
            </div>
        </div>
    </div>
    <div class="score-details" [style.height]="detailsHeight">
        <div id="word-cloud-details-inner" class="score-details-inner">
            <div *ngIf="detailed" class="score-details-head">
                <ul class="number-list">
                    <li *ngFor="let word of numbers; let i = index">
                        <span class="number-title">{{ (i + 1) }}. {{ word.word }}</span>
                        <span class="number-value">{{ word.number | number }} ({{ word.percent | number : '1.0-0' }} %)</span>
                    </li>
                </ul>
                <div *ngIf="!numbers.length">Keine häufigen Wörter gefunden.</div>
            </div>
        </div>
    </div>
</mat-card>