<div class="accordion-window" [class.on-Board]="onBoard">
    <h5>{{ infoTitle }}</h5>
    <div *ngIf="data.length == 1">
        <p [innerHtml]="data[0].text">
        </p>
    </div>
    <mat-accordion *ngIf="data.length > 1" multi="true">
        <mat-expansion-panel *ngFor="let text of data" hideToggle>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    {{ text.title }}
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="accordion-content">
                <p class="info-text" [innerHtml]="text.text">
                </p>
            </div>
        </mat-expansion-panel>
    </mat-accordion>
</div>