import {
  Component,
  OnInit,
  ViewChild,
  Input,
  AfterViewInit,
  OnDestroy,
  Type,
  ComponentRef,
  ComponentFactoryResolver,
  ChangeDetectorRef,
  ElementRef,
  EventEmitter
} from '@angular/core';
import { AnalysisItemContentDirective } from './analysis-item-content.directive';
import { AnalysisItemContent } from './analysis-item-content-interface';
import { CurrentTextService } from 'src/app/services/current-text.service';

@Component({
  selector: 'app-analysis-item',
  templateUrl: './analysis-item.component.html',
  styleUrls: ['./analysis-item.component.scss']
})
export class AnalysisItemComponent implements AfterViewInit, OnDestroy {

  @ViewChild(AnalysisItemContentDirective) insertionPoint: AnalysisItemContentDirective;

  @Input() childComponentType: Type<any>;
  @Input() order: number;
  @Input() itemName: string;
  @Input() data: any;
  @Input() inactive: boolean;
  @Input() scannerName: string;
  componentRef: ComponentRef<any>;
  componentInstance: AnalysisItemContent;
  _hidden: boolean;
  @Input()
  set hidden(hidden: boolean) {
    if(hidden != this._hidden){
      this._hidden = hidden;
      this.visibilityChange.emit(hidden);
    }
  }
  get hidden(): boolean {
    return this._hidden;
  }

  visibilityChange: EventEmitter<boolean> = new EventEmitter();

  detailed = false;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private cd: ChangeDetectorRef,
    private currentText: CurrentTextService
    // private elementRef: ElementRef
  ) {
    this.currentText.textReset.subscribe((soft) => {
      if (!soft) {
        this.componentInstance.close();
      }
    });
  }

  ngAfterViewInit() {
    this.loadChildComponent(this.childComponentType);

    // this.pinboard.focusItem.subscribe((info) => {
    //   this.setFocus(info.frontZIndex, info.zIndex, info.id);
    // });

    // @todo: nessesary?
    this.cd.detectChanges();
  }

  ngOnDestroy() {
    if (this.componentRef) {
      this.componentRef.destroy();
    }
  }

  loadChildComponent(componentType: Type<any>) {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(componentType);

    const viewContainerRef = this.insertionPoint.viewContainerRef;
    viewContainerRef.clear();

    this.componentRef = viewContainerRef.createComponent(componentFactory);
    this.componentInstance = (this.componentRef.instance as AnalysisItemContent);
    this.componentInstance.itemName = this.itemName;
    this.componentInstance.data = this.data;
    this.componentInstance.inactive = this.inactive;
    this.componentInstance.scannerName = this.scannerName;
    this.componentInstance.order = this.order;
    this.componentInstance.visibilityChange = this.visibilityChange;
  }

}
