import { PinboardComponent } from '../pinboard.component';
import {
  Component,
  AfterViewInit,
  OnDestroy, Type,
  ViewChild,
  ComponentFactoryResolver,
  ComponentRef,
  ChangeDetectorRef,
  HostBinding,
  ElementRef,
  Input
} from '@angular/core';
import { InfoItemContentDirective } from './info-item-content.directive';
import { InfoItemContent } from './info-item-content-interface';
import { CdkDrag } from '@angular/cdk/drag-drop';
import { FreePinsComponent } from '../free-pins/free-pins.component';

@Component({
  selector: 'app-info-item',
  templateUrl: './info-item.component.html',
  styleUrls: ['./info-item.component.scss']
})
export class InfoItemComponent implements AfterViewInit, OnDestroy {

  // @HostBinding('attr.cdkDrag') cdkDragDirective = new CdkDrag(this.elementRef);

  @ViewChild(InfoItemContentDirective) insertionPoint: InfoItemContentDirective;

  @Input() childComponentType: Type<any>;
  @Input() id: number;
  @Input() name: string;
  @Input() itmeTitle: string;
  @Input() data: any;
  @Input() pinboard: PinboardComponent | FreePinsComponent;
  @Input() onBoard = false;
  componentRef: ComponentRef<any>;

  // zIndex: number;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private cd: ChangeDetectorRef,
    private elementRef: ElementRef
  ) { }

  ngAfterViewInit() {
    this.loadChildComponent(this.childComponentType);

    // this.pinboard.focusItem.subscribe((info) => {
    //   this.setFocus(info.frontZIndex, info.zIndex, info.id);
    // });

    // @todo: nessesary?
    this.cd.detectChanges();
    this.pinboard.cd.detectChanges();
  }

  ngOnDestroy() {
    if (this.componentRef) {
      this.componentRef.destroy();
    }
  }

  loadChildComponent(componentType: Type<any>) {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(componentType);

    const viewContainerRef = this.insertionPoint.viewContainerRef;
    viewContainerRef.clear();

    this.componentRef = viewContainerRef.createComponent(componentFactory);
    const comInstance = (this.componentRef.instance as InfoItemContent);
    comInstance.infoName = this.name;
    comInstance.infoTitle = this.itmeTitle;
    comInstance.data = this.data;
    comInstance.onBoard = this.onBoard;
  }

  // setFocus(frontZIndex: number, zIndex: number, id: number) {
  //   if (!this.onBoard) {
  //     if (this.id === id) {
  //       this.zIndex = frontZIndex;
  //     } else if (this.zIndex >= zIndex) {
  //       this.zIndex--;
  //     }
  //   }
  // }

  focusThis() {
    if (!this.onBoard) {
      this.pinboard.focusInfoItem(this.id);
    }
  }

  closeThis() {
    this.pinboard.removeInfoItem(this.id);
  }

}
