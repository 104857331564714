import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HttpErrorResponse } from '@angular/common/http';
import { RequestTextBlock } from 'src/app/TeoDefinitions';
import { AppStateService } from 'src/app/services/app-state.service';
import { JsonApiService } from 'src/app/services/json-api.service';
// import { FilteredTextPart, SpellCheckResponse } from 'src/app/TeoDefinitions';

@Injectable({
  providedIn: 'root'
})
export class LanguageToolApiService {
  private apiUrl = environment.languageToolUrl;
  constructor(
    private appState: AppStateService,
    private http: HttpClient,
    private jsonAPI: JsonApiService
  ) {

  }

  arrayUrlEncode(array: object): string {
    let encoded = '';
    let first = true;
    for (const key of Object.keys(array)) {
      if (!first) {
        encoded += '&';
      } else {
        first = false;
      }
      encoded += key + '=' + encodeURIComponent(array[key]);
    }
    return encoded;
  }

  checkTextBlocks(blocks: RequestTextBlock[], onSuccess, onError) {
    const request = {
      data: JSON.stringify({ annotation: this.teoTextBlocksToLangTool(blocks) }),
      language: 'de-DE',
    };
    if (! this.apiUrl) {
      this.jsonAPI.postAuthenticatedTeoRequest(
        request,
        'lt/check',
        onSuccess,
        onError
      );
    } else {
      const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
      this.http.post(this.apiUrl + 'check', this.arrayUrlEncode(request), { headers }).subscribe((response: any) => {
        onSuccess(response);
      }, (response) => {
        onError(response);
      });
    }
  }

  teoTextBlocksToLangTool(textBlocks: RequestTextBlock[]) {
    const langToolBlocks = [];
    textBlocks.forEach(block => {
      let lTBlock: any = {};
      if (block.type == 'text') {
        lTBlock.text = block.string;
      } else if (block.type == 'linebreak') {
        lTBlock.markup = block.string;
        lTBlock.interpretAs = '\n';
      } else if (block.type == 'whitespace') {
        lTBlock.markup = block.string;
        lTBlock.interpretAs = ' ';
      } else if (block.type == 'paragraph') {
        lTBlock.markup = block.string;
        lTBlock.interpretAs = '\n\n';
      } else if (block.type == 'ignore') {
        lTBlock.markup = block.string;
      }
      if (typeof block.interpret_as != 'undefined') {
        if (lTBlock.text) {
          lTBlock.markup = lTBlock.text;
          delete lTBlock.text;
        }
        lTBlock.interpretAs = block.interpret_as;
      }
      langToolBlocks.push(lTBlock);
    });
    return langToolBlocks;
  }

  checkText(text: string, onSuccess, onError) {
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    const request = {
      text,
      language: 'de-DE',
    };
    this.http.post(this.apiUrl + 'check', this.arrayUrlEncode(request), { headers }).subscribe((response: any) => {
      onSuccess(response);
    }, (response) => {
      onError(response);
    });
  }

  // loadDictionaries(){
  //   this.http.get(this.apiUrl + 'words?username=test&apiKey=sfsghsfwe').subscribe((data: any[]) => {
  //     console.log(data);
  //   }, (error: HttpErrorResponse) => {
  //     console.error(error);
  //   });
  // }
}
