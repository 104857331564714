<mat-card *ngIf="gotContent" class="index-card" [class.detailed]="detailed" [class.small-layout]="smallLayout">
  <mat-icon class="top-left-info-icon" svgIcon="info" [appInfoButton]="itemName" [infoTitle]="data.title"></mat-icon>
  <div class="result-panel-wrap" (click)="itemClick();">
    <div class="result-panel-content">
      <div class="score-text">
        {{data.title}}<span *ngIf="!inactive">:
          <b>
            {{data.percent  | number:'1.0-0' }}%
            <!-- <span>
                        ({{data.score}})
                    </span> -->
          </b>
        </span>
        <span *ngIf="scoreChange !== null && !inactive" class="score-change" [class.hidden]="currentText.analysisCounter < 2"
          [class.positive]="scoreChange > 0" [class.negative]="scoreChange < 0" >
          {{ absScoreChange() }}%
        </span>
      </div>
      <app-analysis-item-scale [indexValue]="data.percent" indexType="default" leftMarking="0" centerMarking=""
        rightMarking="100" [benchmark]="data.benchmark" [indexRawValue]="data.score"[inactive]="inactive">
      </app-analysis-item-scale>
    </div>
  </div>
  <div class="score-details" [style.height]="todoDetailsHeight + 'px'">
    <div [id]="todoDetailsInnerId" class="score-details-inner">
      <h4>Details</h4>
      <div>
        <div *ngFor="let todo of data.text_properties;" class="score-todo" [class.active]="selectedTodo === todo.name"
          (click)="selectTodo(todo.name, todo.markers)">
          <div class="score-todo-title">{{ todo.title }}</div>
          <div *ngIf="todo.name !== 'perspective'" class="score-todo-numbers">
            {{ todo.number }} ({{ todo.percentage | number:'1.0-0' }}%)
          </div>
          <div *ngIf="todo.name === 'perspective'" class="score-todo-numbers">
            ({{ todo.percentage_we | number:'1.0-0' }}%/{{ todo.percentage_you | number:'1.0-0' }}%)
          </div>
        </div>
      </div>
      <h4 *ngIf="selectedTodo !== null">Handlunsempfehlungen<a href="#" (click)="toggleTodoDetails();">
          <span *ngIf="!todoDetailsOpen">ausklappen</span>
          <span *ngIf="todoDetailsOpen">einklappen</span>
        </a>
      </h4>
      <div class="todo-details" [class.open]="todoDetailsOpen">
        <div *ngFor="let todo of data.text_properties;" class="score-todo-detail"
          [class.active]="selectedTodo === todo.name">
          <div [id]="todoDetailsInnerId + todo.name" class="score-todo-detail-inner">
            <h6>{{ todo.title }}</h6>
            <h3>Was ist zu tun?</h3>
            <p class="info-text">
              {{ todo.todo }}
            </p>
            <h3>Tipp:<mat-icon class="light" svgIcon="light"></mat-icon>
            </h3>
            <p class="info-text">
              {{ todo.tip }}
            </p>
            <h3>So gehts!<mat-icon svgIcon="pen"></mat-icon>
            </h3>
            <div class="yellow-card">
              <ul>
                <li *ngFor="let howto of todo.howto;" class="" [innerHTML]="howto"></li>
              </ul>
              <a *ngIf="!notesBoxPinned(todo.name) && !smallLayout" href="#" class="pinboard-toggle"
                (click)="openNotesBox(todo.name, todo.title, todo.howto)">an Pinnwand heften</a>
              <a *ngIf="notesBoxPinned(todo.name) && !smallLayout" href="#" class="pinboard-toggle"
                (click)="closeNotesBox(todo.name)">von Pinnwand entfernen</a>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</mat-card>
