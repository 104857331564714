import { CommonModule } from '@angular/common';
import { Component, OnInit, Input } from '@angular/core';
import { AnalysisItemContent } from '../analysis-item-content-interface';
import { CurrentTextService } from 'src/app/services/current-text.service';
import { AppStateService } from 'src/app/services/app-state.service';
import { AccordionInfoComponent } from 'src/app/pinboard/info-item/accordion-info/accordion-info.component';
import { JsonApiService } from 'src/app/services/json-api.service';
import { AnalysisRequest } from 'src/app/TeoDefinitions';

@Component({
  selector: 'app-thesaurus',
  templateUrl: './thesaurus.component.html',
  styleUrls: ['../analysis-item-shared.scss', './thesaurus.component.scss']
})
export class ThesaurusComponent implements OnInit, AnalysisItemContent {
  data: any;
  itemName: string;
  @Input() inactive: boolean;
  @Input() scannerName: string;

  detailed = false;

  loading = false;

  thesEntries: any;

  entryNumber = 0;

  currentEntry: string;

  currentSynonymId: number = null;

  textClickSubscription = null;
  markingsSubscription = null;

  markings = ['wrap_thesaurus-fdl'];

  // editorMarkings = [];

  // currentReplacement: string;

  detailsHeight = '0px;';

  constructor(
    private currentText: CurrentTextService,
    private appState: AppStateService,
    private api: JsonApiService
  ) {
    this.currentText.gotScannerResults.subscribe((dataArray) => {
      if (dataArray && dataArray[this.scannerName]) {
        this.useScanResult(dataArray[this.scannerName]);
      }
      this.setDetailsHeight();
    });
  }

  useScanResult(data) {
    this.currentEntry = null;
    this.currentSynonymId = null;
    this.thesEntries = {};
    this.entryNumber = 0;

    for (const entry of data.entries) {
      this.thesEntries[entry.name] = {
        word: entry.word,
        synonyms: entry.synonyms,
        replacement: entry.word,
        text: entry.text
      };
      this.entryNumber++;
    }
  }

  ngOnInit() {
  }

  openTextEntry() {
    const name = 'thesaurus-' + this.thesEntries[this.currentEntry].word;
    const title = 'Lexikalischer Eintrag: ' + this.thesEntries[this.currentEntry].word;
    this.appState.openInfoItemCustomText(
      AccordionInfoComponent,
      name,
      title,
      this.thesEntries[this.currentEntry].text
    );
  }

  resetReplacement() {
    this.currentSynonymId = null;
    this.useReplacement();
  }

  useReplacement() {
    const synonyms = this.thesEntries[this.currentEntry].synonyms;
    if (this.currentSynonymId !== null) {
      this.thesEntries[this.currentEntry].replacement = synonyms[this.currentSynonymId];
    } else {
      this.thesEntries[this.currentEntry].replacement = this.thesEntries[this.currentEntry].word;
    }

    const editorNode = document.getElementById('editor-text');
    const itemNode = editorNode.getElementsByClassName('relation_' + this.currentEntry)[0];

    itemNode.innerHTML = this.thesEntries[this.currentEntry].replacement;

    const event = new Event('input');
    event.initEvent('input', true, true);
    editorNode.dispatchEvent(event);
    this.setDetailsHeight();
  }

  close(removeMarkings = true) {
    this.detailed = false;
    this.currentEntry = null;
    if (this.textClickSubscription !== null) {
      this.textClickSubscription.unsubscribe();
    }
    if (this.markingsSubscription !== null) {
      this.markingsSubscription.unsubscribe();
    }
    if (removeMarkings) {
      this.currentText.removeMarkings();
      this.currentText.clickClasses = [];
    }
    this.setDetailsHeight();
  }

  open() {
    this.currentText.clickClasses = this.markings;
    this.currentText.setMarkings(this.markings);
    this.textClickSubscription = this.currentText.textClick.subscribe((data) => {
      if (data.marker === this.markings[0]) {
        data.relations.forEach(element => {
          if (element.marker == 'thesaurus-fdl') {
            this.currentEntry = element.relation;
            this.currentSynonymId = null;
            this.thesEntries[this.currentEntry].synonyms.forEach((synonym, index) => {
              if(data.target.innerHTML == synonym){
                this.currentSynonymId = index;
              }
            });
            this.setDetailsHeight();
          }
        });
      }
    });
    this.markingsSubscription = this.currentText.markings.subscribe((reset: boolean) => {
      if (reset && this.detailed) {
        if (!this.currentText.activeMarkings.includes(this.markings[0])) {
          this.close(false);
        }
      }
    });

    this.detailed = true;
    this.setDetailsHeight();
  }

  itemClick() {
    if (this.detailed) {
      this.close();
    } else {
      this.open();
    }
  }

  private setDetailsHeight() {
    window.setTimeout(() => {
      if (this.detailed) {
        const todoElem = document.getElementById('thesaurus-details-inner');
        const height = todoElem.offsetHeight;
        this.detailsHeight = height + 'px';
      } else {
        this.detailsHeight = '0px';
      }
    }, 20);
  }

}
