import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { CurrentTextService } from '../../services/current-text.service';
import { ScoreItem } from '../../TeoDefinitions';
import { AppStateService } from '../../services/app-state.service';
import { JsonApiService } from 'src/app/services/json-api.service';
import { AnalysisIndexComponent } from './analysis-item/analysis-index/analysis-index.component';
import { KeywordScannerComponent } from './analysis-item/keyword-scanner/keyword-scanner.component';
import { AnglicismsComponent } from './analysis-item/anglicisms/anglicisms.component';
import { ThesaurusComponent } from './analysis-item/thesaurus/thesaurus.component';
import { CorporateTonalityComponent } from './analysis-item/corporate-tonality/corporate-tonality.component';
import { HumanInterestComponent } from './analysis-item/human-interest/human-interest.component';
import { TextNumbersComponent } from './analysis-item/textNumbers/textNumbers.component';
import { WordCloudComponent } from './analysis-item/wordCloud/wordCloud.component';
import { DynIndexDebugComponent } from './analysis-item/dynIndexDebug/dynIndexDebug.component';
import { CausalityScannerComponent } from './analysis-item/causalityScanner/causalityScanner.component';
import { DialogScannerComponent } from './analysis-item/dialogScanner/dialogScanner.component';
import { environment } from 'src/environments/environment';
import { LanguageToolComponent } from './analysis-item/languageTool/language-tool.component';
import { PowerWordsComponent } from './analysis-item/power-words/power-words.component';
import { GenderThesaurusComponent } from './analysis-item/gender-thesaurus/gender-thesaurus.component';
import { SentimentComponent } from './analysis-item/sentiment/sentiment.component';
import { WordList, WordListComponent } from './analysis-item/word-list/word-list.component';

@Component({
  selector: 'app-analysis-main',
  templateUrl: './analysis-main.component.html',
  styleUrls: ['./analysis-main.component.scss'],
})


export class AnalysisMainComponent implements OnInit {
  @ViewChild('resultsScroller', { read: ElementRef }) public resultsScroller: ElementRef<any>;
  showFullscreenButton = false;
  // editorFullscreen = true;
  fullscreenOffset = '0px';
  fullscreenWidth = '100%';
  fullscreenZIndex = '2';
  fullscreenCssPosition = '';
  fullscreenTransition = '';

  currentCategory = '';

  tliBenchmarkChange = null;

  scorePercentDistance = null;

  displayPercent = false;

  // scoreItems: ScoreItem[];
  styleItems: ScoreItem[];
  performanceItems: ScoreItem[];
  // structureItems: ScoreItem[];
  statisticItems: ScoreItem[];
  brandItems: ScoreItem[];
  toolItems: ScoreItem[];

  showEditor = environment.editor !== 'office';

  showBrandFit = true;

  categoryNames = {
    activation: 'Performance',
    brand: 'Brand-Scanner',
    style: 'Stil-Scanner',
    statistic: 'Statistik',
  }

  categoryDropdownHeight = '0px';

  @ViewChild('teoEditorWrap', { static: true }) teoEditorWrap: ElementRef;
  constructor(
    public currentText: CurrentTextService,
    public appState: AppStateService,
    private api: JsonApiService
  ) {
    appState.logout.subscribe(() => {
      this.newAnalysis();
    });

    currentText.textReset.subscribe((soft) => {
      this.tliBenchmarkChange = null;
      this.scorePercentDistance = null;
      this.selectIndexCategory(null);
    });

    appState.brandFitOpen.subscribe(() => {
      // if (this.currentCategory !== 'brand') {
      this.selectIndexCategory('brand');
      // }
    });

    if (environment.mainIndexName == "TLI") {
      this.displayPercent = false;
    } else {
      this.displayPercent = true;
    }

    // this.scoreItems = [
    // ];
    this.performanceItems = [
      {
        name: 'comprend',
        itemType: AnalysisIndexComponent,
        hidden: true,
        inactive: false,
        data: {
          title: 'Verständlichkeit',
          type: 'default'
        },
      }, {
        name: 'abstract',
        itemType: AnalysisIndexComponent,
        hidden: true,
        inactive: false,
        data: {
          title: 'Bildhaftigkeit',
          type: 'default'
        },
      }, {
        name: 'activation',
        itemType: AnalysisIndexComponent,
        hidden: true,
        inactive: false,
        data: {
          title: 'Aktivierung',
          type: 'peak'
        },
      },
      {
        name: 'human_interest',
        itemType: HumanInterestComponent,
        hidden: true,
        inactive: false,
        data: {
        },
      }];
    this.styleItems = [
    ];
    // this.toolboxItems = [];
    this.statisticItems = [{
      name: 'text-stats',
      itemType: TextNumbersComponent,
      hidden: true,
      inactive: false,
      data: {
      },
    }, {
      name: 'wordcloud',
      itemType: WordCloudComponent,
      hidden: true,
      inactive: false,
      data: {
      },
    },];
    this.brandItems = [
    ];

    this.toolItems = [];

    if (environment.optionalModules.keywordScanner) {
      this.statisticItems.push({
        name: 'keyword_scanner',
        itemType: KeywordScannerComponent,
        hidden: true,
        inactive: false,
        data: {
        },
      });
    }

    if (environment.optionalModules.debugCard) {
      this.statisticItems.push({
        name: 'dyn_index_debug',
        itemType: DynIndexDebugComponent,
        hidden: true,
        inactive: false,
        resultProperty: 'dynIndexDebug',
        data: {
        },
      });
    }

    if (environment.optionalModules.powerWords) {
      this.brandItems.push({
        name: 'power-words',
        itemType: PowerWordsComponent,
        scannerName: 'ct-power_words',
        hidden: true,
        inactive: false,
        data: {
        },
      });
    }

    if (environment.optionalModules.sentimentScanner) {
      this.styleItems.push({
        name: 'sentiment',
        itemType: SentimentComponent,
        scannerName: 'sentiment',
        hidden: true,
        inactive: false,
        data: {
        },
      });
    }

    if (environment.optionalModules.anglicismThesaurus) {
      this.styleItems.push({
        name: 'anglicisms',
        itemType: AnglicismsComponent,
        hidden: true,
        inactive: false,
        data: {
        },
      });
    }
    if (environment.optionalModules.genderThesaurus) {
      this.styleItems.push({
        name: 'gender-thesaurus',
        itemType: GenderThesaurusComponent,
        scannerName: 'thesaurus-gender',
        hidden: true,
        inactive: false,
        data: {
        },
      });
    }
    if (environment.optionalModules.thesaurus) {
      this.styleItems.push({
        name: 'thesaurus',
        itemType: ThesaurusComponent,
        scannerName: 'thesaurus-fdl',
        hidden: true,
        inactive: false,
        data: {
        },
      });
    }
    if (environment.optionalModules.corporateTonality) {
      this.brandItems.push({
        name: 'corporate_tonality',
        itemType: CorporateTonalityComponent,
        scannerName: 'ct',
        hidden: true,
        inactive: false,
        data: {
        },
      });
    }
    if (environment.optionalModules.languageTool) {
      this.toolItems.push({
        name: 'language-tool',
        itemType: LanguageToolComponent,
        hidden: true,
        inactive: false,
        data: {
        },
      });
    }
    if (environment.optionalModules.wordLists) {
      if (appState.userIsLoggedIn()) {
        this.initWordLists();
      } else {
        appState.login.subscribe(() => {
          this.initWordLists();
        });
      }
    }

    if (environment.optionalModules.causality) {
      this.styleItems.push(
        {
          name: 'causality_scanner',
          itemType: CausalityScannerComponent,
          hidden: true,
          inactive: false,
          data: {
          },
        });
    }

    if (environment.optionalModules.dialog) {
      this.styleItems.push(
        {
          name: 'dialog_scanner',
          itemType: DialogScannerComponent,
          hidden: true,
          inactive: false,
          data: {
          },
        });
    }

  }

  // setText(text: string) {
  //   this.currentText.currentHtml = text;
  // }

  initWordLists() {
    this.api.getWordLists((wordLists: WordList[]) => {
      if (wordLists.length) {
        this.currentText.useWordLists = true;
        const brandItems = [];
        const styleItems = [];
        wordLists.forEach((wordList: WordList) => {
          if (wordList.relation_type != 4) {
            if (wordList.use_for_ct) {
              brandItems.push({
                name: wordList.name,
                itemType: WordListComponent,
                // scannerName: 'ct',
                hidden: true,
                inactive: false,
                data: {
                  wordList
                },
              });
            } else {
              styleItems.push({
                name: wordList.name,
                itemType: WordListComponent,
                // scannerName: 'ct',
                hidden: true,
                inactive: false,
                data: {
                  wordList
                },
              });
            }
          }
        });
        this.brandItems = brandItems.concat(this.brandItems);
        this.styleItems = styleItems.concat(this.styleItems);
      }
    }, (error) => {

    });
  }

  absBenchmarkChange() {
    // absolute value, rounded to two
    return Math.abs(Math.round(100 * this.tliBenchmarkChange) / 100);
  }

  newAnalysis(soft = false) {
    this.currentText.reset(soft);
    this.appState.setView('analysis-configuration');
  }

  analyzeText() {
    if (this.currentText.textModified && !this.currentText.analysisPending) {
      this.currentText.analyze((text: string) => {
        if (this.currentText.lastResult.tli >= 0) {
          this.tliBenchmarkChange = this.currentText.lastResult.tli - this.currentText.tliBenchmark;
          this.scorePercentDistance = 100 - Math.floor(this.currentText.getMainIndexPercentage());

          if (this.currentText.analysisCounter === 1) {
            this.selectIndexCategory('activation');
          }
        }
      });
    }
  }

  unmark() {
    if (this.currentText.editor) {
      this.currentText.editor.unmark();
    }
  }

  scrollResults(detailsHeight): void {
    // console.log("scroll ", detailsHeight)
    // this.myScrollContainer.nativeElement.scroll({
    //   top: this.myScrollContainer.nativeElement.scrollHeight,
    //   left: 0,
    //   behavior: 'smooth'
    // });
  }

  selectIndexCategory(category: string) {
    if (this.currentText.analysisCounter === 0 && category !== null) {
      return;
    }
    // if (this.currentCategory === 'tliDetails') {
    //   this.hideIndices(this.scoreItems);
    // } else
    if (this.currentCategory === 'style') {
      this.hideIndices(this.styleItems);
    } else if (this.currentCategory === 'brand') {
      this.hideIndices(this.brandItems);
    } else if (this.currentCategory === 'activation') {
      this.hideIndices(this.performanceItems);
    } else if (this.currentCategory === 'statistic') {
      this.hideIndices(this.statisticItems);
    } else if (this.currentCategory === 'tools') {
      this.hideIndices(this.toolItems);
    }

    this.currentCategory = category;

    // if (category === 'tliDetails') {
    //   this.showIndicesDelayed(this.scoreItems);
    // } else
    if (category === 'style') {
      this.currentText.updateActiveScanners(this.styleItems);
      this.showIndicesDelayed(this.styleItems);
    } else if (category === 'brand') {
      this.currentText.updateActiveScanners(this.brandItems);
      this.showIndicesDelayed(this.brandItems);
    } else if (category === 'activation') {
      this.currentText.updateActiveScanners(this.performanceItems);
      this.showIndicesDelayed(this.performanceItems);
    } else if (category === 'statistic') {
      this.currentText.updateActiveScanners(this.statisticItems);
      this.showIndicesDelayed(this.statisticItems);
    } else if (category === 'tools') {
      this.currentText.updateActiveScanners(this.toolItems);
      this.showIndicesDelayed(this.toolItems);
    } else {

    }
    this.resultsScroller.nativeElement.scrollTop = 0;

    this.closeCategoryDropdown();
  }

  showIndicesDelayed(indices: ScoreItem[]) {
    this.setVisibilityIndicesDelayedRec(0, indices, false);
  }

  hideIndicesDelayed(indices: ScoreItem[]) {
    this.setVisibilityIndicesDelayedRec(indices.length - 1, indices, true);
  }

  private setVisibilityIndicesDelayedRec(startIndex: number, indices: ScoreItem[], hidden: boolean) {
    if (!indices.length) {
      return;
    }
    let skip = false;
    if (indices[startIndex].resultProperty
      // && !this.currentText.lastResult.hasOwnProperty(indices[startIndex].resultProperty)
      && !this.currentText.lastResult[indices[startIndex].resultProperty]) {
      // skip this item
      skip = true;
    } else {
      indices[startIndex].hidden = hidden;
    }
    if (hidden) {
      startIndex--;
    } else {
      startIndex++;
    }
    if (startIndex < indices.length && startIndex >= 0) {
      if (skip) {
        this.setVisibilityIndicesDelayedRec(startIndex, indices, hidden);
      } else {
        window.setTimeout(() => { this.setVisibilityIndicesDelayedRec(startIndex, indices, hidden); }, 150);
      }
    }
  }

  showIndices(indices: ScoreItem[]) {
    for (const index of indices) {
      index.hidden = false;
    }
  }

  hideIndices(indices: ScoreItem[]) {
    for (const index of indices) {
      index.hidden = true;
    }
  }

  toggleCategoryDropdown() {
    if (this.categoryDropdownHeight === '0px') {
      this.openCategoryDropdown();
    } else {
      this.closeCategoryDropdown();
    }
  }

  closeCategoryDropdown() {
    this.categoryDropdownHeight = '0px';
  }

  openCategoryDropdown() {
    this.categoryDropdownHeight = '140px';
  }

  ngOnInit() {
  }

}
