import { AnalysisIndex } from '../../../../TeoDefinitions';
import { Component, OnInit, Input, ViewChild, ElementRef, AfterViewInit, Directive } from '@angular/core';
import { CurrentTextService } from '../../../../services/current-text.service';
import { AccordionInfoComponent } from 'src/app/pinboard/info-item/accordion-info/accordion-info.component';
import { NoteInfoComponent } from 'src/app/pinboard/info-item/note-info/note-info.component';
import { AppStateService } from 'src/app/services/app-state.service';
import { AnalysisItemContent } from '../analysis-item-content-interface';
import { environment } from 'src/environments/environment';

// @Directive({ selector: '[todoDetailsInner]' })
// export class TodoDetailsInnerDirective {
// }

@Component({
  selector: 'app-analysis-index',
  templateUrl: './analysis-index.component.html',
  styleUrls: ['../analysis-item-shared.scss', './analysis-index.component.scss']
})
export class AnalysisIndexComponent implements OnInit, AfterViewInit, AnalysisItemContent {
  @Input() itemName: string;
  @Input() data: any;
  @Input() inactive: boolean;

  indexTitle: string;
  indexType: string;
  detailed: boolean = false;

  index: AnalysisIndex;
  leftMarking: string;
  centerMarking: string;
  rightMarking: string;

  scoreChange: number;

  todoDetailsOpen = false;
  selectedTodo: string = null;

  todoDetailsHeight = 0;
  todoDetailsInnerId: string;

  notesBoxOpen = {};

  waitForMarkings = false;

  comprendTliException = false;

  smallLayout = environment.editor == 'office';

  constructor(
    private currentText: CurrentTextService,
    private appState: AppStateService
  ) {
    this.currentText.markings.subscribe((reset: boolean) => {
      if (reset) {
        if (this.waitForMarkings) {
          this.waitForMarkings = false;
        } else {
          this.selectedTodo = null;
          window.setTimeout(() => {
            this.setTodoDetailsHeight(true);
          }, 50);
        }
      }
    });
  }

  ngOnInit() {

    this.indexTitle = this.data.title;
    this.indexType = this.data.type;

    this.resetIndex();
    this.currentText.gotResults.subscribe(() => {

      if (this.itemName == "activation") {
        if (
          this.currentText.textStrategy
          && (this.currentText.textStrategy.main_index_name == 'TPI1' || this.currentText.textStrategy.main_index_name == 'TPI2')
        ) {
          this.inactive = true;
        } else {
          this.inactive = false;
        }
      }
      if (this.itemName == "comprend") {
        if (environment.mainIndexName == "TLI") {
          this.comprendTliException = true;
        } else {
          this.comprendTliException = false;
        }
      }
      const freshIndex = this.currentText.scoreResults[this.itemName];
      if (typeof freshIndex !== 'undefined') {
        this.scoreChange = Math.round(freshIndex.percent - this.index.percent);
        this.index = freshIndex;
      } else {
        this.scoreChange = 0;
        this.index.score = 0;
        this.index.percent = 0;
        this.index.todo = [];
      }

      window.setTimeout(() => {
        this.setTodoDetailsHeight();
      }, 200);

    });

    this.appState.pinboardChange.subscribe(() => {
      for (const name in this.notesBoxOpen) {
        if (Object.prototype.hasOwnProperty.call(this.notesBoxOpen, name)) {
          if (!this.appState.infoItemIsPinned(name)) {
            this.notesBoxOpen[name] = null;
          }
        }
      }
    });

    this.index.name = this.itemName;
    this.index.title = this.indexTitle;
    this.todoDetailsInnerId = 'todo-details-inner-' + this.itemName;
    if (this.indexType === 'number') {

    } else if (this.indexType === 'peak') {
      // this.leftMarking = 'zu schwach';
      // this.centerMarking = 'aktivierend';
      // this.rightMarking = 'zu stark';
      this.leftMarking = 'schwach';
      this.centerMarking = '';
      this.rightMarking = 'stark';
    } else {
      this.indexType = 'default';
      this.leftMarking = '0';
      this.centerMarking = '';
      this.rightMarking = '100';
      this.scoreChange = null;
    }
  }
  ngAfterViewInit() {
  }

  private resetIndex() {
    this.index = {
      name: '',
      title: '',
      score: 0,
      percent: 0,
      benchmark: 10,
      todo: []
    };
  }

  private absScoreChange() {
    return Math.abs(this.scoreChange);
  }

  selectTodo(todoMarker: string) {
    if (this.selectedTodo !== todoMarker) {
      this.selectedTodo = todoMarker;
      this.waitForMarkings = true;
      this.currentText.setMarkings(todoMarker.split(','));
    } else {
      this.selectedTodo = null;
      this.currentText.removeMarkings();
    }
    window.setTimeout(() => {
      this.setTodoDetailsHeight();
    }, 50);
  }

  toggleTodoDetails() {
    this.todoDetailsOpen = !this.todoDetailsOpen;
    this.setTodoDetailsHeight();
  }

  openNotesBox(todoMarker: string, title: string, howtos: object) {
    this.notesBoxOpen[todoMarker] = this.appState.pinInfoItem(NoteInfoComponent, todoMarker + '-notes', title, { howtos });
  }
  closeNotesBox(todoMarker: string) {
    this.appState.unpinInfoItem(this.notesBoxOpen[todoMarker]);
    this.notesBoxOpen[todoMarker] = null;
  }

  private notesBoxPinned(todoMarker: string) {
    return !(typeof this.notesBoxOpen[todoMarker] === 'undefined' || this.notesBoxOpen[todoMarker] === null);
  }

  private setTodoDetailsHeight(scrollMain = false) {
    if (this.detailed) {
      const todoElem = document.getElementById(this.todoDetailsInnerId + this.selectedTodo);
      const todosElem = document.getElementById(this.todoDetailsInnerId);
      let height = 0;
      if (this.todoDetailsOpen && this.selectedTodo !== null && todoElem.offsetHeight) {
        height += todoElem.offsetHeight;
      }
      if (todosElem.offsetHeight) {
        height += todosElem.offsetHeight;
      }

      if (scrollMain && this.todoDetailsHeight > height) {
        // do something
        // this.appState.scrollResults.emit(height - this.todoDetailsHeight);
      }

      this.todoDetailsHeight = height;
    } else {
      this.todoDetailsHeight = 0;
    }
  }

  open() {
    if (!this.inactive) {
      this.detailed = true;
      this.setTodoDetailsHeight();
    }
  }

  close() {
    this.detailed = false;
    this.selectedTodo = null;
    this.currentText.removeMarkings();
    this.setTodoDetailsHeight();
  }

  itemClick() {
    if (this.detailed) {
      this.close();
    } else {
      this.open();
    }
  }
}
