<mat-card class="index-card" [class.detailed]="detailed">
  <mat-icon class="top-left-info-icon" svgIcon="info" appInfoButton="corporate-tonality" infoTitle="Corporate Text-Prüfung">
  </mat-icon>
  <mat-progress-bar class="card-loading-bar" [style.opacity]="loading ? 1 : 0" mode="indeterminate"
  color="accent"></mat-progress-bar>
  <div class="result-panel-wrap" (click)="itemClick();">
    <div class="result-panel-content">
      <div class="">
        Corporate Text-Prüfung: <b>{{ violationNumber }}</b>
      </div>
    </div>
  </div>
  <div class="score-details" [style.height]="detailsHeight">
    <div id="ct-details-inner" class="score-details-inner">
      <!-- <div *ngIf="detailed" class="score-details-head">
        <a href="#" (click)="scan()"><span *ngIf="ctViolations.length || noViolations">neu </span>prüfen</a>

      </div> -->
      <div *ngIf="detailed && !noViolations">
        <h4>Vorgaben</h4>
        <div>
          <label *ngFor="let violation of ctViolations" class="score-todo">
            <div class="score-todo-text ct-entry">
              Statt <i>{{ violation.word }}</i><br />
              -> <b><i>{{ violation.replacement }}</i></b>
            </div>
            <mat-checkbox [(ngModel)]="violation.replaced"
              (ngModelChange)="useReplacement(violation.id)"></mat-checkbox>
          </label>
        </div>
        <div class="keyword-actions">
          <a href="#" (click)="correctAll()">Alle Korrigieren</a>
        </div>
        <div class="keyword-infos">
          Achten Sie bitte darauf, dass Sie betreffende Artikel ggf. ersetzen müssen. Denken Sie bitte daran,
          den Text erneut zu analysieren, sobald Sie die gewünschten Synonyme ersetzt haben.
        </div>
      </div>
    </div>
  </div>
</mat-card>
