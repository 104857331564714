import { Component, OnInit, Inject, Input } from '@angular/core';
import { AnalysisItemContent } from '../analysis-item-content-interface';
import { CurrentTextService } from 'src/app/services/current-text.service';
import { AppStateService } from 'src/app/services/app-state.service';
import { AccordionInfoComponent } from 'src/app/pinboard/info-item/accordion-info/accordion-info.component';
// import { NumberPipe } from '@angular/common';
import { LOCALE_ID } from '@angular/core';
import { formatNumber, formatPercent } from '@angular/common';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-text-numbers',
  templateUrl: './textNumbers.component.html',
  styleUrls: ['../analysis-item-shared.scss', './textNumbers.component.scss']
})
export class TextNumbersComponent implements OnInit, AnalysisItemContent {
  data: any;
  itemName: string;
  @Input() inactive: boolean;

  detailed = false;

  numbers: any[] = [];

  detailsHeight = '0px;';

  showCopyButton = false;

  smallLayout = environment.editor == 'office';

  constructor(
    private currentText: CurrentTextService,
    private appState: AppStateService,
    @Inject(LOCALE_ID) public locale: string
  ) {
    this.currentText.gotResults.subscribe(() => {
      this.data = this.currentText.lastResult.textNumbers;
      if (this.data) {
        this.numbers = this.createNumbersArray(this.data);
      } else {
        this.numbers = [];
      }

      this.setDetailsHeight();
      if (this.appState.user && this.appState.user.role && this.appState.user.role.permissions.includes('SEE_DEBUG_INFO')) {
        this.showCopyButton = true;
      }
    });

  }

  ngOnInit() {
  }

  close(removeMarkings = true) {
    this.detailed = false;
    this.setDetailsHeight();
  }

  open() {
    this.detailed = true;
    this.setDetailsHeight();
  }

  itemClick() {
    if (this.detailed) {
      this.close();
    } else {
      this.open();
    }
  }

  createNumbersArray(data) {
    return [
      {
        label: 'Anzahl Wörter',
        value: formatNumber(data.word_num, this.locale)
      },
      {
        label: 'Ø Wortlänge',
        value: formatNumber(data.word_length_syll, this.locale, '1.2-2')
      },
      {
        label: 'Anzahl lange Wörter',
        value: formatNumber(data.long_word_num, this.locale)
      },
      {
        label: 'Lange Wörter in %',
        value: formatNumber(data.long_word_percentage, this.locale, '1.2-2') + ' %'
      },
      {
        spacer: true,
        label: '',
        value: ''
      },
      {
        label: 'Anzahl Sätze',
        value: formatNumber(data.sentence_num, this.locale)
      },
      {
        label: 'Ø Satzlänge',
        value: formatNumber(data.sentence_length, this.locale, '1.2-2')
      },
      {
        label: 'Anzahl lange Sätze',
        value: formatNumber(data.long_sentence_num, this.locale)
      },
      {
        label: 'Lange Sätze in %',
        value: formatNumber(data.long_sentence_percentage, this.locale, '1.2-2') + ' %'
      },
      {
        spacer: true,
        label: '',
        value: ''
      },
      {
        label: 'Anzahl Substantive',
        value: formatNumber(data.substantive_num, this.locale)
      },
      {
        label: 'Anteil Substantive in %',
        value: formatNumber(data.substantive_percentage, this.locale, '1.2-2') + ' %'
      },
      {
        label: 'Anzahl abstr. Substantive',
        value: formatNumber(data.abstract_num, this.locale)
      },
      {
        label: 'Anteil abstr. Substantive in %',
        value: formatNumber(data.abstract_percentage, this.locale, '1.2-2') + ' %'
      },
      {
        spacer: true,
        label: '',
        value: ''
      },
      {
        label: 'Anzahl persönliche Sätze',
        value: formatNumber(data.personal_sentence_num, this.locale)
      },
      {
        label: 'Anzahl persönliche Wörter',
        value: formatNumber(data.personal_word_num, this.locale)
      },
      {
        label: 'Perspektive: Wir% Sie%',
        name: 'perspective',
        value: formatNumber(data.we_percentage, this.locale, '1.0-1')
          + ' % '
          + formatNumber(data.you_percentage, this.locale, '1.0-1')
          + ' %'
      },
      {
        spacer: true,
        label: '',
        value: ''
      },
      {
        label: 'Anzahl Sätze mit Impuls',
        value: formatNumber(data.impuls_sentence_num, this.locale)
      },
      {
        label: 'Impulskraft Gesamt',
        value: formatNumber(data.impuls_weight_full, this.locale)
      },
      {
        label: 'Ø Impulskraft pro Satz',
        value: formatNumber(data.impuls_weight_sentence, this.locale)
      },
    ];
  }

  private setDetailsHeight() {
    window.setTimeout(() => {
      if (this.detailed) {
        const todoElem = document.getElementById('text-numbers-details-inner');
        const height = todoElem.offsetHeight;
        this.detailsHeight = height + 'px';
      } else {
        this.detailsHeight = '0px';
      }
    }, 20);
  }

  copyToClipboard() {
    const copyElem = document.getElementById('text-number-copy-to-clipboard') as HTMLInputElement;
    let copyText = '';
    for (const textNumber of this.numbers) {
      if (textNumber.name && textNumber.name == 'perspective') {
        copyText += formatNumber(this.data.we_percentage, this.locale, '1.0-1') + '%' + '\n';
        copyText += formatNumber(this.data.you_percentage, this.locale, '1.0-1') + '%' + '\n';
      } else {
        copyText += textNumber.value.replace(/\s/g, '') + '\n';
      }
    }
    const indexNumbers: any = {};
    for (const index of this.currentText.lastResult.indices) {
      indexNumbers[index.name] = { score: index.score, percent: index.percent };
    }
    copyText += '\n' + formatNumber(this.currentText.lastResult.tli, this.locale, '1.2-2') + '\n';
    copyText += formatNumber(Math.round(indexNumbers.comprend.score), this.locale, '1.0') + '\n';
    copyText += formatNumber(Math.round(indexNumbers.abstract.score), this.locale, '1.0') + '\n';
    copyText += formatNumber(Math.round(this.currentText.lastResult.additionalData.humanInterest.score), this.locale, '1.0') + '\n';
    copyText += formatNumber(Math.round(indexNumbers.activation.score), this.locale, '1.0') + '\n';
    copyText += formatNumber(Math.round(indexNumbers.comprend.percent), this.locale, '1.0') + '%\n';
    copyText += formatNumber(Math.round(indexNumbers.abstract.percent), this.locale, '1.0') + '%\n';
    copyText += formatNumber(Math.round(this.currentText.lastResult.additionalData.humanInterest.percent), this.locale, '1.0') + '%\n';
    copyText += formatNumber(Math.round((15 - Math.abs(indexNumbers.activation.score - 15)) / 15 * 100), this.locale, '1.0') + '%\n';

    copyElem.innerHTML = copyText;
    copyElem.select();
    copyElem.setSelectionRange(0, 99999);
    document.execCommand('copy');
  }

}
