import { Injectable, EventEmitter } from '@angular/core';
import { AnalysisRequest, AnalysisResult, BenchmarkOptions, HistoryEntry, EditorMarking, EditorMarkings, RequestTextBlock, ScoreItem } from '../TeoDefinitions';
import { JsonApiService } from './json-api.service';
import { AnalysisIndexComponent } from '../view-components/analysis-main/analysis-item/analysis-index/analysis-index.component';
import { AppStateService } from './app-state.service';
import { environment } from 'src/environments/environment';
import { OfficeEditor } from '../office/office-editor';
import { StorageService } from './storage.service';
import { DialogAction } from '../general-components/dialog-content/dialog-content.component';
// import { TeoAPIService } from './teo-api.service';

@Injectable({
  providedIn: 'root'
})
export class CurrentTextService {
  statisticSession: string;

  textAlias: string = null;

  reportId: string;

  // currentHtml: string;
  currentEditorContent: string;

  textAudience: any = null;
  textStrategy: any;
  textMedium: any;

  isInformal: boolean = false;

  benchmarkOptions: BenchmarkOptions = {
    'target-audience': [],
    'strat-direction': [],
    'text-medium': []
  };

  _textModified: boolean;

  analysisPending = false;

  lastResult: AnalysisResult;
  scoreResults: object;

  tliBenchmark: number;

  wordNum: number;

  activeMarkings: string[];

  activeScanners: string[] = [];

  // is triggered when this.activeMarkings gets updated and the markings in the editor are supposed to change
  markings = new EventEmitter();

  beforeAnalysis = new EventEmitter();

  // true if actually got results, false if results just got reset
  gotResults: EventEmitter<boolean> = new EventEmitter();
  textReset = new EventEmitter();

  gotScannerResults: EventEmitter<any> = new EventEmitter();

  editorContentUpdate: EventEmitter<string> = new EventEmitter();

  // is triggered when we get new potential markings
  gotMarkings: EventEmitter<EditorMarkings> = new EventEmitter();

  // is triggered when potential markings are removed. Contains the "wrapperClass" of those markings
  removedMarkings: EventEmitter<string> = new EventEmitter();

  analysisCounter = 0;

  clickClasses: string[] = [];
  textClick = new EventEmitter();

  useWordLists = false;

  wrapColors = {};

  initialized = false;

  environment = environment;

  history = [];
  currentHistoryEntry: HistoryEntry = null;

  editor: OfficeEditor;

  private storageKey = 'current-text';

  constructor(
    private api: JsonApiService,
    private appState: AppStateService,
    private storage: StorageService
  ) {

    this.loadStoredAttributes();

    this.reset();
    appState.login.subscribe(() => {
      this.api.getWrapColors((colors: object) => {
        this.wrapColors = colors;
      }, (e: Error) => {
        console.error('could not get wrap colors from server');
        this.wrapColors = {};
      });

      this.api.getConfigOptions((options: BenchmarkOptions) => {
        this.benchmarkOptions = options;
        if (this.textAudience === null) {
          this.resetAnalysisConfig();
        } else {
          // if there is a config already (from session storage), reinit it, to make the selection screen work
          this.benchmarkOptions['target-audience'].forEach(element => {

            if (element.id == this.textAudience.id) {
              this.textAudience = element;
            }
          });
          this.benchmarkOptions['strat-direction'].forEach(element => {
            if (element.id == this.textStrategy.id) {
              this.textStrategy = element;
            }
          });
          this.benchmarkOptions['text-medium'].forEach(element => {
            if (element.id == this.textMedium.id) {
              this.textMedium = element;
            }
          });
        }
      }, (e: Error) => {
        console.error('could not get config options from server');
        // this.wrapColors = {};
      });
    });

    this.appState.logout.subscribe(() => {
      this.reset();
    });

    if (environment.editor === 'office') {
      this.editor = new OfficeEditor(this);
      this.editor
        .init()
        .then(() => {
          console.info('Office Editor initialized');
        })
        .catch((err: unknown) => {
          console.error('Office Editor failed to initialize.', err);
        });
    }
  }

  get textModified() {
    return this._textModified || environment.editor === 'office'
  }

  set textModified(modified: boolean) {
    this._textModified = modified
  }

  public reset(soft = false, keepText = false, keepConfig = false) {
    this.textReset.emit(soft);
    this.lastResult = {
      tli: 0,
      tliBenchmark: 0,
      tpi: {
        value: 0,
        benchmark: 10,
      },
      wrapped: '',
      wrappers: [],
      indices: [],
      additionalData: {},
      wordCloud: [],
      textNumbers: []
    };
    this.scoreResults = {};
    this.removeMarkings();
    this.clickClasses = [];
    this.analysisCounter = 0;
    this.tliBenchmark = 0;
    if (soft) {
      this.textModified = true;
    } else {
      this.history = [];
      if (!keepText) {
        this.updateEditorContent('');
        this.wordNum = 0;
        this.textModified = false;
      } else {
        this.textModified = true;
      }
      if (!keepConfig) {
        this.resetAnalysisConfig();
      }
      this.statisticSession = null;
      // this.storage.setProperty(this.storageKey, 'statisticSession', this.statisticSession);
      this.textAlias = null;
    }
    this.gotResults.emit(false);
  }

  public prepareMarkings(markings, wrapperClass) {
    this.gotMarkings.emit({ markings, wrapperClass });
  }

  public unPrepareMarkings(wrapperClass) {
    this.removedMarkings.emit(wrapperClass);
  }

  public setMarkings(markers: string[]) {
    this.activeMarkings = markers;
    this.markings.emit(true);
  }

  public addMarking(markerName: string) {
    this.activeMarkings.push(markerName);
    this.markings.emit();
  }

  public removeMarking(markerName: string) {
    this.activeMarkings = this.activeMarkings.filter(name => name !== markerName);
    this.markings.emit();
  }

  public removeMarkings() {
    this.activeMarkings = [];
    this.markings.emit(true);
  }

  public updateEditorContent(string: string) {
    // this.currentHtml = string;
    this.currentEditorContent = string;
    this.storage.setProperty(this.storageKey, 'currentEditorContent', this.currentEditorContent);
    this.editorContentUpdate.emit(string);
  }

  async analyze(successCB: (text: string) => void): Promise<void> {
    if (this.analysisPending === true) {
      return;
    }
    this.appState.queForLoading(async (finished) => {
      this.beforeAnalysis.emit();
      this.unPrepareMarkings('main-wrapper');
      this.unPrepareMarkings('secondary-wrapper');
      this.analysisPending = true;

      const request = new AnalysisRequest();

      request.text = this.currentEditorContent;
      request.audience = this.textAudience.id;
      request.strategy = this.textStrategy.id;
      request.medium = this.textMedium.id;
      request.evaluation_profile = this.benchmarkOptions['profile-id'];
      request.statistic_session = this.statisticSession;
      request.text_alias = this.textAlias;

      if (this.isInformal !== null) {
        request.is_informal = this.isInformal;
      }

      if (this.activeScanners.length) {
        request.scanners = this.activeScanners;
      }

      if (environment.editor === 'office') {
        await this.editor.prepareRequest(request);
      }

      this.api.analyzeText(
        request,
        (result) => {
          this.lastResult = result;

          this.scoreResults = {};

          this.tliBenchmark = result.tliBenchmark;

          this.wordNum = this.lastResult.textNumbers['word_num'];

          this.analysisCounter++;

          this.analysisPending = false;

          this.statisticSession = result.statisticSession;
          // this.storage.setProperty(this.storageKey, 'statisticSession', this.statisticSession);

          for (const index of this.lastResult.indices) {
            this.scoreResults[index.name] = index;
          }

          this.reportId = result.reportId;

          this.createHistoryEntry();

          this.gotResults.emit(true);
          let wrappers = this.lastResult.wrappers;
          if (result.scanners) {
            for (let name in result.scanners) {
              if (result.scanners[name].wrappers) {
                wrappers = wrappers.concat(result.scanners[name].wrappers);
              }
            }
            this.gotScannerResults.emit(this.lastResult.scanners);
          }
          this.prepareMarkings(wrappers, 'main-wrapper');
          // this.gotMarkings.emit({
          //   markings: this.lastResult.wrappers,
          //   wrapperClass: 'base-wrapper'
          // });
          finished();
          successCB('sdf');
        }, (error) => {
          this.analysisPending = false;
          finished();
        });
    });
  }

  public getMainIndex() {
    if (environment.mainIndexName == "TLI") {
      return this.lastResult.tli;
    } else {
      return this.lastResult.tpi.value;
    }
  }

  public getMainIndexBenchmark() {
    if (environment.mainIndexName == "TLI") {
      return this.lastResult.tliBenchmark;
    } else {
      return this.lastResult.tpi.benchmark;
    }
  }

  public getMainIndexPercentage() {
    const bench = this.getMainIndexBenchmark();
    const score = this.getMainIndex();
    let value = 0;
    if (bench > 0) {
      value = score / bench * 100;
    } else {
      value = 0;
    }
    if (value > 100) {
      value = 100;
    }
    return value;
  }

  public updateActiveScanners(scoreItems: ScoreItem[]) {
    this.activeScanners = [];
    if (this.useWordLists) {
      this.activeScanners.push('word-lists');
    }
    scoreItems.forEach(item => {
      if (item.scannerName) {
        this.activeScanners.push(item.scannerName);
      }
    });
    this.requestScanners();
  }

  public storeConfig() {
    this.storage.setProperty(this.storageKey, 'textAudience', this.textAudience);
    this.storage.setProperty(this.storageKey, 'textStrategy', this.textStrategy);
    this.storage.setProperty(this.storageKey, 'textMedium', this.textMedium);
    this.storage.setProperty(this.storageKey, 'isInformal', this.isInformal);
  }

  public loadStoredAttributes(attr: string[] = []) {
    const storedData = this.storage.retrieve(this.storageKey);
    for (const property in storedData) {
      if (!attr.length || attr.includes(property)) {
        this[property] = storedData[property];
      }
    }
  }

  public showTextChangeMessage() {
    this.appState.showMessage(
      'Der Text hat sich umfassend geändert. Handelt es sich hierbei noch um das gleiche Thema?',
      'dialog',
      [
        new DialogAction(
          'Nein: neue Analyse starten',
          () => {
            this.reset(false, true, true);
            this.appState.setView('analysis-configuration');
          }
        ),
        new DialogAction(
          'Ja: mit Analyse fortfahren',
          () => { }
        ),
      ],
      {
        title: 'Warum sehe ich diesen Hinweis?',
        text: "1.) TEO unterstützt Sie dabei, Fehler in der Text-Analyse zu vermeiden. Wann solche Fehler auftreten? Wenn Sie verschiedene Texte mit derselben Analyse-Konfiguration analysieren. Oft stimmen dann die Parameter, die Sie initial gesetzt haben, nicht mehr mit dem aktuellen Text überein."
          + (environment.mainIndexName == "TPI" ? " In diesem Fall wird der TPI nicht korrekt für Ihren aktuellen Text berechnet.<br /><br />" : "<br /><br />")
          + "2.) Konfigurieren Sie neue Texte auch deshalb immer neu, weil Sie nur dann ein korrektes Monitoring erhalten. So teilen Sie TEO mit, dass Sie einen komplett neuen Text analysieren. Damit helfen Sie, Ihre statistischen Daten korrekt zu erfassen."
      }
    );
  }

  async requestScanners() {
    this.appState.queForLoading(async (finished) => {
      if (this.activeScanners.length) {
        this.unPrepareMarkings('secondary-wrapper');
        // setTimeout()
        const request = new AnalysisRequest();
        request.text = this.currentEditorContent;

        if (environment.editor === 'office') {
          await this.editor.prepareRequest(request);
        }
        this.api.postMultiScanRequest(this.activeScanners, request, (result) => {
          let wrappers = [];
          for (let name in result) {
            if (result[name].wrappers) {
              wrappers = wrappers.concat(result[name].wrappers);
            }
          }
          this.prepareMarkings(wrappers, 'secondary-wrapper');
          this.gotScannerResults.emit(result);
          finished();
        },
          () => {
            finished();
          });

      } else {
        finished();
      }
    })
  }

  private resetAnalysisConfig() {
    this.textAudience = null;
    this.textStrategy = null;
    this.textMedium = null;

    this.benchmarkOptions['target-audience'].forEach(option => {
      if (option.state == 'SELECTED') {
        this.textAudience = option;
      }
    });
    this.benchmarkOptions['strat-direction'].forEach(option => {
      if (option.state == 'SELECTED') {
        this.textStrategy = option;
      }
    });
    this.benchmarkOptions['text-medium'].forEach(option => {
      if (option.state == 'SELECTED') {
        this.textMedium = option;
      }
    });
  }

  private createHistoryEntry() {
    // const mainIndex = this.getMainIndex();
    // const mainIndexBench = this.getMainIndexBenchmark();
    const mainIndexPercent = this.getMainIndexPercentage();
    let color = 'green';
    if (mainIndexPercent < 100) {
      color = mainIndexPercent < 50 ? 'red' : 'yellow';
    }
    this.currentHistoryEntry = {
      audience: this.textAudience.id,
      strategy: this.textStrategy.id,
      medium: this.textMedium.id,
      is_informal: this.isInformal,
      text: this.currentEditorContent,
      text_alias: this.textAlias,
      // main_index: mainIndex,
      // main_index_bench: mainIndexBench,
      main_index_percentage: mainIndexPercent,
      result: this.lastResult,
      "time": new Date(),
      minutes_ago: 0,
      color
    };
    this.history.push(this.currentHistoryEntry);
  }
}
