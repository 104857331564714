<mat-card class="index-card" [class.detailed]="detailed">
  <mat-icon class="top-left-info-icon" svgIcon="info" appInfoButton="power-words" infoTitle="Power-Wörter">
  </mat-icon>
  <mat-progress-bar class="card-loading-bar" [style.opacity]="loading ? 1 : 0" mode="indeterminate" color="accent">
  </mat-progress-bar>
  <div class="result-panel-wrap" (click)="itemClick();">
    <div class="result-panel-content">
      <div class="score-text">
        Power-Wörter: <b>{{ wordNumber }} ({{ wordsPercent }} %)</b>
      </div>
      <app-analysis-item-scale [indexValue]="wordsPercent" [indexRawValue]="scaleScore" indexType="default"
        leftMarking="0" centerMarking="" [rightMarking]="scaleMarking" [benchmark]="scaleBenchmark"
        [inactive]="scaleInactive">
      </app-analysis-item-scale>
    </div>
  </div>
  <div class="score-details" [style.height]="detailsHeight">

    <div id="power-words-details-inner" class="score-details-inner">

      <div *ngIf="detailed" class="score-details-head">
        <!-- <a href="#" [class.active]="marked" (click)="mark()">Markieren</a><br /> -->
        <a href="#" (click)="showList()">Übersicht Power-Wörter</a>
      </div>
      <!-- <div *ngIf="detailed" class="score-details-head">
        <a href="#" (click)="scan()">neu prüfen</a>


        <div style="padding-top: 25px;">
          Anzahl: <div class="current-word">
            {{ wordNumber }} ({{ wordsPercent }} %)
          </div>
        </div>
      </div> -->
      <!-- <div *ngIf="detailed && !noViolations">
      </div> -->
    </div>
  </div>
</mat-card>
