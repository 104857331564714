import { Component, OnInit } from '@angular/core';
import { AppStateService } from 'src/app/services/app-state.service';

@Component({
  selector: 'app-brand-fit-button',
  templateUrl: './brand-fit-button.component.html',
  styleUrls: ['./brand-fit-button.component.scss']
})
export class BrandFitButtonComponent implements OnInit {

  constructor(
    public appState: AppStateService
  ) { }

  ngOnInit(): void {
  }

  onClick() {
    if (this.appState.brandFit !== null) {
      this.appState.brandFitOpen.emit();
    }
  }
}
