<div class="medium-container">
  <div class="page-header">
    <h1>Neuigkeiten für Sie</h1>
    <div class="info-text">Gleich nachsehen, was TEO V2 Neues zu bieten hat.</div>
  </div>
  <div id="changelog-content" class="layout-row">
    <mat-card *ngFor="let logEntry of changelog" class="changelog-entry">
      <div class="changelog-entry-title">{{ logEntry.title }}</div>
      <div [innerHtml]="logEntry.description"></div>
    </mat-card>
  </div>
  <div id="changelog-actions" class="">
    <a href="#" title="weiter zur Analyse-Konfiguration" (click)="continue()" class="changelog-continue">weiter zur Analyse-Konfiguration</a>
    <span class="changelog-link-wrap">
      Mehr Informationen auf tcl.digital:<br />
      <a [href]="changelogLink" title="zum Changelog" target="_blank">{{ changelogLink }}
        <mat-icon class="" svgIcon="open_in_new">
        </mat-icon>
      </a>
    </span>
  </div>
</div>
