import { WordListInfoComponent } from './../../../../pinboard/info-item/word-list-info/word-list-info.component';
import { JsonApiService } from 'src/app/services/json-api.service';
import { Component, OnInit, Input } from '@angular/core';
import { AnalysisItemContent } from '../analysis-item-content-interface';
import { CurrentTextService } from 'src/app/services/current-text.service';
import { AppStateService } from 'src/app/services/app-state.service';
import { AccordionInfoComponent } from 'src/app/pinboard/info-item/accordion-info/accordion-info.component';

@Component({
  selector: 'app-causality-scanner',
  templateUrl: './causalityScanner.component.html',
  styleUrls: ['../analysis-item-shared.scss', './causalityScanner.component.scss']
})
export class CausalityScannerComponent implements OnInit, AnalysisItemContent {
  data: any;
  itemName: string;
  @Input() inactive: boolean;

  detailed = false;

  detailsHeight = '0px;';

  connectorsList: string[];

  marked = false;

  // markingsJustChanged = false;

  markingsSubscription = null;

  number = 0;

  brandFit = true;

  constructor(
    private currentText: CurrentTextService,
    private appState: AppStateService,
    private api: JsonApiService
  ) {
    this.currentText.gotMarkings.subscribe((markings) => {
      this.number = 0;
      if(this.currentText.lastResult.additionalData.causalConn){
        this.number = this.currentText.lastResult.additionalData.causalConn.score;
      }
      // markings.markings.forEach(marking => {
      //   console.log(marking);
      //   if (marking.marker == 'causal_conn') {
      //     console.log('count!!!!')
      //     this.number += 1;
      //   }
      // });
      // console.log(this.number)
      // this.checkBrandFit();
      this.setDetailsHeight();
    });
    // this.currentText.markings.subscribe((reset: boolean) => {
    //   if (reset) {
    //     if (this.markingsJustChanged) {
    //       this.markingsJustChanged = false;
    //     } else {
    //       this.marked = false;
    //       window.setTimeout(() => {
    //         this.setDetailsHeight();
    //       }, 50);
    //     }
    //   }
    // });
  }

  ngOnInit() {
  }

  // mark() {
  //   if (this.marked) {
  //     this.currentText.removeMarkings();
  //     this.marked = false;
  //   } else {
  //     this.markingsJustChanged = true;
  //     this.currentText.setMarkings(['wrap_causal_conn']);
  //     this.marked = true;
  //   }
  // }

  checkBrandFit() {
    this.brandFit = true;
    if (this.number < 1 && this.currentText.analysisCounter > 0) {
      this.brandFit = false;
    }
    // this.appState.updateBrandFit('causality-scanner', this.brandFit);
  }

  showList() {
    this.appState.openInfoItem(
      WordListInfoComponent,
      'causal_connector_list',
      'Übersicht: Kausal-Adverbien',
      this.connectorsList
    );
  }

  close(removeMarkings = true) {
    this.detailed = false;
    if (this.markingsSubscription !== null) {
      this.markingsSubscription.unsubscribe();
    }
    if (removeMarkings) {
      this.currentText.removeMarkings();
      this.marked = false;
    }
    this.setDetailsHeight();
  }

  open() {
    this.currentText.setMarkings(['wrap_causal_conn']);
    this.marked = true;
    this.markingsSubscription = this.currentText.markings.subscribe((reset: boolean) => {
      if (reset && this.detailed) {

        if (!this.currentText.activeMarkings.includes('wrap_causal_conn')) {
          this.close(false);
        }
      }
    });

    this.detailed = true;
    this.setDetailsHeight();
    // this.currentText.removeMarkings();
    if (!this.connectorsList) {
      this.api.getCausalConnectors((list) => {
        this.connectorsList = list;
      });
    }
  }

  itemClick() {
    if (this.detailed) {
      this.close();
    } else {
      this.open();
    }
  }

  private setDetailsHeight() {
    window.setTimeout(() => {
      if (this.detailed) {
        const todoElem = document.getElementById('causality-scanner-details-inner');
        const height = todoElem.offsetHeight;
        this.detailsHeight = height + 'px';
      } else {
        this.detailsHeight = '0px';
      }
    }, 20);
  }

}
