<mat-card class="index-card" [class.detailed]="detailed">
  <mat-icon class="top-left-info-icon" svgIcon="info" [appInfoButton]="itemName" infoTitle="Rechtschreibprüfung">
  </mat-icon>
  <mat-progress-bar class="card-loading-bar" [style.opacity]="loading ? 1 : 0" mode="indeterminate" color="accent">
  </mat-progress-bar>
  <div class="result-panel-wrap" (click)="itemClick();">
    <div class="result-panel-content">
      <div>
        <b>Rechtschreibprüfung</b>
      </div>
    </div>
  </div>
  <div class="score-details" [style.height]="detailsHeight">
    <div id="lang-tool-details-inner" class="score-details-inner">
      <div *ngIf="detailed" class="score-details-head">
        <!-- Fehler: <div class="current-word">
          {{ matches.length }}
        </div> -->
        <a href="#" (click)="test()"><span *ngIf="matches.length || nothingFound">neu </span>prüfen</a>
        <div *ngIf="currentMatchId === null">
          <div *ngIf="matches.length || nothingFound" class="" style="padding-top: 25px;">
            <span *ngIf="!nothingFound">Bitte wählen Sie ein markiertes Wort aus.</span>
            <span *ngIf="nothingFound">Keine Fehler gefunden.</span>
          </div>
        </div>
      </div>
      <div *ngIf="detailed && currentMatchId !== null">

        <div class="score-details-inner-text">{{ matches[currentMatchId].message }}</div>
        <div *ngIf="matches[currentMatchId].replacements.length">
          <h4>Ersetzen</h4>
          <div class="lang-tool-replacements">
            <button mat-stroked-button *ngFor="let replacement of matches[currentMatchId].replacements | slice:0:5; let i = index" (click)="replace(i)" [class.active]="currentReplacementId === i">
              {{ replacement.value }}
            </button>
          </div>
          <div class="keyword-actions">
            <a href="#" (click)="resetReplacement()">Zurücksetzen</a>
          </div>
        </div>
      </div>
      <div *ngIf="detailed" class="score-details-head">
        <span class="small-text">Diese Funktion verwendet die Software des Drittanbieters "LanguageTool". <a href="#"
            (click)="openLangtoolInfo()">Copyright Info</a></span>
      </div>
    </div>
  </div>
</mat-card>
