<mat-card class="index-card" [class.detailed]="detailed">
    <mat-icon class="top-left-info-icon" svgIcon="info" appInfoButton="keyword-scanner" infoTitle="Keyword-Scanner">
    </mat-icon>
    <div class="result-panel-wrap" (click)="itemClick();">
        <div class="result-panel-content">
            <div>
                <b>Keyword-Scanner</b>
            </div>
        </div>
    </div>
    <div class="score-details" [style.height]="detailsHeight" [class.no-keywords]="!keywords.length">
        <div id="keyword-scanner-details-inner">
            <form (ngSubmit)="addWordFromInput()">
                <mat-form-field>
                    <input matInput name="keyword" placeholder="Keyword eingeben" [(ngModel)]="inputContent">
                </mat-form-field>
            </form>
            <div class="score-details-inner" *ngIf="keywords.length">
                <h4>Ergebnis</h4>
                <div>
                    <label *ngFor="let keyword of keywords;" class="score-todo" [class.active]="keyword.selected">
                        <div class="score-todo-title">{{ keyword.word }}</div>
                        <div class="score-todo-numbers">{{ keyword.number }} ({{ keyword.percent | percent }})</div>
                        <mat-checkbox [(ngModel)]="keyword.selected" (ngModelChange)="setMarker(keyword)">
                        </mat-checkbox>
                    </label>
                </div>
                <div class="keyword-actions">
                    <a href="#" (click)="selectAll()">Alle Markieren</a>
                    <a href="#" (click)="deleteKeywords()">Löschen</a>
                </div>
                <div class="keyword-infos">
                    <div class="keyword-info">Anzahl Wörter: {{ analysisWordCount? analysisWordCount : wordCount }}
                    </div>
                    <div class="keyword-info">Anzahl Keywords: {{ keywordCount }}</div>
                    <div class="keyword-info">Gesamtanteil Keywords: {{ keywordPercentage | percent }}</div>
                </div>
            </div>
        </div>
    </div>
</mat-card>
