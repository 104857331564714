import { AppStateService } from 'src/app/services/app-state.service';
import {
  Component,
  OnInit,
  ComponentFactoryResolver,
  ApplicationRef,
  Injector,
  Type,
  EmbeddedViewRef,
  ComponentRef,
  ElementRef,
  ChangeDetectorRef,
  ViewChild,
  EventEmitter
} from '@angular/core';
import { InfoItemComponent } from './info-item/info-item.component';
import { DomSanitizer } from '@angular/platform-browser';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { PinboardContentDirective } from './pinboard-content.directive';
import { element } from 'protractor';

@Component({
  selector: 'app-pinboard',
  templateUrl: './pinboard.component.html',
  styleUrls: ['./pinboard.component.scss']
})
export class PinboardComponent implements OnInit {

  @ViewChild(PinboardContentDirective) insertionPoint: PinboardContentDirective;

  freeInfoZIndex: number = 10;

  defaultDragPosition = { x: 0, y: 0 };

  infoItemNames: string[] = [];

  boardElements: any[] = [];

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector,
    private elementRef: ElementRef,
    private appState: AppStateService,
    public cd: ChangeDetectorRef,
    protected sanitizer: DomSanitizer
  ) {
    appState.pinboard = this;

    const left = window.innerWidth / 2 - 210;
    const top = window.innerHeight / 2 - 200;
    this.defaultDragPosition = { x: left, y: top };
  }

  ngOnInit() {
  }

  public isPinned(name: string) {
    if (this.infoItemNames.includes(name)) {
      return true;
    }
    return false;
  }

  public pinInfoItem(componentType: Type<any>, name: string, title: string, data: any) {
    if (!this.infoItemNames.includes(name)) {
      this.infoItemNames.push(name);
      const elementData = {
        componentType,
        name,
        title,
        data,
        parent: this,
        id: null,
        zIndex: 1,
        dragPosition: { x: this.defaultDragPosition.x, y: this.defaultDragPosition.y },
      };

      const id = this.boardElements.push(elementData) - 1;
      this.boardElements[id].id = id;
      this.checkPinboardDisplay();
      this.appState.pinboardChange.emit();
      return id;


    } else {
      // @todo: return id of already existin item
      return null;
    }
  }

  public removeAllInfoItems() {
    this.boardElements = [];
    this.infoItemNames = [];
    this.checkPinboardDisplay();
    this.appState.pinboardChange.emit();
  }

  public removeInfoItem(id: number) {
    this.boardElements = this.boardElements.filter((value) => {
      if (value.id === id) {
        this.infoItemNames = this.removeFromArray(this.infoItemNames, value.name);
        return false;
      }
      return true;
    });
    this.checkPinboardDisplay();
    this.appState.pinboardChange.emit();
  }

  public checkPinboardDisplay() {
    if (this.boardElements.length === 0) {
    } else {
      this.appState.openSideBar('pinboard');
    }
  }

  public focusInfoItem(id: number) {
  }

  boardDrop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.boardElements, event.previousIndex, event.currentIndex);
  }

  private removeFromArray(array: any[], elem: any) {
    return array.filter((value) => {
      return elem !== value;
    });
  }
}
