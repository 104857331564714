import { HttpClient } from '@angular/common/http';
import { Component, OnInit, Input, EventEmitter } from '@angular/core';
import { AccordionInfoComponent } from 'src/app/pinboard/info-item/accordion-info/accordion-info.component';
import { InfoItemComponent } from 'src/app/pinboard/info-item/info-item.component';
import { AppStateService } from 'src/app/services/app-state.service';
import { CurrentTextService } from 'src/app/services/current-text.service';
import { AnalysisRequest, RequestTextBlock } from 'src/app/TeoDefinitions';
import { AnalysisItemContent } from '../analysis-item-content-interface';
import { LanguageToolApiService } from './language-tool-api';

@Component({
  selector: 'app-language-tool',
  templateUrl: './language-tool.component.html',
  styleUrls: ['../analysis-item-shared.scss', './language-tool.component.scss']
})
export class LanguageToolComponent implements OnInit, AnalysisItemContent {
  data: any;
  itemName: string;
  @Input() inactive: boolean;
  @Input() visibilityChange: EventEmitter<boolean>;

  detailed = false;

  textClickSubscription = null;

  loading = false;

  detailsHeight = '0px;';
  markings = [
    'wrap_lang_tool_GRAMMAR',
    'wrap_lang_tool_TYPOS',
    'wrap_lang_tool_COMPOUNDING',
    'wrap_lang_tool_SEMANTICS',
    'wrap_lang_tool_HILFESTELLUNG_KOMMASETZUNG',
    'wrap_lang_tool_MISC',
    'wrap_lang_tool_CASING'
  ];

  matches = [];
  editorMarkings = [];

  currentMatchId = null;
  currentReplacementId = null;

  nothingFound = false;

  markingsSubscription = null;

  constructor(
    private currentText: CurrentTextService,
    private appState: AppStateService,
    private api: LanguageToolApiService
  ) {
    this.currentText.gotResults.subscribe(() => {
      window.setTimeout(() => {
        this.setDetailsHeight();
      }, 50);
    });

    this.currentText.markings.subscribe(() => {
    });
  }

  ngOnInit() {
    this.visibilityChange.subscribe((hidden) => {
      if (hidden === false) {
        // automaticly open card when category is selected
        setTimeout(() => {
          this.open();
        }, 100);
      } else {
        this.close();
      }
    });
  }

  test(callback) {
    if (!this.loading) {
      this.currentMatchId = null;
      this.loading = true;
      this.currentText.unPrepareMarkings('lang-tool-wrapper');
      setTimeout(() => {  // wait for wrappers to be removed
        const anaRequest = new AnalysisRequest();
        anaRequest.text = this.currentText.currentEditorContent;
        this.api.checkTextBlocks(anaRequest.prepareHTMLText(), (data) => {
          this.matches = [];
          this.editorMarkings = [];
          for (let i = 0; i < data.matches.length; i++) {
            const match = data.matches[i];
            match.replaced = false;
            match.currentReplacementId = null;
            match.id = i;
            this.matches.push(match);
            this.editorMarkings.push({
              marker: 'lang_tool_' + match.rule.category.id,
              offset: match.offset,
              length: match.length,
              relation: i
            });
          }
          this.nothingFound = this.matches.length ? false : true;
          this.setDetailsHeight();
          this.currentText.prepareMarkings(this.editorMarkings, 'lang-tool-wrapper');
          setTimeout(() => {
            this.currentText.setMarkings(this.markings);
            this.loading = false;
            callback(true);
          }, 50); // wait for wrappers to be inserted
        }, (error) => {
          console.log('Language Tool Error: ', error);
          this.appState.showMessage('Die Rechtschreibprüfung scheint nicht korrekt konfiguriert zu sein. Bitte wenden Sie sich an den Support.')
          this.loading = false;
          callback(false);
        });
      }, 50);
    } else {
      this.loading = false;
      callback(false);
    }
  }

  applyReplacement() {
    const editorNode = document.getElementById('editor-text');
    const itemNodes = editorNode.getElementsByClassName('relation_' + this.currentMatchId);
    const setOriginalContent = this.matches[this.currentMatchId].content === undefined;

    if (this.matches[this.currentMatchId].replaced) {
      if (setOriginalContent) {
        this.matches[this.currentMatchId].content = [itemNodes[0].innerHTML];
      }
      itemNodes[0].innerHTML = this.matches[this.currentMatchId].replacements[this.currentReplacementId].value;
      itemNodes[0].classList.add('wrapper-corrected');
      this.matches[this.currentMatchId].currentReplacementId = this.currentReplacementId;
    } else {
      itemNodes[0].innerHTML = this.matches[this.currentMatchId].content[0];
      itemNodes[0].classList.remove('wrapper-corrected');
    }
    for (var i = 1; i < itemNodes.length; i++) {
      if (this.matches[this.currentMatchId].replaced) {
        if (setOriginalContent) {
          this.matches[this.currentMatchId].content.push(itemNodes[i].innerHTML);
        }
        itemNodes[i].innerHTML = '';
      } else {
        itemNodes[i].innerHTML = this.matches[this.currentMatchId].content[i];
        // itemNodes[i].classList.remove('wrapper-corrected');
      }
    }

    const event = new Event('input');
    event.initEvent('input', true, true);
    editorNode.dispatchEvent(event);
  }

  replace(replacementId = null) {
    if (replacementId !== null) {
      this.currentReplacementId = replacementId;
    }
    this.matches[this.currentMatchId].replaced = true;
    this.applyReplacement();
  }

  resetReplacement() {
    this.currentReplacementId = null;
    this.matches[this.currentMatchId].replaced = false;
    this.applyReplacement();
  }

  close(removeMarkings = true) {
    this.detailed = false;
    this.currentMatchId = null;
    this.matches = [];
    this.editorMarkings = [];
    this.currentText.unPrepareMarkings('lang-tool-wrapper');
    if (this.textClickSubscription !== null) {
      this.textClickSubscription.unsubscribe();
    }
    if (removeMarkings) {
      this.currentText.removeMarkings();
      this.currentText.clickClasses = [];
    }
    if (this.markingsSubscription !== null) {
      this.markingsSubscription.unsubscribe();
    }
    this.setDetailsHeight();
  }

  open() {
    this.detailed = true;
    this.currentText.clickClasses = this.markings;
    this.currentText.setMarkings(this.markings);
    this.textClickSubscription = this.currentText.textClick.subscribe((data) => {
      if (this.markings.includes(data.marker)) {
        data.relations.forEach(element => {
          // if (element.marker == 'language_tool') {
          this.currentMatchId = element.relation;
          this.currentReplacementId = this.matches[this.currentMatchId].currentReplacementId;
          // this.ctViolations[element.relation].replaced = !this.ctViolations[element.relation].replaced;

          // }
        });
      }
      this.setDetailsHeight();
    });
    this.appState.queForLoading((finished) => {
      this.test(finished)
    });

    this.markingsSubscription = this.currentText.markings.subscribe((reset: boolean) => {
      if (reset && this.detailed) {
        if (!this.currentText.activeMarkings.includes(this.markings[0])) {
          this.close(false);
        }
      }
    });

    this.setDetailsHeight();
  }

  itemClick() {
    if (this.detailed) {
      this.close();
    } else {
      this.open();
    }
  }

  openLangtoolInfo() {
    this.appState.openInfoItemCustomText(
      AccordionInfoComponent,
      'langtool-info',
      'Über LanguageTool',
      'TEO V2 verwendet für die Rechtschreib-Prüfung die Software und Wortlisten von "LanguageTool". Die Korrekturvorschläge sind Bestandteile der "LanguageTool"-Wortlisten. TEO V2 schickt keine Daten bzw. Texte an externe Systeme. Die LanguageTool-Wortlisten und Algorithmen werden physisch auf dem selben Server betrieben wie TEO V2.<br>"LanguageTool" ist eine eigenständige Software und kein Bestandteil von TEO V2.<br>"LanguageTool" steht unter der LGPL 2.1 Lizenz und wird von der "LanguageTooler GmbH" entwickelt. <br><br><a href="https://languagetool.org/" target="_blank">Website</a> (https://languagetool.org)<br><a href="https://github.com/languagetool-org/languagetool" target="_blank">Github</a> (https://github.com/languagetool-org/languagetool)'
    );
  }

  private setDetailsHeight() {
    window.setTimeout(() => {
      if (this.detailed) {
        const todoElem = document.getElementById('lang-tool-details-inner');
        const height = todoElem.offsetHeight;
        this.detailsHeight = height + 'px';
      } else {
        this.detailsHeight = '0px';
      }
    }, 50);
  }
}
