<mat-card class="index-card" [class.detailed]="detailed">
  <mat-icon class="top-left-info-icon" svgIcon="info" appInfoButton="dialog-scanner" infoTitle="Dialog-Scanner">
  </mat-icon>
  <div class="brand-fit-indicator" [class.active]="!brandFit"></div>
  <div class="result-panel-wrap" (click)="itemClick();">
    <div class="result-panel-content">
      <div>
        Dialog-Scanner: <b class="score-text-number">{{ number }}</b>
      </div>
    </div>
  </div>
  <div class="score-details" [style.height]="detailsHeight">
    <div id="dialog-scanner-details-inner" class="score-details-inner">
      <!-- <div *ngIf="detailed" class="score-details-head">
                <a href="#" [class.active]="marked" (click)="mark()">Markieren</a><br />
            </div> -->
    </div>
  </div>
</mat-card>
