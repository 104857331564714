import { WordListInfoComponent } from './../../../../pinboard/info-item/word-list-info/word-list-info.component';
import { JsonApiService } from 'src/app/services/json-api.service';
import { Component, OnInit, Input } from '@angular/core';
import { AnalysisItemContent } from '../analysis-item-content-interface';
import { CurrentTextService } from 'src/app/services/current-text.service';
import { AppStateService } from 'src/app/services/app-state.service';
import { AccordionInfoComponent } from 'src/app/pinboard/info-item/accordion-info/accordion-info.component';

@Component({
  selector: 'app-dialog-scanner',
  templateUrl: './dialogScanner.component.html',
  styleUrls: ['../analysis-item-shared.scss', './dialogScanner.component.scss']
})
export class DialogScannerComponent implements OnInit, AnalysisItemContent {
  data: any;
  itemName: string;
  @Input() inactive: boolean;

  detailed = false;

  detailsHeight = '0px;';

  marked = false;

  number = 0;

  brandFit = true;

  // markingsJustChanged = false;
  markingsSubscription = null;

  constructor(
    private currentText: CurrentTextService,
    private appState: AppStateService,
  ) {
    this.currentText.gotResults.subscribe(() => {
      this.number = 0;
      if (this.currentText.lastResult.additionalData.dialog) {
        this.number = this.currentText.lastResult.additionalData.dialog.score;
      }
      // this.checkBrandFit();
      this.setDetailsHeight();
    });
    // this.currentText.markings.subscribe((reset: boolean) => {
    //   if (reset) {
    //     if (this.markingsJustChanged) {
    //       this.markingsJustChanged = false;
    //     } else {
    //       this.marked = false;
    //       window.setTimeout(() => {
    //         this.setDetailsHeight();
    //       }, 50);
    //     }
    //   }
    // });
  }

  ngOnInit() {
  }

  checkBrandFit() {
    this.brandFit = true;
    if (this.number < 1 && this.currentText.analysisCounter > 0) {
      this.brandFit = false;
    }
    // this.appState.updateBrandFit('dialog-scanner', this.brandFit);
  }

  close(removeMarkings = true) {
    this.detailed = false;
    if (this.markingsSubscription !== null) {
      this.markingsSubscription.unsubscribe();
    }
    if (removeMarkings) {
      this.currentText.removeMarkings();
      this.marked = false;
    }
    this.setDetailsHeight();
  }

  open() {
    this.markingsSubscription = this.currentText.markings.subscribe((reset: boolean) => {
      if (reset && this.detailed) {

        if (
          !
          (this.currentText.activeMarkings.includes('wrap_dialog_phrase_part')
            || this.currentText.activeMarkings.includes('wrap_modal_particle'))
        ) {
          this.close(false);
        }
      }
    });

    this.currentText.setMarkings(['wrap_dialog_phrase_part', 'wrap_modal_particle']);
    this.marked = true;
    this.detailed = true;
    this.setDetailsHeight();
    // this.currentText.removeMarkings();
  }

  itemClick() {
    if (this.detailed) {
      this.close();
    } else {
      this.open();
    }
  }

  private setDetailsHeight() {
    window.setTimeout(() => {
      if (this.detailed) {
        const todoElem = document.getElementById('dialog-scanner-details-inner');
        const height = todoElem.offsetHeight;
        this.detailsHeight = height + 'px';
      } else {
        this.detailsHeight = '0px';
      }
    }, 20);
  }

}
