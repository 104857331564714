import { AfterViewInit, Component, Inject, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export class DialogAction {
  constructor(text, action) {
    this.text = text;
    this.action = action;
    this.id = Math.floor((Math.random() * 6) + 1);
  }
  text: string;
  action: CallableFunction;
  id: Number
}
@Component({
  selector: 'app-dialog-content',
  templateUrl: './dialog-content.component.html',
  styleUrls: ['./dialog-content.component.scss']
})

export class DialogContentComponent implements OnInit, AfterViewInit {
  actions: DialogAction[] = [];
  type = 'alert';
  notice: { title: string, text: string } = null;
  noticeHeight = '0px';

  @ViewChildren('actionButton') actionButtons;
  @ViewChild('noticeTextInner') noticeTextInner;

  constructor(
    public dialogRef: MatDialogRef<DialogContentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (data.actions) {
      this.actions = data.actions;
      this.actions.forEach(element => {
        element.id = Math.floor((Math.random() * 6) + 1);
      });
    }
    if (data.type) {
      this.type = data.type;
    }
    if (data.notice) {
      this.notice = data.notice;
    }
  }

  ngAfterViewInit(): void {
    // this.actionButtons.forEach((element, index) => {
    // if (index == this.actionButtons.length - 1) {
    // angular material automatically focuses the first button. we want the last button
    // setTimeout(() => {
    //   element.focus();
    // }, 300);
    // }
    // });
  }

  ngOnInit(): void {
  }

  toggleNotice() {
    if (this.noticeHeight == '0px') {
      this.noticeHeight = this.noticeTextInner.nativeElement.offsetHeight + 'px';
    } else {
      this.noticeHeight = '0px';
    }
    return false;
  }

  close() {
    this.dialogRef.close();
  }

  actionClick(index) {
    this.actions[index].action();
    this.close();
  }
}
