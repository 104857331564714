import { CommonModule } from '@angular/common';
import { Component, OnInit, Input } from '@angular/core';
import { AnalysisItemContent } from '../analysis-item-content-interface';
import { CurrentTextService } from 'src/app/services/current-text.service';
import { AccordionInfoComponent } from 'src/app/pinboard/info-item/accordion-info/accordion-info.component';
import { AppStateService } from 'src/app/services/app-state.service';

@Component({
  selector: 'app-anglicisms',
  templateUrl: './anglicisms.component.html',
  styleUrls: ['../analysis-item-shared.scss', './anglicisms.component.scss']
})
export class AnglicismsComponent implements OnInit, AnalysisItemContent {
  data: any;
  itemName: string;
  @Input() inactive: boolean;

  detailed = false;

  anglicisms: any;

  anglicismNumber = 0;

  currentAnglicism: string;

  currentTranslationId: number = null;

  textClickSubscription = null;

  markings = ['wrap_anglicism'];

  // currentReplacement: string;

  detailsHeight = '0px;';

  constructor(
    private currentText: CurrentTextService,
    private appState: AppStateService
  ) {
    this.currentText.gotResults.subscribe(() => {
      this.unselect(true);
      this.anglicisms = {};
      this.anglicismNumber = 0;

      const data = this.currentText.lastResult.additionalData.anglicisms;

      if (typeof data !== 'undefined') {
        for (const angli of data) {
          this.anglicisms[angli.name] = {
            word: angli.word,
            translations: angli.translations,
            replacement: angli.word,
            text: angli.text
          };
          this.anglicismNumber++;
        }
      }

      this.setDetailsHeight();
    });

    this.currentText.markings.subscribe(() => {
      if (
        (this.currentText.activeMarkings.length !== this.markings.length
          || this.currentText.activeMarkings[0] !== this.markings[0])
        && this.detailed
      ) {
        this.close(false);
      }
    });
  }

  ngOnInit() {
  }

  unselect(resetMarkings: boolean = false) {
    this.currentAnglicism = null;
    this.currentTranslationId = null;
    if (resetMarkings) {
      this.currentText.removeMarkings();
    }
  }

  resetReplacement() {
    this.currentTranslationId = null;
    this.useReplacement();
  }

  useReplacement() {
    const translations = this.anglicisms[this.currentAnglicism].translations;

    const editorNode = document.getElementById('editor-text');
    const itemNode = editorNode.getElementsByClassName('relation_' + this.currentAnglicism)[0];

    if (this.currentTranslationId !== null) {
      this.anglicisms[this.currentAnglicism].replacement = translations[this.currentTranslationId];
      itemNode.classList.add('wrapper-corrected');
    } else {
      this.anglicisms[this.currentAnglicism].replacement = this.anglicisms[this.currentAnglicism].word;
      itemNode.classList.remove('wrapper-corrected');
    }

    itemNode.innerHTML = this.anglicisms[this.currentAnglicism].replacement;

    const event = new Event('input');
    event.initEvent('input', true, true);
    editorNode.dispatchEvent(event);
  }

  close(removeMarkings = true) {
    this.detailed = false;
    this.currentAnglicism = null;
    if (this.textClickSubscription !== null) {
      this.textClickSubscription.unsubscribe();
    }
    if (removeMarkings) {
      this.currentText.removeMarkings();
      this.currentText.clickClasses = [];
    }
    this.setDetailsHeight();
  }

  open() {
    this.detailed = true;
    this.currentText.clickClasses = this.markings;
    this.currentText.setMarkings(this.markings);
    this.textClickSubscription = this.currentText.textClick.subscribe((data) => {
      this.onWordClick(data);
    });
    this.setDetailsHeight();
  }

  openTextEntry() {
    const name = 'thesaurus-' + this.anglicisms[this.currentAnglicism].word;
    const title = 'Lexikalischer Eintrag: ' + this.anglicisms[this.currentAnglicism].word;
    this.appState.openInfoItemCustomText(
      AccordionInfoComponent,
      name,
      title,
      this.anglicisms[this.currentAnglicism].text
    );
  }

  onWordClick(data) {
    if (data.marker === this.markings[0]) {
      data.relations.forEach(element => {
        if (element.marker == 'anglicism') {
          this.currentTranslationId = null;
          this.currentAnglicism = element.relation;

          // select the correct radio button for the translation
          this.anglicisms[this.currentAnglicism].translations.forEach((translation, index) => {
            if(data.target.innerHTML == translation){
              this.currentTranslationId = index;
            }
          });
          this.currentAnglicism = element.relation;
          this.setDetailsHeight();
        }
      });
    }
  }

  itemClick() {
    if (this.detailed) {
      this.close();
    } else {
      this.open();
    }
  }

  private setDetailsHeight() {
    window.setTimeout(() => {
      if (this.detailed) {
        const todoElem = document.getElementById('anglicisms-details-inner');
        const height = todoElem.offsetHeight;
        this.detailsHeight = height + 'px';
      } else {
        this.detailsHeight = '0px';
      }
    }, 20);
  }
}
