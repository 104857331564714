import { Component } from '@angular/core';
import { AppStateService } from './services/app-state.service';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  smallLayout = environment.editor == 'office';
  constructor(
    private domSanitizer: DomSanitizer,
    private matIconRegistry: MatIconRegistry,
    public appState: AppStateService,
    private titleService: Title
  ) {
    this.matIconRegistry.addSvgIcon(
      'drawing_board',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/drawing_board.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'fullscreen',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/fullscreen.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'info',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/info.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'light',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/light.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'pen',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/pen.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'stats',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/stats.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'stop_watch',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/stop_watch.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'open_in_new',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/open_in_new.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'toolbox',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/toolbox.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'pin',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/pin.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'stil',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/stil.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'tli-details',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/tli-details.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'arrow',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/arrow.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'check-mark',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/check-mark.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'activation',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/activation.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'write',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/write.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'lens',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/lens.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'clear',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/clear.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'history',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/history.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'sync_alt',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/sync_alt.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'notification_important',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/notification_important.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'brand_r',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/brand_r.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'arrow_drop_down',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/arrow_drop_down.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'spellcheck',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/spellcheck.svg')
    );
    this.matIconRegistry.addSvgIcon(
      'spellcheck_alt',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/images/icons/spellcheck_alt.svg')
    );

    this.titleService.setTitle(environment.appTitle);
  }
  // title = environment.appTitle;
}
