/// <reference types="@types/office-js" />

import { createLogger } from './logger';

const LOG = createLogger('utils');

export async function sync(ctx: Word.RequestContext): Promise<void> {
  LOG.log('pre sync', ctx.debugInfo.pendingStatements);
  await ctx.sync();
  LOG.log('post sync');
}

export function createSync(ctx: Word.RequestContext): () => Promise<void> {
  return async () => {
    try {
      LOG.log('sync >>> ', ctx.debugInfo.pendingStatements);
      const START = Date.now();
      await ctx.sync();
      const END = Date.now();
      LOG.log(`sync <<< (Sync took ${END - START}ms)`);
    } catch (error: unknown) {
      LOG.error('sync !!! ', error);
      throw error;
    }
  };
}
export function toError(error: unknown): Error {
  if (error instanceof Error) {
    return error;
  } else {
    return new Error(String(error));
  }
}

export function delay(ms: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export { createLogger };
