import { Component, OnInit, Input } from '@angular/core';
import { CurrentTextService } from 'src/app/services/current-text.service';
import { JsonApiService } from 'src/app/services/json-api.service';
import { AnalysisRequest } from 'src/app/TeoDefinitions';
import { AnalysisItemContent } from '../analysis-item-content-interface';

@Component({
  selector: 'app-corporate-tonality',
  templateUrl: './corporate-tonality.component.html',
  styleUrls: ['../analysis-item-shared.scss', './corporate-tonality.component.scss']
})
export class CorporateTonalityComponent implements OnInit, AnalysisItemContent {
  data: any;
  itemName: string;
  @Input() inactive: boolean;
  @Input() scannerName: string;

  detailed = false;

  loading = false;

  ctViolations = [];
  // ctViolationNameArray: string[] = [];

  // editorMarkings = [];

  violationNumber = 0;

  noViolations = true;

  textClickSubscription = null;
  markingsSubscription = null;

  markings = ['wrap_corporate_tonality'];

  detailsHeight = '0px;';

  constructor(
    private currentText: CurrentTextService,
    private api: JsonApiService
  ) {
    this.currentText.gotScannerResults.subscribe((dataArray) => {
      if (dataArray && dataArray[this.scannerName]) {
        this.useScanResult(dataArray[this.scannerName]);
      }
    });
  }

  ngOnInit() {
  }

  // scan() {
  //   if (!this.loading) {
  //     this.currentText.unPrepareMarkings('ct-wrapper');
  //     setTimeout(() => {  // wait for wrappers to be removed
  //       const anaRequest = new AnalysisRequest();
  //       anaRequest.text = this.currentText.currentEditorContent;
  //       this.api.postScanRequest('ctScan', anaRequest, (data) => {
  //         this.useScanResult(data);
  //         setTimeout(() => {
  //           this.currentText.prepareMarkings(this.editorMarkings, 'ct-wrapper');
  //           this.currentText.setMarkings(this.markings);
  //           this.setDetailsHeight();
  //         }, 50); // wait for wrappers to be inserted
  //       }, (error) => { console.log('Corporate Tonality Error: ', error); this.loading = false; });
  //     }, 50);
  //   }
  // }

  useScanResult(data) {
    this.ctViolations = [];
    // this.ctViolationNameArray = [];
    this.violationNumber = 0;
    // if (typeof data !== 'undefined') {
    for (const vio of data.violations) {
      this.ctViolations[vio.id] = {
        word: vio.word,
        replacement: vio.replacement,
        id: vio.id,
        replaced: false
      };
      // this.ctViolationNameArray.push(vio.name);
      this.violationNumber++;
    }
    if (this.violationNumber > 0) {
      this.noViolations = false;
    } else {
      this.noViolations = true;
    }
    this.setDetailsHeight();
  }

  useReplacement(violationId) {
    const editorNode = document.getElementById('editor-text');
    // const itemNodes = editorNode.querySelectorAll('[itemprop=' + violationId + ']');
    const itemNodes = editorNode.getElementsByClassName('wrap_corporate_tonality relation_' + violationId);

    if (this.ctViolations[violationId].replaced) {
      this.ctViolations[violationId].content = [itemNodes[0].innerHTML];
      itemNodes[0].innerHTML = this.ctViolations[violationId].replacement;
      itemNodes[0].classList.add('wrapper-corrected');
    } else {
      itemNodes[0].innerHTML = this.ctViolations[violationId].content[0];
      itemNodes[0].classList.remove('wrapper-corrected');
    }
    for (var i = 1; i < itemNodes.length; i++) {
      if (this.ctViolations[violationId].replaced) {
        this.ctViolations[violationId].content.push(itemNodes[i].innerHTML);
        itemNodes[i].innerHTML = '';
      } else {
        itemNodes[i].innerHTML = this.ctViolations[violationId].content[i];
        // itemNodes[i].classList.remove('wrapper-corrected');
      }
    }
    const event = new Event('input');
    event.initEvent('input', true, true);
    editorNode.dispatchEvent(event);
  }

  correctAll() {
    for (const vio of this.ctViolations) {
      vio.replaced = true;
      this.useReplacement(vio.id);
    }
  }

  close(removeMarkings = true) {
    this.detailed = false;
    if (this.textClickSubscription !== null) {
      this.textClickSubscription.unsubscribe();
    }
    if (this.markingsSubscription !== null) {
      this.markingsSubscription.unsubscribe();
    }
    if (removeMarkings) {
      this.currentText.removeMarkings();
      this.currentText.clickClasses = [];
    }
    this.setDetailsHeight();
  }

  open() {
    // window.setTimeout(() => {
    this.currentText.clickClasses = this.markings;
    this.currentText.setMarkings(this.markings);
    this.textClickSubscription = this.currentText.textClick.subscribe((data) => {
      if (data.marker === this.markings[0]) {
        data.relations.forEach(element => {
          if (element.marker == 'corporate_tonality') {
            this.ctViolations[element.relation].replaced = !this.ctViolations[element.relation].replaced;

            this.useReplacement(element.relation);
          }
        });
      }
    });
    this.markingsSubscription = this.currentText.markings.subscribe((reset: boolean) => {
      if (reset && this.detailed) {
        if (!this.currentText.activeMarkings.includes(this.markings[0])) {
          this.close(false);
        }
      }
    });
    this.detailed = true;
    this.setDetailsHeight();
    // }, 50);
  }

  itemClick() {
    if (this.detailed) {
      this.close();
    } else {
      this.open();
    }
  }

  private setDetailsHeight() {
    window.setTimeout(() => {
      if (this.detailed) {
        const todoElem = document.getElementById('ct-details-inner');
        const height = todoElem.offsetHeight;
        this.detailsHeight = height + 'px';
      } else {
        this.detailsHeight = '0px';
      }
    }, 20);
  }
}
