import { Component, OnInit, Input } from '@angular/core';
import { AnalysisItemContent } from '../analysis-item-content-interface';
import { CurrentTextService } from 'src/app/services/current-text.service';
import { AppStateService } from 'src/app/services/app-state.service';
import { AccordionInfoComponent } from 'src/app/pinboard/info-item/accordion-info/accordion-info.component';

@Component({
  selector: 'app-dyn-index-debug',
  templateUrl: './dynIndexDebug.component.html',
  styleUrls: ['../analysis-item-shared.scss', './dynIndexDebug.component.scss']
})
export class DynIndexDebugComponent implements OnInit, AnalysisItemContent {
  data: any;
  itemName: string;
  @Input() inactive: boolean;

  detailed = false;

  indices: any[] = [];

  detailsHeight = '0px;';

  constructor(
    private currentText: CurrentTextService,
    private appState: AppStateService
  ) {
    this.currentText.gotResults.subscribe(() => {
      const data = this.currentText.lastResult.dynIndexDebug;

      this.indices = data;

      this.setDetailsHeight();
    });

    // this.currentText.markings.subscribe(() => {
    //   if (
    //     (this.currentText.activeMarkings.length !== this.markings.length
    //       || this.currentText.activeMarkings[0] !== this.markings[0])
    //     && this.detailed
    //   ) {
    //     this.close(false);
    //   }
    // });
  }

  ngOnInit() {
  }

  close(removeMarkings = true) {
    this.detailed = false;
    this.setDetailsHeight();
  }

  open() {
    this.detailed = true;
    this.setDetailsHeight();
  }

  itemClick() {
    if (this.detailed) {
      this.close();
    } else {
      this.open();
    }
  }

  valueNotUndefined(value) {
    return typeof value != 'undefined';
  }

  private setDetailsHeight() {
    window.setTimeout(() => {
      if (this.detailed) {
        const todoElem = document.getElementById('dyn-index-debug-details-inner');
        const height = todoElem.offsetHeight;
        this.detailsHeight = height + 'px';
      } else {
        this.detailsHeight = '0px';
      }
    }, 20);
  }

}
