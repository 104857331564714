import { JsonApiService } from 'src/app/services/json-api.service';
import { AppStateService } from '../../services/app-state.service';
import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { User } from 'src/app/TeoDefinitions';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-user-login',
  templateUrl: './user-login.component.html',
  styleUrls: ['./user-login.component.scss']
})
export class UserLoginComponent implements OnInit {
  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);
  passwordFormControl = new FormControl('', [
    Validators.required,
  ]);
  loginFailed = false;

  showPasswordLink = environment.showPasswordReset;
  showDevNotice = !environment.production;

  privacyLink = environment.privacyInfoUrl;

  constructor(
    public appState: AppStateService,
    private apiService: JsonApiService
  ) { }

  ngOnInit() {
    if (this.appState.user) {
      this.appState.login.emit(this.appState.user);
    }
  }

  logIn() {
    if (this.emailFormControl.valid && this.passwordFormControl.valid) {
      this.apiService.logIn(this.emailFormControl.value, this.passwordFormControl.value, (user: User) => {
        this.loginFailed = false;
        this.appState.login.emit(user);
        if (user.role && user.role.permissions.includes('SEE_DEBUG_INFO')) {
          this.appState.debugMode = true;
        }
        if (user.changelog && user.changelog.length) {
          this.appState.setView('changelog');
        } else {
          this.appState.setView('analysis-configuration');
        }
      }, () => {
        this.loginFailed = true;
      });
    } else {
      this.emailFormControl.markAllAsTouched();
      this.passwordFormControl.markAllAsTouched();
    }
  }

}
