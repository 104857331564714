// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  // production: false,
  production: true,
  // secureConnection: false,
  secureConnection: true,
  appTitle: 'test TeoApp test',
  // apiUrl: 'https://demo-api.teo.digital/api/',
  // apiUrl: 'http://teo-api.local/api/',
  // apiUrl: 'http://localhost:8080/api/',
  // apiUrl: null,
  apiUrl: 'https://cloud-02.teo.digital/api/',
  // privacyInfoUrl: 'http://teo-api.local/datenschutz',
  privacyInfoUrl: 'https://cloud-02.teo.digital/datenschutz',
  changelogUrl: 'https://tcl.digital/changelog/',
  // privacyInfoUrl: null,
  // apiUrl: 'http://localhost:8080/api/',
  // privacyInfoUrl: 'http://localhost:8080/datenschutz',
  languageToolUrl: null,
  mainIndexName: 'TPI',
  evaluationProfileId: 3,
  disableInformal: false,
  useLdapLogin: false,
  showPasswordReset: true,
  optionalModules: {
    thesaurus: false,
    corporateTonality: false,
    languageTool: true,
    powerWords: false,
    genderThesaurus: false,
    anglicismThesaurus: true,
    causality: true,
    dialog: true,
    sentimentScanner: true,
    keywordScanner: true,
    debugCard: true,
    wordLists: true
  },
  editor: 'web',
  storageType: 'session',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
