<div class="tli" [class.inactive]="currentText.analysisCounter === 0" appHelpLayerItem="tli-help" helpTitle="TEO Leitindex" helpView="analysis-main">
  <div class="tli-tacho">
    <div class="tli-tacho-score">
        <svg *ngFor="let segment of tachoSegments; let index = index" [ngClass]="getTachoSegmentClass(segment)" [ngStyle]="segment.styles"
            height="100" width="100" version="1.1" xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink">
                <path id="path-2" opacity="1"
                stroke-width="10" [attr.d]="segmentArcPath" />

        </svg>
    </div>
    <div *ngIf="currentText.analysisCounter" class="tli-tacho-bench">
        <!-- <svg class="tli-tacho-bench-svg"
            height="100" width="100" version="1.1" xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink">
            <path id="tli-tacho-bench-arc" #benchArc fill="none" stroke-width="4" [attr.d]="benchmarkArcPath" />
        </svg> -->
        <svg *ngFor="let segment of benchArcSegments; let index = index" class="bench-arc-segment" [ngStyle]="segment.styles"
        height="100" width="100" version="1.1" xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink">
            <path id="path-2" opacity="1"
            stroke-width="4" fill="none" [attr.d]="segment.path" />

    </svg>
    </div>
  </div>

    <div class="tli-content" [ngClass]="currentColor">
        <span class="tli-text">{{ environment.mainIndexName }}</span><br />
        <span *ngIf="!percentDisplay" class="tli-number">{{ currentText.lastResult.tli | number : '1.2-2' }}</span>
        <span *ngIf="percentDisplay" class="tli-number">{{ scorePercent | number : '1.0-0' }}%</span>
    </div>
    <div *ngIf="isModified" class="mod-marker" [ngClass]="currentColor">MOD.</div>
</div>
