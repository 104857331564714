import { Directive, Input, HostListener } from '@angular/core';
import { HelpLayerService } from './help-layer.service';

@Directive({
  selector: '[appHelpLayerTrigger]'
})
export class HelpLayerTriggerDirective {
  @Input('appHelpLayerTrigger') helpView: string;

  constructor(
    private helpLayer: HelpLayerService
    ) { }
    @HostListener('click') onClick() {
      this.helpLayer.open(this.helpView);
    }
}
