import { Component, OnInit, ElementRef, ViewChild, HostListener } from '@angular/core';
import { CurrentTextService } from '../../../services/current-text.service';
import { ViewEncapsulation } from '@angular/core';
import { JsonApiService } from 'src/app/services/json-api.service';
import { environment } from 'src/environments/environment';
import { EditorMarking, EditorMarkings } from 'src/app/TeoDefinitions';
import { resolveSanitizationFn } from '@angular/compiler/src/render3/view/template';
import { AppStateService } from 'src/app/services/app-state.service';

@Component({
  selector: 'app-analysis-editor',
  templateUrl: './analysis-editor.component.html',
  styleUrls: ['./analysis-editor.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AnalysisEditorComponent implements OnInit {

  styleSheet;
  colors = {};
  editorOptions = {
    fontSize: 12,
    lineHeight: 1.5,
  };
  editorFontSizes = [
    10, 11, 12, 14, 16
  ];
  editorLineHeights = [
    1, 1.5, 2
  ];
  // markingArray: EditorMarking[] = [];

  clickInfoIdCounter = 0;
  clickInfos = {};

  @ViewChild('editor') editorElement;

  @HostListener('window:beforeunload', ['$event'])
  unloadHandler(event: Event) {
    if (this.currentText.currentEditorContent.length > 4) {
      return false;
    }
    return true;
  }

  constructor(
    public currentText: CurrentTextService,
  ) {
    let style = document.createElement('style');
    style.appendChild(document.createTextNode(''));
    document.head.appendChild(style);
    this.styleSheet = style;

    this.currentText.markings.subscribe(() => {

      console.log('markiere:', this.currentText.activeMarkings);

      this.removeMarkings();
      this.cleanStyleSpans();
      this.useMarkings();
    });

    this.currentText.gotMarkings.subscribe((markings: EditorMarkings) => {

      console.log('neue Marker:',markings);

      this.currentText.updateEditorContent(
        this.applyWrappers(
          markings.markings,
          this.currentText.currentEditorContent,
          markings.wrapperClass
        ));
    });

    this.currentText.editorContentUpdate.subscribe((string) => {
      this.editorElement.nativeElement.innerHTML = this.currentText.currentEditorContent;
    })

    this.currentText.removedMarkings.subscribe((wrapperClass: string) => {
      this.removeWrappers(wrapperClass);
    });

    this.currentText.beforeAnalysis.subscribe(() => {
      this.removeWrappers();
    })
  }

  ngOnInit() {
  }

  getEditorStyle() {
    return {
      'font-size': this.editorOptions.fontSize + 'pt',
      'line-height': this.editorOptions.lineHeight,
    };
  }

  private findWrapperPositions(markingArray, markerNames = []) {
    const wrapperPositions = [];
    markingArray.forEach(marking => {
      if (markerNames.length === 0 || markerNames.includes(marking.marker)) {
        if (!wrapperPositions.includes(marking.offset)) {
          wrapperPositions.push(marking.offset);
        }
        if (!wrapperPositions.includes(marking.offset + marking.length)) {
          wrapperPositions.push(marking.offset + marking.length);
        }
      }
    });
    wrapperPositions.sort((a, b) => { return a - b });
    return wrapperPositions;
  }

  private insertWrapper(textString, htmlClass, clickId, start, end) {
    const endInsert = '</span>';
    let startInsert = '<span class="' + htmlClass + '"';
    startInsert += clickId === null ? '' : ' itemprop="' + clickId + '"';
    startInsert += '>';

    // search (backwards) html tags inside this wrapper, outmit them
    var insideTag = false;
    // var wrapperOpen =false;

    if (textString[end - 1] != '>') {
      textString = this.stringInsert(textString, endInsert, end);
      // wrapperOpen = true;
    } else {
      insideTag = true;
    }

    for (var i = end - 2; i > start; i--) {
      if (textString[i] == '>') {
        if (!insideTag) {
          insideTag = true;
          textString = this.stringInsert(textString, startInsert, i + 1);
          // wrapperOpen = false;
        }
      }
      if (textString[i] == '<') {
        if (insideTag) {
          if (!(i > 0 && textString[i - 1] == '>')) {
            // there is no tag immidiatly following this one
            insideTag = false;
            textString = this.stringInsert(textString, endInsert, i);
            // wrapperOpen = true;
          }
        } else {
          throw new Error('trying to insert wrapper in html tag.');
        }
      }

    }
    if (insideTag) {
      // console.log(textString.substring(start, end));
      // throw new Error('trying to insert wrapper in html tag.');
    } else {
      textString = this.stringInsert(textString, startInsert, start);
    }
    return textString;
  }

  private applyWrappers(markingArray, textString, wrapperClass) {
    const wrapperPositions = this.findWrapperPositions(markingArray);
    // textString = this.stringInsert(textString, '</span>', wrapperPositions[wrapperPositions.length - 1]);
    for (let i = wrapperPositions.length - 2; i >= 0; i--) {
      // let insert = i > 0 ? '</span>' : '';
      let classList = 'teo-wrapper ' + wrapperClass;
      const relations = [];
      let hasMarkings = false;
      markingArray.forEach(marking => {
        if (
          marking.offset <= wrapperPositions[i]
          && marking.offset + marking.length > wrapperPositions[i]
        ) {
          hasMarkings = true;
          classList += ' ' + 'wrap_' + marking.marker;
          if (typeof marking.relation != 'undefined' && marking.relation !== null) {
            relations.push({ marker: marking.marker, relation: marking.relation });
            classList += ' ' + 'relation_' + marking.relation;
          }
        }
      });
      if (!hasMarkings) {
        continue;
      }
      // insert += '<span class="' + classList + '"';
      let clickId = null;
      if (relations.length) {
        this.clickInfos[++this.clickInfoIdCounter] = relations;
        // insert += ' itemprop="' + this.clickInfoIdCounter + '"';
        clickId = this.clickInfoIdCounter;
      }
      // insert += '>';
      // textString = this.stringInsert(textString, insert, wrapperPositions[i]);
      textString = this.insertWrapper(
        textString,
        classList,
        clickId,
        wrapperPositions[i],
        wrapperPositions[i + 1]
      );
    }
    return textString;
  }

  private removeWrappers(wrapperClass = 'teo-wrapper') {
    this.cleanStyleSpans();
    return this.removeHtmlBySelector('.' + wrapperClass);
  }

  private cleanStyleSpans() {
    // to fix problem with chromes contenteditable
    return this.removeHtmlBySelector('span[style]');
  }

  private removeHtmlBySelector(selector) {
    let wrappers = this.editorElement.nativeElement.querySelectorAll(selector);
    let removed = wrappers.length ? true : false;
    while (wrappers.length) {
      for (let i = wrappers.length - 1; i >= 0; i--) {
        wrappers[i].outerHTML = wrappers[i].innerHTML;
      }
      wrappers = this.editorElement.nativeElement.querySelectorAll(selector);
    }
    this.currentText.currentEditorContent = this.editorElement.nativeElement.innerHTML;
    return removed;
  }

  private stringInsert(mainString: string, insString: string, pos: number) {
    return mainString.slice(0, pos) + insString + mainString.slice(pos);
  }

  private useMarkings() {
    this.colors = this.currentText.wrapColors;
    this.currentText.activeMarkings.forEach(wrapper => {
      if (typeof this.colors[wrapper] !== 'undefined') {
        this.addMarking(wrapper, this.colors[wrapper]);
      } else {
        this.addMarking(wrapper, 'yellow');
      }
    });
  }

  private markAll() {
    for (const wrapper in this.colors) {
      if (this.colors.hasOwnProperty(wrapper)) {
        this.addMarking(wrapper, this.colors[wrapper]);
      }
    }
  }

  private addMarking(marker: string, color: string) {
    let style = '{background-color:' + color + ';';
    if (this.currentText.clickClasses.includes(marker)) {
      style += 'cursor: pointer;';
    }
    style += '}';
    this.styleSheet.appendChild(document.createTextNode('.' + marker + style));
    this.enableCorrectionMarker(marker);
  }

  private enableCorrectionMarker(marker: string, color = this.colors['wrapper-corrected']) {
    const style = '{background-color:' + color + ';}';
    this.styleSheet.appendChild(document.createTextNode('.wrapper-corrected.' + marker + style));
  }

  private removeMarkings() {
    this.styleSheet.innerHTML = '';
  }

  removeLinebreaks() {
    const lbRegex = /(((\r\n|\r|\n)|(<br[\s\/]*>))+(\s|&nbsp;)*)+/gm;
    const matches = [];
    let text = this.replaceDivs(this.currentText.currentEditorContent)
    let currMatch;
    do {
      currMatch = lbRegex.exec(text);
      if (currMatch) {
        matches.push(currMatch)
      }
    } while (currMatch);

    for (let match of matches) {
      if (match.index == 0) {
        text = text.substr(match[0].length);
      } else {
        let rep = this.countLinebreaks(match[0]) < 2 ? ' ' : '<br><br>';
        text = text.replace(match[0], rep);
      }
    }
    this.currentText.updateEditorContent(text);
  }

  countLinebreaks(str) {
    const re = /(\r\n|\r|\n)|(<br[\s\/]*>)/gm;
    return ((str || '').match(re) || []).length;
  }

  replaceDivs(text) {
    text = text.replace(/<\/div>/gmi, ' ');
    text = text.replace(/<div>/gmi, '<br />');
    return text;
  }

  onEdit(event: Event) {
    const eventTarget: any = event.target; // to avoid compiling Error
    if (
      this.currentText.currentEditorContent.length > 50
      && eventTarget.innerHTML.length < 30
      && this.currentText.statisticSession) {
      // most of the current text got removed (less then 30 chars remaining)
      this.currentText.showTextChangeMessage();
    }
    this.currentText.currentEditorContent = eventTarget.innerHTML;
    this.currentText.textModified = true;
  }

  onPaste(event: Event) {
    const anyEvent: any = event; // to avoid compiling Error
    let text: string = anyEvent.clipboardData.getData('text/plain');
    text = text.replace(/ä/g, 'ä');
    text = text.replace(/ö/g, 'ö');
    text = text.replace(/ü/g, 'ü');
    text = text.replace(/Ä/g, 'Ä');
    text = text.replace(/Ö/g, 'Ö');
    text = text.replace(/Ü/g, 'Ü');
    if (
      // relevant amount of text is inserted
      text.length > 50
      // and there alredy was a text before inserting
      && this.currentText.currentEditorContent.length > 30
      // and the current text has already been analyzed
      && this.currentText.statisticSession
    ) {
      this.currentText.showTextChangeMessage();
    }
    document.execCommand('insertHTML', false, text);
    return false;
  }

  onClick($event) {
    for (const marker of this.currentText.clickClasses) {
      if ($event.target.classList.contains(marker)) {
        console.log('click auf Markierung:',{
          marker,
          relations: this.clickInfos[$event.target.getAttribute('itemprop')],
          target: $event.target
        });
        this.currentText.textClick.emit({
          marker,
          relations: this.clickInfos[$event.target.getAttribute('itemprop')],
          target: $event.target
        });
      }
    }
  }

}
