import { Component, OnInit, Input } from '@angular/core';
import { AnalysisItemContent } from '../analysis-item-content-interface';
import { CurrentTextService } from 'src/app/services/current-text.service';
import { EditorMarking, EditorMarkings } from 'src/app/TeoDefinitions';
import { AppStateService } from 'src/app/services/app-state.service';

@Component({
  selector: 'app-keyword-scanner',
  templateUrl: './keyword-scanner.component.html',
  styleUrls: ['../analysis-item-shared.scss', './keyword-scanner.component.scss']
})
export class KeywordScannerComponent implements OnInit, AnalysisItemContent {
  data: any;
  itemName: string;
  @Input() inactive: boolean;

  detailed = false;

  inputContent = '';

  keywords: {
    id: number,
    word: string,
    selected: boolean;
    number: number,
    percent: number
  }[] = [];

  lastWordId = -1;

  analysisWordCount = 0;
  wordCount = 0;
  keywordCount = 0;
  keywordPercentage = 0;

  detailsHeight = '0px';

  wrapClassBase = 'keyword-wrap-';

  markingsActive = false;
  markingsJustChanged = false;

  resultsSubs;

  constructor(
    private currentText: CurrentTextService,
    private appState: AppStateService
  ) {
    this.currentText.markings.subscribe((reset: boolean) => {
      if (reset) {
        if (this.markingsJustChanged) {
          this.markingsJustChanged = false;
        } else {
          this.setAll(false);
          this.markingsActive = false;
        }
      }
    });
  }

  ngOnInit() {
  }

  setMarkingsActive() {
    this.markingsActive = true;
    this.markingsJustChanged = true;
    this.currentText.removeMarkings();
  }

  addWordFromInput() {
    this.addKeyword(this.inputContent);
  }

  addKeyword(word: string) {
    word.replace(/[^0-9a-zA-Z\-äüöÄÜÖéô]/gi, '');

    let exists = false;
    this.keywords.forEach((kWord) => {
      if (kWord.word === word) {
        exists = true;
      }
    });
    if (exists) {
      return;
    }

    this.lastWordId++;

    this.countAllWords();

    const wrapNum = this.wrapWord(word, this.wrapClassBase + this.lastWordId);
    this.keywords.push({
      id: this.lastWordId,
      word,
      selected: false,
      number: wrapNum,
      percent: (this.wordCount ? wrapNum / this.wordCount : 0)
    });
    this.refreshKeywordCounters();
    this.setDetailsHeight();
  }

  reInitAll() {
    this.countAllWords();
    this.keywords.forEach(word => {
      const wrapNum = this.wrapWord(word.word, this.wrapClassBase + word.id);
      word.number = wrapNum;
      this.setMarker(word);
    });
  }

  refreshKeywordCounters() {
    this.keywordCount = 0;
    this.keywordPercentage = 0;
    for (const word of this.keywords) {
      this.keywordCount += word.number;
    }
    if (this.wordCount > 0) {
      this.keywordPercentage = this.keywordCount / this.wordCount;
    }
  }

  countAllWords() {
    let pureText = this.currentText.currentEditorContent.replace(/<.*?>/g, '');
    pureText = pureText.replace(/&nbsp;/, ' ');
    pureText = pureText.replace(/\s{2,}/, ' ');
    pureText = pureText.trim();
    const words = pureText.split(' ');
    this.wordCount = words.length;
    return this.wordCount;
  }

  selectAll() {
    if (!this.setAll(true)) {
      this.setAll(false);
    }
  }

  setAll(select: boolean) {
    let changeCheck = false;
    for (const word of this.keywords) {
      if (word.selected !== select) {
        changeCheck = true;
        word.selected = select;
        this.setMarker(word);
      }
    }
    return changeCheck;
  }

  deleteKeywords() {
    this.keywords = this.keywords.filter(word => {
      if (word.selected) {
        word.selected = false;
        this.setMarker(word);
        return false;
      }
      return true;
    });
    this.setDetailsHeight();
  }

  setMarker(keyword: any) {
    if (!this.markingsActive) {
      this.setMarkingsActive();
    }
    if (keyword.selected) {
      this.currentText.addMarking('wrap_' + this.wrapClassBase + keyword.id);
    } else {
      this.currentText.removeMarking('wrap_' + this.wrapClassBase + keyword.id);
    }
  }

  wrapWord(word: string, wrapClass: string) {
    const found = this.searchWord(word, this.currentText.currentEditorContent);
    const markings: EditorMarking[] = [];
    for (let i = 0; i < found.length; i++) {
      markings.push({
        marker: wrapClass,
        offset: found[i],
        length: word.length
      })
    }
    this.appState.queForLoading((finished) => {
      this.currentText.prepareMarkings(markings, 'keword-scanner');
      finished();
    });
    return found.length;
  }

  stringInsert(mainString: string, insString: string, pos: number) {
    return mainString.slice(0, pos) + insString + mainString.slice(pos);
  }

  /**
   * searches for word in text, ignoring content of xml tags
   * text has to be valid xml
   * word can not contain whitespaces
   */
  searchWord(word: string, text: string) {
    let inTag = false;
    let wordPosition = 0;
    const results = [];
    for (let i = 0; i < text.length; i++) {
      if (!inTag) {
        if (text[i] === '<') {
          inTag = true;
        } else if (/\s|(&nbsp)/.test(text[i])) {
          // word can not contain whitespace, but has to be prepended by one if it does not follwo a html tag or the beginning of the text
          wordPosition = 0; // word could start with the next char
        } else {
          if (text[i] === word[wordPosition]) {
            wordPosition++;
            if (
              wordPosition === word.length
              && (typeof text[i + 1] === 'undefined'
                || /\s|(&nbsp)/.test(text[i + 1])
                || text[i + 1] === '<')
            ) {
              // the full word is followed by the end of the text or a whitespace or a tag: Meaning the word does not continue
              results.push(i - word.length + 1); // start position of word in text
              wordPosition = -1;
            }
          } else {
            // did not match
            wordPosition = -1; // word can not start with the next char
          }
        }
      } else if (text[i] === '>') {
        inTag = false;
        wordPosition = 0; // word could start with the next char
      }
    }
    return results;
  }

  open() {
    this.detailed = true;
    this.reInitAll();
    this.resultsSubs = this.currentText.gotResults.subscribe(() => {
      setTimeout(() => {
        this.reInitAll();
      }, 200);
    });
    this.setDetailsHeight();
  }

  close() {
    this.detailed = false;
    this.resultsSubs.unsubscribe();
    this.currentText.removeMarkings();
    this.currentText.unPrepareMarkings('keword-scanner');
    this.setDetailsHeight();
  }

  itemClick() {
    if (this.detailed) {
      this.close();
    } else {
      this.open();
    }
  }

  private setDetailsHeight() {
    window.setTimeout(() => {
      if (this.detailed) {
        const todoElem = document.getElementById('keyword-scanner-details-inner');
        const height = todoElem.offsetHeight;
        this.detailsHeight = height + 'px';
      } else {
        this.detailsHeight = '0px';
      }
    }, 20);
  }
}
