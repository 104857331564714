import { Component, OnInit } from '@angular/core';
import { CurrentTextService } from '../../services/current-text.service';
import { AppStateService } from '../../services/app-state.service';
import { AccordionInfoComponent } from 'src/app/pinboard/info-item/accordion-info/accordion-info.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-analysis-configuration',
  templateUrl: './analysis-configuration.component.html',
  styleUrls: ['./analysis-configuration.component.scss']
})

export class AnalysisConfigurationComponent implements OnInit {

  public informalSelectOptions = [
    {
      title: 'Sie',
      name: 'text-formal',
      value: false,
      state: 'SELECTED'
    },
    {
      title: 'Du *',
      name: 'text-informal',
      value: true,
      state: 'ACTIVE'
    }
  ];

  smallLayout = false;

  constructor(
    public currentText: CurrentTextService,
    private appState: AppStateService
  ) {
    if (environment.disableInformal == true) {
      this.informalSelectOptions[1].state = 'INACTIVE';
    }
    if (environment.editor == 'office') {
      this.smallLayout = true;
    }
  }

  ngOnInit() {
    this.currentText.loadStoredAttributes(['textAudience','textMedium','textStrategy']);
  }

  selectionReady() {
    return this.currentText.textAudience !== null && this.currentText.textStrategy !== null && this.currentText.textMedium !== null && this.currentText.isInformal !== null;
  }

  continue() {
    if (this.selectionReady()) {
      this.currentText.storeConfig();
      this.appState.setView('analysis-main');
    }
  }

  // openInfoBox(name: string, title: string) {
  //   this.appState.openInfoItem(AccordionInfoComponent, name, title);
  //   return false;
  // }

}
