import { JsonApiService } from 'src/app/services/json-api.service';
import { Component, OnInit } from '@angular/core';
import { AppStateService } from 'src/app/services/app-state.service';
import { CurrentTextService } from 'src/app/services/current-text.service';
import { FormControl } from '@angular/forms';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss']
})
export class TopBarComponent implements OnInit {

  initials: string;
  userColor: string;
  textAliasFormControl = new FormControl('', []);
  changelogLink = environment.changelogUrl;

  smallLayout = environment.editor == 'office';

  constructor(
    public appState: AppStateService,
    public currentText: CurrentTextService,
    private api: JsonApiService
  ) {
    this.reInit();
    appState.viewChange.subscribe(() => {
      this.reInit();
      this.textAliasFormControl.setValue(currentText.textAlias);
    });

    this.textAliasFormControl.valueChanges.subscribe((value) => {
      currentText.textAlias = value;
    })

  }

  ngOnInit() {
  }

  logoutClick() {
    this.api.logOut((success) => { }, (error) => { });
  }

  openReport() {
    this.api.openReport(this.currentText.reportId, (data) => { }, (error) => { })
  }

  private reInit() {
    if (this.appState.user) {
      this.initials = this.makeInitials(this.appState.user.name);
      this.userColor = this.makeUserColor(this.appState.user.email);
    }
  }

  private makeInitials(name: string) {
    const names = name.split(' ');
    if (names.length < 2) {
      return name.substr(0, 1);
    }
    return names[0].substr(0, 1) + names[names.length - 1].substr(0, 1);
  }

  private makeUserColor(email: string) {
    let hash = 0;
    for (let i = 0; i < email.length; i++) {
      hash = email.charCodeAt(i) + ((hash << 5) - hash);
    }
    const c = (hash & 0x00FFFFFF)
      .toString(16)
      .toUpperCase();

    return '#' + '00000'.substring(0, 6 - c.length) + c;
  }

}
