<mat-toolbar id="teo-top-bar" class="">
  <div class="teo-logo"></div>
  <span class="toolbar-spacer"></span>
  <span *ngIf="!smallLayout">
    <span *ngIf="currentText.reportId" class="report-id">
      <a (click)="openReport()">Report öffnen</a>
    </span>
    <mat-icon class="toolbar-icon" svgIcon="info" [appInfoButton]="'text-alias'" [infoTitle]="'Text-Alias'">
    </mat-icon>
    <span class="alias-label">TEXT-ALIAS:</span>
    <mat-form-field class="top-bar-form-field" color="primary">
      <input matInput type="text" [formControl]="textAliasFormControl">
    </mat-form-field>
  </span>
  <span class="user-icon" [style.background-color]="userColor" [mdePopoverTriggerFor]="userMenuPopover"
    mdePopoverTriggerOn="click" #userMenuTrigger="mdePopoverTrigger">
    {{ initials }}
  </span>
  <mde-popover #userMenuPopover="mdePopover" [mdePopoverOverlapTrigger]="false" [mdePopoverArrowColor]="'#fff'">
    <mat-card style="max-width: 300px">
      <mat-card-content>
        <!-- <div *ngIf="changelogLink !== null">
          <a [href]="changelogLink" title="Changelog" target="_blank">{{ changelogLink }}</a>
          <mat-icon class="" svgIcon="open_in_new">
          </mat-icon>
        </div> -->
        <b>{{ appState.user.name }}</b>
        <p>{{ appState.user.email }}</p>
        <div class="logout-button-wrap">
          <a (click)="logoutClick()">Logout</a>
        </div>
      </mat-card-content>
    </mat-card>
  </mde-popover>
</mat-toolbar>
